<template>
  <Navbar navBackground="white" :fixedTop="false" />
  <div class="container-fluid" id="container-fluid">
    <div class="row">
      <div class="col-12 col-lg-6 m-0 p-0">
        <div class="row m-2">
          <div class="col">
            <div class="my-2">
              <div class="row">
                <div class="col-12">
                  <h1 class="font-weight-bold text-capitalize d-inline heading">
                    personal information
                  </h1>
                </div>
              </div>
            </div>
            <form
              id="basic-info-form"
              :class="{ disableform: haveInput == 'true' }"
            >
              <div class="row">
                <div class="col-12 col-sm-4">
                  <div class="form-group text-capitalize">
                    <label
                      for="name"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الأسم"
                      class="label-font font-weight-bold font-size-large"
                      >name</label
                    >
                    <input
                      type="text"
                      class="form-control bg-light"
                      id="name"
                      tabindex="1"
                      v-model="name"
                    />
                    <label
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الجنس"
                      class="label-font font-weight-bold font-size-large"
                      >gender</label
                    >
                    <select
                      class="form-control selectpicker"
                      id="gender-selector"
                      tabindex="4"
                      v-model="gender"
                    >
                      <option>Male</option>
                      <option>Female</option>
                    </select>
                    <label
                      data-toggle="tooltip"
                      data-placement="top"
                      title="العمر"
                      class="label-font font-weight-bold font-size-large "
                      >age</label
                    >
                    <select
                      class="form-control selectpicker"
                      id="age-selector"
                      tabindex="7"
                      v-model="age"
                    >
                      <option v-for="i in 100" :key="i">{{ i }}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الوظيفة"
                      class="label-font font-weight-bold font-size-large"
                      >occupation</label
                    >
                    <input
                      type="text"
                      class="form-control bg-light"
                      id="occupation"
                      tabindex="2"
                      v-model="occupation"
                    />
                    <label
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الحالة الزوجية"
                      class="label-font font-weight-bold font-size-large"
                      >marital status</label
                    >
                    <select
                      class="form-control selectpicker"
                      id="marital-status"
                      tabindex="5"
                      v-model="maritalStatus"
                    >
                      <option>Married</option>
                      <option>Single</option>
                    </select>
                    <label
                      for="name"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="العنوان"
                      class="label-font font-weight-bold font-size-large"
                      >Address</label
                    >
                    <input
                      type="text"
                      class="form-control bg-light"
                      id="address"
                      tabindex="8"
                      v-model="address"
                    />
                  </div>
                </div>
                <div class="col-12 col-sm-4">
                  <div class="form-group">
                    <label
                      for="name"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="رقم الهاتف"
                      class="label-font font-weight-bold font-size-large"
                      >phone number</label
                    >
                    <input
                      type="tel"
                      class="form-control  bg-light"
                      id="phone-number"
                      tabindex="3"
                      v-model="phoneNumber"
                    />
                    <!-- <label
                      for="name"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="أسم الدكتور"
                      class="label-font font-weight-bold font-size-large"
                      >doctor name</label
                    >
                    <input
                      type="text"
                      class="form-control  bg-light"
                      id="doctor-name"
                      v-model="doctorName"
                    /> -->
                    <label
                      data-toggle="tooltip"
                      data-placement="top"
                      title="أسم الدكتور"
                      class="label-font font-weight-bold font-size-large"
                      >doctor name</label
                    >
                    <select
                      class="form-control selectpicker"
                      id="doctor-selector"
                      tabindex="6"
                      v-model="doctorName"
                    >
                      <option v-for="name in doctors" :key="name">{{
                        name
                      }}</option>
                    </select>
                    <label
                      for="name"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="تاريخ المراجعة"
                      class="label-font font-weight-bold font-size-large"
                      >Date</label
                    >
                    <input
                      autocomplete="off"
                      type="text"
                      class="form-control bg-light datetimepicker"
                      tabindex="9"
                      id="datetimepicker1"
                    />
                  </div>
                </div>
              </div>
              <div class="my-2">
                <h1 class="font-weight-bold text-capitalize heading">
                  medical information
                </h1>
              </div>
              <p
                class="font-weight-bold text-capitalize label-font font-size-x-large"
                data-toggle="tooltip"
                data-placement="top"
                title="أي امراض متعلقة ب"
              >
                any related diseases to
              </p>
              <div class="row">
                <div class="col-12 col-sm-6">
                  <div class="h4 mb-1 mb-md-0 pb-0">
                    <div
                      class="pretty p-default p-curve p-fill label-font font-weight-bold"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="القلب و الأوعية الدموية"
                    >
                      <input
                        type="checkbox"
                        value="Cardiovascular"
                        v-model="diseases"
                        tabindex="10"
                      />
                      <div class="state p-dark">
                        <label class="label-font font-weight-bold"
                          >Cardiovascular</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="h4 mb-1 mb-md-0 pb-0">
                    <div
                      class="pretty p-default p-curve p-fill label-font font-weight-bold"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الجهاز التنفسي"
                    >
                      <input
                        type="checkbox"
                        value="Respiratory"
                        tabindex="11"
                        v-model="diseases"
                      />
                      <div class="state p-dark">
                        <label class="label-font font-weight-bold"
                          >Respiratory</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="h4 mb-1 mb-md-0 pb-0">
                    <div
                      class="pretty p-default p-curve p-fill label-font font-weight-bold"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الجهاز الهضمي"
                    >
                      <input
                        type="checkbox"
                        value="Gastrointestinal"
                        tabindex="12"
                        v-model="diseases"
                      />
                      <div class="state p-dark">
                        <label class="label-font font-weight-bold"
                          >Gastrointestinal</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
                <div class="col-12 col-sm-6">
                  <div class="h4 mb-1 mb-md-0 pb-0">
                    <div
                      class="pretty p-default p-curve p-fill label-font font-weight-bold"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الغدد الصماء (السكري)"
                    >
                      <input
                        type="checkbox"
                        value="Endocrine(diabetes)"
                        tabindex="13"
                        v-model="diseases"
                      />
                      <div class="state p-dark">
                        <label class="label-font font-weight-bold"
                          >Endocrine(diabetes)</label
                        >
                      </div>
                    </div>
                  </div>
                  <div class="h4 mb-1 mb-md-0 pb-0">
                    <div
                      class="pretty p-default p-curve p-fill label-font font-weight-bold"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الكلية"
                    >
                      <input
                        type="checkbox"
                        value="Renal"
                        v-model="diseases"
                        tabindex="14"
                      />
                      <div class="state p-dark">
                        <label class="label-font font-weight-bold">Renal</label>
                      </div>
                    </div>
                  </div>
                  <div class="h4 mb-1 mb-md-0 pb-0">
                    <div
                      class="pretty p-default p-curve p-fill label-font font-weight-bold"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="الكبد"
                    >
                      <input
                        type="checkbox"
                        value="Hepatic"
                        tabindex="15"
                        v-model="diseases"
                      />
                      <div class="state p-dark">
                        <label class="label-font font-weight-bold"
                          >Hepatic</label
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="row mt-3">
                <div class="col-12">
                  <div class="form-group">
                    <textarea
                      class="form-control placeholder-with-font"
                      rows="3"
                      placeholder="If yes ,  More information"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="اذا نعم , المزيد من المعلومات"
                      id="more-information"
                      tabindex="16"
                      v-model="moreInformation"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <textarea
                      class="form-control placeholder-with-font"
                      rows="3"
                      placeholder="Allergic to drugs/mediacal materials"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="حساسية للأدوية / المواد الطبية"
                      id="medication-allergy"
                      tabindex="17"
                      v-model="medicationAllergy"
                    ></textarea>
                  </div>
                </div>
                <div class="col-12">
                  <div class="form-group">
                    <textarea
                      class="form-control placeholder-with-font"
                      rows="3"
                      placeholder="Patient has been hospitalized/operated"
                      data-toggle="tooltip"
                      data-placement="top"
                      title="هل دخل المراجع مستشفى / اجرى عملية"
                      id="previous-operations"
                      tabindex="18"
                      v-model="previousOperations"
                    ></textarea>
                  </div>
                </div>
              </div>
              <div class="row">
                <div class="col">
                  <div
                    class="for-woman"
                    id="for-woman"
                    v-show="gender == 'Female' && maritalStatus == 'Married'"
                    ref="patientPregnantRef"
                  >
                    <div class="row">
                      <div class="col">
                        <!-- <label class="font-weight-bold h4 label-font"
                          ><input
                            type="radio"
                            class="orthodontic-icheck multiple-check"
                            id="pregnant-women"
                          />Patient pregnant ?</label
                        > -->
                        <div class="h4 mb-0 pb-0 font-weight-bold">
                          <div
                            class="pretty p-default p-round p-thick h4 font-weight-bold"
                          >
                            <input
                              type="checkbox"
                              value="pregnant"
                              tabindex="19"
                              v-model="patientPregnant"
                            />
                            <div class="state p-primary-o">
                              <label
                                class="pretty p-default p-curve p-fill h4 font-weight-bold"
                                >Patient pregnant ?</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row for-month" v-show="patientPregnant">
                      <div class="col">
                        <select
                          class="selectpicker"
                          id="month-of-pregnant"
                          tabindex="20"
                          v-model="patientPregnantMonth"
                        >
                          <option>First Month</option>
                          <option>Second Month</option>
                          <option>Third Month</option>
                          <option>Fourth Month</option>
                          <option>Fifth Month</option>
                          <option>Sixth Month</option>
                          <option>Seventh Month</option>
                          <option>Eighth Month</option>
                          <option>NinthMonth</option>
                          <option>Tenth Month</option>
                          <option>Eleventh Month</option>
                          <option>Twelfth Month</option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="my-2">
                <h1
                  class="font-weight-bold text-capitalize heading-info-font heading-info-color responsive-font-size-h1"
                >
                  Treatment Plan
                </h1>
              </div>
            </form>
            <div class="not-print">
              <div class="row  mx-0 px-0">
                <div class="col-6 col-md-5 col-lg-6 col-xl-5 mx-0 px-0">
                  <button
                    type="button"
                    class="btn btn-primary btn-md p-2 m-1"
                    data-toggle="modal"
                    data-target="#endodontic-treatment"
                    :disabled="endodonticDisable"
                  >
                    Endodontic Treatment
                  </button>
                </div>
                <div class="col-6  col-md-7 col-lg-6 col-xl-7 mx-0 px-0">
                  <button
                    type="button"
                    class="btn btn-success btn-md p-2 m-1"
                    data-toggle="modal"
                    data-target="#composite"
                    :disabled="compositeDisable"
                  >
                    Composite Restoration
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col mr-0 pr-0">
                  <button
                    type="button"
                    class="btn btn-secondary btn-md  p-2 m-1"
                    data-toggle="modal"
                    data-target="#teeth-prostheti-and-hollywood-smile"
                    :disabled="prostheticDisable"
                  >
                    Prosthetic And Hollywood Smile
                  </button>
                </div>
                <div class="col ml-0 pl-0">
                  <button
                    type="button"
                    class="btn btn-danger btn-md  p-2 m-1"
                    data-toggle="modal"
                    data-target="#extraction"
                    :disabled="extractionDisable"
                  >
                    Extraction
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-6 col-sm-5 col-md-5 col-lg-6 col-xl-5">
                  <button
                    type="button"
                    class="btn btn-dark btn-md  p-2 m-1"
                    data-toggle="modal"
                    data-target="#orthodotic-treatment"
                    :disabled="orthodonticDisable"
                  >
                    Orthodontic Treatment
                  </button>
                </div>
                <div
                  class="col-4 col-sm-4 pl-sm-5 col-md-5 pl-md-3 col-lg-5 col-xl-5"
                >
                  <button
                    type="button"
                    class="btn btn-info btn-md  p-2 m-1"
                    data-toggle="modal"
                    data-target="#teeth-implant"
                    :disabled="teethImplantDisable"
                  >
                    Teeth Implant
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-10 col-sm-9">
                  <button
                    type="button"
                    class="btn btn-warning btn-md btn-block p-2 m-1"
                    data-toggle="modal"
                    data-target="#periodontal-treatment-and-teeth-bleaching"
                    :disabled="PeriodontalDisable"
                  >
                    Periodontal Treatment And Bleaching
                  </button>
                </div>
              </div>
              <div class="row">
                <div class="col-10 col-sm-9">
                  <button
                    type="button"
                    class="btn btn-orange btn-md btn-block p-2 m-1"
                    data-toggle="modal"
                    data-target="#Paedodontic"
                    :disabled="paedodonticDisable"
                  >
                    Paedodontics
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="d-none col-lg-6 d-lg-block m-0 px-0 mt-3">
        <img
          src="@/assets/images/clinic.webp"
          class="img-thumbnail w-100"
          id="myDiv"
        />
      </div>
    </div>
  </div>
  <!-- data validation  modal -->
  <div class="modal" tabindex="-1" role="dialog" id="data-validation-modal">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">more information required</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div v-for="feild in requiredfields" :key="feild">
            <p class="text-danger">the {{ feild }} feild is required</p>
          </div>
          <p class="text-danger d-none text-center" ref="errorWhenUploadP"></p>
        </div>
      </div>
    </div>
  </div>
  <Endodontic
    :dateOfNextVisitEndodontic="dateOfNextVisitEndodontic"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <Composite
    :dateOfNextVisitComposite="dateOfNextVisitComposite"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <Extraction
    :dateOfNextVisitExtraction="dateOfNextVisitExtraction"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @instractionClicked="showInstraction"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <Prosthetic
    :dateOfNextVisitProsthetic="dateOfNextVisitProsthetic"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <Periodontal
    :dateOfNextVisitPeriodontal="dateOfNextVisitPeriodontal"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <Orthodontic
    :dateOfNextVisitOrthodontic="dateOfNextVisitOrthodontic"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <TeethImplant
    :dateOfNextVisitTeethImplant="dateOfNextVisitTeethImplant"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <Paedodontic
    :dateOfNextVisitPaedodontic="dateOfNextVisitPaedodontic"
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :edit="edit"
    :newVisit="newVisit"
    :id="id"
    :haveSubCases="haveSubCases"
    :rx="rx"
    :rxContainer="rxContainer"
    @needSomeRequiredData="needSomeRequiredData"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
  <Rx
    :mode="modeOfRx"
    :settings="rxSettings"
    :rxGeneralInfo="rxGeneralInfo"
    :rxContainer="rxContainer"
    :edit="edit"
    :haveInput="haveInput"
    @getRx="getRx"
    @allRxDataFinish="startPrint"
    :images="images"
  />
  <!-- no need modal -->
  <div class="modal fade" id="no-need" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-body">
          <p class="copy-text-general">
            جاري العمل على تفعيل عملية رفع الصور في سيرفرات تدعم موقعك الجغرافي
            (الشرق الأوسط)
          </p>
          <p class="copy-text-general">أنتظرنا قريبا</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Endodontic from "./Endodontic.vue";
import Composite from "./Composite.vue";
import Extraction from "./Extraction.vue";
import Prosthetic from "./Prosthetic.vue";
import Periodontal from "./Periodontal.vue";
import Orthodontic from "./Orthodotic.vue";
import TeethImplant from "./TeethImplant.vue";
import Rx from "./Rx.vue";
import Navbar from "./Navbar.vue";
import Paedodontic from "./Paedodontic.vue";
import { ref } from "@vue/reactivity";
import getUser from "@/composables/getUser";
import getDocument from "@/composables/getDocument";
import { projectFirestore } from "../firebase/config";
import { onMounted, onBeforeUnmount } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  props: [
    "patientInformation",
    "haveInput",
    "treatmentPlan",
    "disabledMode",
    "edit",
    "newVisit",
    "id",
    "haveSubCases",
    "print",
    "rxContainer",
    "images",
    "sendFromSubCase",
  ],
  components: {
    Navbar,
    Endodontic,
    Composite,
    Extraction,
    Prosthetic,
    Periodontal,
    Orthodontic,
    TeethImplant,
    Paedodontic,
    Rx,
  },
  setup(props) {
    const { user } = getUser();
    const modeOfRx = ref("");
    const rxSettings = ref({});
    const store = useStore();

    const name = ref("");
    const occupation = ref("");
    const phoneNumber = ref("");
    const gender = ref("Male");
    const maritalStatus = ref("Married");
    const doctorName = ref("");
    const age = ref("20");
    const address = ref("");
    const dateOfVisit = ref("");
    const diseases = ref([]);
    const moreInformation = ref("");
    const medicationAllergy = ref("");
    const previousOperations = ref("");

    const patientPregnant = ref(false);
    const patientPregnantRef = ref(null);
    const patientPregnantMonth = ref("Sixth Month");

    const dateOfNextVisitEndodontic = ref("");
    const dateOfNextVisitComposite = ref("");
    const dateOfNextVisitExtraction = ref("");
    const dateOfNextVisitOrthodontic = ref("");
    const dateOfNextVisitProsthetic = ref("");
    const dateOfNextVisitPeriodontal = ref("");
    const dateOfNextVisitTeethImplant = ref("");
    const dateOfNextVisitPaedodontic = ref("");

    const endodonticDisable = ref(false);
    const compositeDisable = ref(false);
    const extractionDisable = ref(false);
    const prostheticDisable = ref(false);
    const teethImplantDisable = ref(false);
    const orthodonticDisable = ref(false);
    const PeriodontalDisable = ref(false);
    const paedodonticDisable = ref(false);

    const requiredfields = ref([]);
    const doctors = ref([]);
    const errorWhenUploadP = ref(null);
    if (props.patientInformation) {
      console.log(typeof props.patientInformation.monthOfPregnant);
      name.value = props.patientInformation.name;
      occupation.value = props.patientInformation.occupation;
      phoneNumber.value = props.patientInformation.phoneNumber;
      gender.value = props.patientInformation.gender;
      maritalStatus.value = props.patientInformation.maritalStatus;

      age.value = props.patientInformation.age;
      address.value = props.patientInformation.address;
      diseases.value = props.patientInformation.diseases;
      moreInformation.value = props.patientInformation.moreInformation;
      medicationAllergy.value = props.patientInformation.medicationAllergy;
      previousOperations.value = props.patientInformation.previousOperations;
      if (props.patientInformation.monthOfPregnant != "false") {
        patientPregnant.value = true;
        patientPregnantMonth.value = props.patientInformation.monthOfPregnant;
      }
      dateOfVisit.value = props.patientInformation.dateOfVisit;
      console.log(props.treatmentPlan);
      if (props.treatmentPlan.type == "Endodontic Treatment") {
        dateOfNextVisitEndodontic.value = props.treatmentPlan.dateOfNextVisit;
      } else if (props.treatmentPlan.type == "Composite Restoration") {
        dateOfNextVisitComposite.value = props.treatmentPlan.dateOfNextVisit;
      } else if (props.treatmentPlan.type == "Extraction") {
        dateOfNextVisitExtraction.value = props.treatmentPlan.dateOfNextVisit;
      } else if (props.treatmentPlan.type == "Orthodontic Treatment") {
        dateOfNextVisitOrthodontic.value = props.treatmentPlan.dateOfNextVisit;
      } else if (props.treatmentPlan.type == "Prosthetic And Hollywood Smile") {
        dateOfNextVisitProsthetic.value = props.treatmentPlan.dateOfNextVisit;
      } else if (
        props.treatmentPlan.type == "Periodontal Treatment And Teeth Bleaching"
      ) {
        dateOfNextVisitPeriodontal.value = props.treatmentPlan.dateOfNextVisit;
      } else if (props.treatmentPlan.type == "Teeth Implant") {
        dateOfNextVisitTeethImplant.value = props.treatmentPlan.dateOfNextVisit;
      } else if (props.treatmentPlan.type == "Paedodontic") {
        dateOfNextVisitPaedodontic.value = props.treatmentPlan.dateOfNextVisit;
      }
    }

    // const myPrint = () => {
    //   // if (props.treatmentPlan.type == "Endodontic Treatment") {
    //   const modal = document.getElementById("printThis");
    //   const cloned = modal.cloneNode(true);
    //   let section = document.getElementById("print");
    //   if (!section) {
    //     section = document.createElement("div");
    //     section.id = "print";
    //     document.body.appendChild(section);
    //   }

    //   section.innerHTML = "";
    //   section.appendChild(cloned);
    //   // document.body.appendChild(section);
    //   window.print();
    //   document.body.removeChild(section);
    //   // }
    // };
    const getIdForUserDoc = async () => {
      let id = null;
      let ref = projectFirestore
        .collection("users")
        .where("userId", "==", user.value.uid);
      const data = await ref.get();
      data.forEach((doc) => {
        id = doc.id;
      });

      return id;
    };

    const patientInformation = ref({});
    const rxGeneralInfo = ref({});
    const rx = ref(null);
    const getRx = (rxObj) => {
      rx.value = rxObj;
    };
    onMounted(async () => {
      // for doctor selection
      let userPaymentObj = await projectFirestore
        .collection("users")
        .where(...["userId", "==", user.value.uid])
        .get();
      let userPaymentObjData;
      userPaymentObj.forEach((doc) => {
        userPaymentObjData = doc.data();
      });

      doctors.value = userPaymentObjData.doctors;
      if (!props.patientInformation) {
        doctorName.value = doctors.value[0];
      } else {
        doctorName.value = props.patientInformation.doctorName;
      }

      if (props.rxContainer) {
        rx.value = props.rxContainer;
      }
      if (props.patientInformation) {
        $("#datetimepicker1").val(props.patientInformation.dateOfVisit);

        if (props.treatmentPlan.type == "Endodontic Treatment") {
          $("#datetimepicker2").val(props.treatmentPlan.dateOfNextVisit);
        } else if (props.treatmentPlan.type == "Composite Restoration") {
          $("#datetimepicker3").val(props.treatmentPlan.dateOfNextVisit);
        } else if (props.treatmentPlan.type == "Extraction") {
          $("#datetimepicker4").val(props.treatmentPlan.dateOfNextVisit);
        } else if (props.treatmentPlan.type == "Orthodontic Treatment") {
          $("#datetimepicker5").val(props.treatmentPlan.dateOfNextVisit);
        } else if (
          props.treatmentPlan.type == "Prosthetic And Hollywood Smile"
        ) {
          $("#datetimepicker6").val(props.treatmentPlan.dateOfNextVisit);
        } else if (
          props.treatmentPlan.type ==
          "Periodontal Treatment And Teeth Bleaching"
        ) {
          $("#datetimepicker7").val(props.treatmentPlan.dateOfNextVisit);
        } else if (props.treatmentPlan.type == "Teeth Implant") {
          $("#datetimepicker8").val(props.treatmentPlan.dateOfNextVisit);
        } else if (props.treatmentPlan.type == "Paedodontic") {
          $("#datetimepicker9").val(props.treatmentPlan.dateOfNextVisit);
        }

        if (props.disabledMode == "1") {
          if (props.treatmentPlan.type == "Endodontic Treatment") {
            compositeDisable.value = true;
            extractionDisable.value = true;
            prostheticDisable.value = true;
            teethImplantDisable.value = true;
            orthodonticDisable.value = true;
            PeriodontalDisable.value = true;
            paedodonticDisable.value = true;
          } else if (props.treatmentPlan.type == "Composite Restoration") {
            endodonticDisable.value = true;
            extractionDisable.value = true;
            prostheticDisable.value = true;
            teethImplantDisable.value = true;
            orthodonticDisable.value = true;
            PeriodontalDisable.value = true;
            paedodonticDisable.value = true;
          } else if (props.treatmentPlan.type == "Extraction") {
            endodonticDisable.value = true;
            compositeDisable.value = true;
            prostheticDisable.value = true;
            teethImplantDisable.value = true;
            orthodonticDisable.value = true;
            PeriodontalDisable.value = true;
            paedodonticDisable.value = true;
          } else if (props.treatmentPlan.type == "Orthodontic Treatment") {
            endodonticDisable.value = true;
            compositeDisable.value = true;
            prostheticDisable.value = true;
            teethImplantDisable.value = true;
            extractionDisable.value = true;
            PeriodontalDisable.value = true;
            paedodonticDisable.value = true;
          } else if (
            props.treatmentPlan.type == "Prosthetic And Hollywood Smile"
          ) {
            endodonticDisable.value = true;
            compositeDisable.value = true;
            orthodonticDisable.value = true;
            // prostheticDisable.value = true;
            teethImplantDisable.value = true;
            extractionDisable.value = true;
            PeriodontalDisable.value = true;
            paedodonticDisable.value = true;
          } else if (
            props.treatmentPlan.type ==
            "Periodontal Treatment And Teeth Bleaching"
          ) {
            endodonticDisable.value = true;
            compositeDisable.value = true;
            orthodonticDisable.value = true;
            teethImplantDisable.value = true;
            extractionDisable.value = true;
            prostheticDisable.value = true;
            paedodonticDisable.value = true;
          } else if (props.treatmentPlan.type == "Teeth Implant") {
            endodonticDisable.value = true;
            compositeDisable.value = true;
            orthodonticDisable.value = true;
            PeriodontalDisable.value = true;
            extractionDisable.value = true;
            prostheticDisable.value = true;
            paedodonticDisable.value = true;
          } else if (props.treatmentPlan.type == "Paedodontic") {
            endodonticDisable.value = true;
            compositeDisable.value = true;
            orthodonticDisable.value = true;
            PeriodontalDisable.value = true;
            extractionDisable.value = true;
            prostheticDisable.value = true;
            teethImplantDisable.value = true;
          }
        }
      }
      $("#rx-modal").on("hidden.bs.modal", function(e) {
        if (modeOfRx.value == 4) {
          modeOfRx.value = 3;
        }
      });
      $(
        "#endodontic-treatment , #composite , #extraction , #orthodotic-treatment , #teeth-prostheti-and-hollywood-smile , #periodontal-treatment-and-teeth-bleaching , #teeth-implant , #Paedodontic"
      ).on("shown.bs.modal", function() {
        // validation section
        // if (name.value == "") {
        //   $(".modal").modal("hide");
        //   requiredfields.value.push("name");
        //   $("#data-validation-modal").modal("show");
        //   return;
        // }
        rxGeneralInfo;
        rxGeneralInfo.value = {
          name: name.value,
          age: age.value,
          gender: gender.value,
          maritalStatus: maritalStatus.value,
          patientPregnant: patientPregnant.value,
        };
        let monthOfPregnant = false;
        if (patientPregnant.value == true) {
          monthOfPregnant = patientPregnantMonth.value;
        }

        let element = patientPregnantRef.value;
        let style = window.getComputedStyle(element);

        if (style.getPropertyValue("display") == "none") {
          monthOfPregnant = false;
        }

        patientInformation.value = {
          name: name.value,
          occupation: occupation.value,
          phoneNumber: phoneNumber.value,
          gender: gender.value,
          maritalStatus: maritalStatus.value,
          doctorName: doctorName.value,
          age: age.value,
          address: address.value,
          dateOfVisit: dateOfVisit.value,
          diseases: diseases.value,
          moreInformation: moreInformation.value,
          medicationAllergy: medicationAllergy.value,
          previousOperations: previousOperations.value,
          monthOfPregnant: monthOfPregnant,
        };
      });
      $("#rx-modal").on("shown.bs.modal", function() {});
      // add datetimepicker to date selection
      $("#datetimepicker1").datetimepicker();
      $("#datetimepicker2").datetimepicker();
      $("#datetimepicker3").datetimepicker();
      $("#datetimepicker4").datetimepicker();
      $("#datetimepicker5").datetimepicker();
      $("#datetimepicker6").datetimepicker();
      $("#datetimepicker7").datetimepicker();
      $("#datetimepicker8").datetimepicker();
      $("#datetimepicker9").datetimepicker();
      // tooltip
      $('[data-toggle="tooltip"]').tooltip();

      $("#datetimepicker1").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfVisit.value = $input.val();
        },
      });
      $("#datetimepicker2").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitEndodontic.value = $input.val();
        },
      });
      $("#datetimepicker3").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitComposite.value = $input.val();
        },
      });
      $("#datetimepicker4").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitExtraction.value = $input.val();
        },
      });
      $("#datetimepicker5").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitOrthodontic.value = $input.val();
        },
      });
      $("#datetimepicker6").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitProsthetic.value = $input.val();
        },
      });
      $("#datetimepicker7").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitPeriodontal.value = $input.val();
        },
      });
      $("#datetimepicker8").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitTeethImplant.value = $input.val();
        },
      });
      $("#datetimepicker9").datetimepicker({
        timepicker: false,
        onChangeDateTime: function(dp, $input) {
          dateOfNextVisitPaedodontic.value = $input.val();
        },
      });

      $(".selectpicker").selectpicker("refresh");

      let id = await getIdForUserDoc();
      let ref = projectFirestore.collection("users").doc(id);
      const doc = await ref.get();
      let userSettingsAndPresets = doc.data();
      if (userSettingsAndPresets.haveRxSettings) {
        modeOfRx.value = 3;
        rxSettings.value = userSettingsAndPresets.rxSettings;
      } else {
        modeOfRx.value = 1;
        rxSettings.value = null;
      }
      // get the rxGeneralInfo in mounted , important for printing
      rxGeneralInfo.value = {
        name: name.value,
        age: age.value,
        gender: gender.value,
        maritalStatus: maritalStatus.value,
        patientPregnant: patientPregnant.value,
      };
    });
    const startPrint = () => {
      if (props.print == "true") {
        //your code to be executed after 1 second

        let modalToPrint;
        if (props.treatmentPlan.type == "Endodontic Treatment") {
          modalToPrint = "EndodonticTreatmentPrintModal";
        } else if (props.treatmentPlan.type == "Composite Restoration") {
          modalToPrint = "CompositeRestorationPrintModal";
        } else if (props.treatmentPlan.type == "Extraction") {
          modalToPrint = "ExtractionPrintModal";
        } else if (props.treatmentPlan.type == "Orthodontic Treatment") {
          modalToPrint = "OrthodonticTreatmentPrintModal";
        } else if (
          props.treatmentPlan.type == "Prosthetic And Hollywood Smile"
        ) {
          modalToPrint = "ProstheticAndHollywoodSmilePrintModal";
        } else if (
          props.treatmentPlan.type ==
          "Periodontal Treatment And Teeth Bleaching"
        ) {
          modalToPrint = "PeriodontalTreatmentAndTeethBleachingPrintModal";
        } else if (props.treatmentPlan.type == "Teeth Implant") {
          modalToPrint = "TeethImplantPrintModal";
        } else if (props.treatmentPlan.type == "Paedodontic") {
          modalToPrint = "PaedodonticPrintModal";
        }
        const modal = document.getElementById(modalToPrint);
        const cloned = modal.cloneNode(true);
        const toPrinRx = document.getElementById("modalBodyOfRx");
        const clonedToPrintRx = toPrinRx.cloneNode(true);
        let section = document.getElementById("print");
        if (!section) {
          section = document.createElement("div");
          section.id = "print";
          document.body.appendChild(section);
        }

        section.innerHTML = "";
        section.innerHTML += `
          <div class="my-2 mx-4">
                <h1
                  class="font-weight-bold text-capitalize heading-info-font heading-info-color responsive-font-size-h1"
                >
                  ${props.treatmentPlan.type}
                </h1>
          </div>

          `;
        section.appendChild(cloned);
        if (props.rxContainer) {
          section.appendChild(clonedToPrintRx);
        }
        if (props.treatmentPlan.type == "Extraction") {
          document.getElementById("noramlRxMode3").style.display = "none";
          document.getElementById("instractionMode4").style.display = "flex";

          const toPrinRx = document.getElementById("modalBodyOfRx");
          toPrinRx.style.marginTop = "30px";
          const clonedToPrintRx = toPrinRx.cloneNode(true);
          section.appendChild(clonedToPrintRx);

          document.getElementById("noramlRxMode3").style.display = "flex";
          document.getElementById("instractionMode4").style.display = "none";
        }
        // document.body.appendChild(section);
        window.print();
        document.body.removeChild(section);
      }
    };
    const showInstraction = () => {
      if (modeOfRx.value == 3) modeOfRx.value = 4;
    };
    const needSomeRequiredData = (Requiredfields) => {
      requiredfields.value = Requiredfields;
      if (requiredfields.value.includes(0)) {
        errorWhenUploadP.value.classList.remove("d-none");
        errorWhenUploadP.value.innerHTML =
          "الرجاء الذهاب الى الأعدادات و اضافة الأطباء اولا";
        requiredfields.value = [];
      }
      $("#data-validation-modal").modal("show");
    };
    onBeforeUnmount(() => {
      store.commit("zeroForCounterForNewVisitsAdds");
    });
    return {
      name,
      occupation,
      phoneNumber,
      gender,
      maritalStatus,
      doctorName,
      age,
      address,
      dateOfVisit,
      diseases,
      moreInformation,
      medicationAllergy,
      previousOperations,
      patientInformation,
      dateOfNextVisitEndodontic,
      patientPregnantMonth,
      patientPregnant,
      patientPregnantRef,
      endodonticDisable,
      compositeDisable,
      extractionDisable,
      prostheticDisable,
      teethImplantDisable,
      orthodonticDisable,
      PeriodontalDisable,
      paedodonticDisable,
      dateOfNextVisitComposite,
      dateOfNextVisitExtraction,
      dateOfNextVisitProsthetic,
      dateOfNextVisitOrthodontic,
      dateOfNextVisitPeriodontal,
      dateOfNextVisitTeethImplant,
      dateOfNextVisitPaedodontic,
      modeOfRx,
      rxSettings,
      rxGeneralInfo,
      getRx,
      rx,
      showInstraction,
      startPrint,
      requiredfields,
      needSomeRequiredData,
      doctors,
      errorWhenUploadP,
    };
  },
  methods: {},
};
</script>

<style>
/* @media print { */
@media print {
  div.not-print {
    display: none;
  }
  /* You cannot use this property on an empty <div> or on absolutely positioned elements. */
  /* .break-for-printing {
    page-break-before: always;
    top: 100%;
  } */
}
/* body * {
    visibility: hidden;
  }
  #printSection,
  #printSection * {
    visibility: visible;
  }
  #printSection {
    position: absolute;
    left: 0;
    top: 0;
  } */
/* } */
</style>
