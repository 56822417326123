<template>
  <!-- Paedodontic modal -->
  <div class="modal fade" id="Paedodontic">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Paedodontic</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <!-- Modal body -->
        <div
          class="modal-body"
          :class="{ disableform: haveInput == 'true' }"
          id="PaedodonticPrintModal"
        >
          <div class="card">
            <div class="row">
              <div class="d-none col-xl-6 d-xl-block">
                <img
                  src="@/assets/images/paedodontic.webp"
                  class="rounded"
                  style="width:100%; height:1530px;"
                  id="paedodomtic-img"
                />
              </div>
              <div class="col-12 col-xl-6">
                <div class="card-body ml-0 pl-0">
                  <div class="card-title ">
                    <h2>Tooth Number</h2>
                  </div>
                  <div class="card-text">
                    <div
                      class="btn-group btn-group-lg-teeth btn-group-toggle special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div
                      class="btn-group btn-group-toggle btn-group-lg-teeth special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <!--  -->

                    <div
                      class="btn-group btn-group-lg-teeth btn-group-toggle d-flex justify-content-center mt-4"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />E
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />D
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />C
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />B
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />A
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />A
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />B
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />C
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />D
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />E
                      </label>
                    </div>
                    <div
                      class="btn-group btn-group-toggle btn-group-lg-teeth d-flex justify-content-center"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />E
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />D
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />C
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />B
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />A
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />A
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />B
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />C
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />D
                      </label>
                      <label class="btn btn-light active teeth-btn-Paedodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />E
                      </label>
                    </div>

                    <!--  -->
                    <div class="row mt-3">
                      <div
                        class="col-12"
                        v-if="!images.length || edit == 'true'"
                      >
                        <input
                          type="file"
                          id="imgupload1"
                          style="display:none"
                          ref="inputFile"
                          @change="imageUpload"
                        />
                        <button
                          type="button"
                          class="btn btn-rx btn-lg btn-block py-0 h4 upload-image"
                          id="OpenImgUpload1"
                          @click="triggerFunc"
                        >
                          Image Upload
                        </button>
                        <div
                          class="div-of-files d-flex justify-content-center"
                          v-if="arrayOfFiles.length"
                        >
                          <div
                            class="paragraph-file"
                            v-for="file in arrayOfFiles"
                            :key="file"
                            @click="handleClickFile(file)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-12" style="pointer-events: auto;" v-else>
                        <div class="div-of-files d-flex justify-content-center">
                          <div
                            class="paragraph-file"
                            v-for="image in imagesArray"
                            :key="image.url"
                            @click="handleClickFile2(image)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="row">
                        <div class="col mt-2">
                          <h2 class=" mb-3 mt-2">Chief Complaint</h2>
                          <div class="row">
                            <div class="col-12">
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Pain"
                                  v-model="chiefComplaint"
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Pain</label
                                  >
                                </div>
                              </div>
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Swelling"
                                  v-model="chiefComplaint"
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Swelling</label
                                  >
                                </div>
                              </div>
                              <div class="h4 mb-0 pb-0 form-inline">
                                <div class="mb-0 pb-0 ">
                                  <div
                                    class="pretty p-default p-round p-thick h4 mr-2"
                                  >
                                    <input
                                      type="checkbox"
                                      :value="chiefComplaintOtherInput"
                                      v-model="chiefComplaintOtherCheck"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                      ></label>
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control eng-placeholder d-inline"
                                    id="chief-complaint-other-input-p"
                                    v-model="chiefComplaintOtherInput"
                                    placeholder="others......"
                                    style="width: 50%;"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <!-- <div
                            class="h4 mb-0 pb-0 mt-2 form-inline d-block d-sm-none"
                          >
                            <div class="mb-0 pb-0 ">
                              <input
                                type="text"
                                class="form-control eng-placeholder"
                                id="small-screen-chief-complaint-other-input"
                                placeholder="others......"
                                style="width: 40%;"
                              />
                            </div>
                          </div> -->
                          <div class="row">
                            <div class="col mt-3">
                              <p class="h4 mb-3">Oral Habits</p>
                              <div class="row">
                                <div class="col-12 col-sm-8">
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Thumb sucking"
                                      v-model="oralHabits"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Thumb sucking</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Tonque Thrusting"
                                      v-model="oralHabits"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Tonque Thrusting</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Mouth Breathing"
                                      v-model="oralHabits"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Mouth Breathing</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Bruxism"
                                      v-model="oralHabits"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Bruxism</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Nail Biting"
                                      v-model="oralHabits"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Nail Biting</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Lip Biting"
                                      v-model="oralHabits"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Lip Biting</label
                                      >
                                    </div>
                                  </div>
                                  <div class="h4 mb-0 pb-0 form-inline">
                                    <div class="mb-0 pb-0 ">
                                      <div
                                        class="pretty p-default p-round p-thick h4 mr-2"
                                      >
                                        <input
                                          type="checkbox"
                                          :value="oralHabitsOtherInput"
                                          v-model="oralHabitsOtherCheck"
                                        />
                                        <div class="state p-warning-o">
                                          <label
                                            class="pretty p-default p-curve p-fill h4"
                                          ></label>
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control eng-placeholder d-inline"
                                        id="chief-complaint-other-input-11"
                                        v-model="oralHabitsOtherInput"
                                        placeholder="others......"
                                        style="width: 70%;"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <p class="h4 my-3">Treatment Plan</p>
                              <div class="row">
                                <div class="col-12 col-sm-8">
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Scaling And Polising"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Scaling And Polising</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Fluoride Appliaction"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Fluoride Appliaction</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Fissure Sealant"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Fissure Sealant</label
                                      >
                                    </div>
                                  </div>

                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Filling"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Filling</label
                                      >
                                    </div>
                                  </div>

                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="GIC"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >GIC</label
                                      >
                                    </div>
                                  </div>
                                </div>
                                <div class="col-12 col-sm-4">
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Composite"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Composite</label
                                      >
                                    </div>
                                  </div>
                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Pulpotomy"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Pulpotomy</label
                                      >
                                    </div>
                                  </div>

                                  <div
                                    class="pretty p-default p-round p-thick h4 d-block"
                                  >
                                    <input
                                      type="checkbox"
                                      value="Pulpectomy"
                                      v-model="treatmentPlan3"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                        >Pulpectomy</label
                                      >
                                    </div>
                                  </div>

                                  <div class="h4 mb-0 pb-0 form-inline">
                                    <div class="mb-0 pb-0 ">
                                      <div
                                        class="pretty p-default p-round p-thick h4 mr-2"
                                      >
                                        <input
                                          type="checkbox"
                                          :value="treatmentPlan3OtherInput"
                                          v-model="treatmentPlan3OtherCheck"
                                        />
                                        <div class="state p-warning-o">
                                          <label
                                            class="pretty p-default p-curve p-fill h4"
                                          ></label>
                                        </div>
                                      </div>
                                      <input
                                        type="text"
                                        class="form-control eng-placeholder d-inline"
                                        id="chief-complaint-other-input-22"
                                        v-model="treatmentPlan3OtherInput"
                                        placeholder="others......"
                                        style="width: 70%;"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-5"></div>
                              <div class="row mt-3">
                                <div class="col">
                                  <div class="row">
                                    <div class="col">
                                      <h2>Clinical Examination</h2>
                                      <div class="row mt-3">
                                        <div class="col">
                                          <div class="form-group">
                                            <textarea
                                              class="form-control eng-placeholder-lg"
                                              rows="3"
                                              id="extraction-EO"
                                              placeholder="E/O"
                                              v-model="eo"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row mt-3">
                                        <div class="col">
                                          <div class="form-group">
                                            <textarea
                                              class="form-control eng-placeholder-lg"
                                              rows="3"
                                              id="extraction-IO"
                                              placeholder="I/O"
                                              v-model="io"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                      <div class="row mt-3">
                                        <div class="col">
                                          <div class="form-group">
                                            <textarea
                                              class="form-control eng-placeholder-lg"
                                              rows="3"
                                              id="extraction-investigatioms"
                                              placeholder="Investigatioms"
                                              v-model="investigatioms"
                                            ></textarea>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col">
                                      <div class="form-group">
                                        <textarea
                                          class="form-control eng-placeholder-lg"
                                          rows="3"
                                          id="extraction-notes"
                                          placeholder="Your Notes"
                                          v-model="notes"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row">
                                    <div class="col-12 col-sm-6">
                                      <label for="name" class="h4"
                                        >Date Of Next Visit</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control  bg-light datetimepicker"
                                        id="datetimepicker9"
                                      />
                                    </div>
                                    <div class="col-6 col-sm-3">
                                      <label for="name" class="h4">Cost</label>
                                      <input
                                        type="text"
                                        class="form-control bg-light"
                                        v-model="cost"
                                      />
                                    </div>
                                    <div class="col-6 col-sm-3">
                                      <label class="h4" style="color: #ffffff;"
                                        >Cost</label
                                      >
                                      <br />
                                      <button
                                        type="button"
                                        class="btn btn-rx px-4"
                                        data-toggle="modal"
                                        data-target="#rx-modal"
                                        :class="{
                                          pointerEventAuto: rxContainer != null,
                                        }"
                                      >
                                        RX
                                      </button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <!-- Modal footer -->
            <div
              class="modal-footer"
              :class="{ disableform: haveInput == 'true' }"
            >
              <button
                type="button"
                class="btn btn-orange px-5"
                data-dismiss="modal"
                id="paedodontic-btn"
                @click="saveData"
                :class="{ disableformForInput: haveInput == 'true' }"
                v-if="
                  (edit == 'false' || !edit) &&
                    (newVisit == 'false' || !newVisit)
                "
              >
                Save
              </button>
              <button
                type="button"
                class="btn btn-orange px-5"
                data-dismiss="modal"
                id="paedodontic-btn"
                @click="editData"
                v-if="edit == 'true'"
              >
                Save changes
              </button>
              <button
                type="button"
                class="btn btn-orange px-5"
                data-dismiss="modal"
                id="paedodontic-btn"
                @click="saveDataToNewVisit"
                v-if="newVisit == 'true'"
              >
                New Visit
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of file  -->
  <div class="modal" id="modal-of-file-paedodontic" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-body" :class="{ disableform: haveInput == 'true' }">
          <img
            id="file-ip-8-preview"
            style="display:none;"
            class="uploaded-img"
          />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-danger m-2"
              @click="deleteImage"
              :class="{ disableformForInput: haveInput == 'true' }"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2 not-disabled"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed } from "@vue/reactivity";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import { timestamp } from "@/firebase/config";
import $ from "jquery";
import { onMounted } from "@vue/runtime-core";
import getUser from "@/composables/getUser";
import { useStore } from "vuex";
export default {
  props: [
    "patientInformation",
    "dateOfNextVisitPaedodontic",
    "treatmentPlan",
    "haveInput",
    "edit",
    "newVisit",
    "id",
    "haveSubCases",
    "rx",
    "rxContainer",
    "images",
    "sendFromSubCase",
  ],
  emits: ["needSomeRequiredData"],
  setup(props, context) {
    const { updateDoc } = useDocument("patients");
    const { updateDoc: updateVisits } = useDocument("visits");
    const { addDoc } = useCollection("patients");
    const { addDoc: addNewVisit } = useCollection("visits");
    const { user } = getUser();
    const { filePath, url, uploadImage, deleteImage: delImage } = useStorage();
    const store = useStore();

    function getTeethBtnSelected(teethBtnsClass) {
      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);
      console.log(arrayOfTeeth);
      let arrayOfSelectedTeeth = [];
      for (let i = 0; i < arrayOfTeeth.length; i++) {
        if ($(arrayOfTeeth[i]).hasClass("active") == false) {
          arrayOfSelectedTeeth.push(i + 1);
        }
      }
      return arrayOfSelectedTeeth;
    }

    function showTeethBtnSelected(arrayOfSelectedTeeth, teethBtnsClass) {
      // remove class is redundant

      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);

      if (teethBtnsClass == "teeth-btn-endodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-primary"
          );
        }
      } else if (teethBtnsClass == "teeth-btn-composite") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-faroze");
        }
      } else if (teethBtnsClass == "teeth-btn-extraction") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-danger");
        }
      } else if (teethBtnsClass == "teeth-btn-orthodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-dark");
        }
      } else if (teethBtnsClass == "teeth-btn-Implant") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-info");
        }
      } else if (teethBtnsClass == "teeth-btn-periodontal-treatment") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-warning"
          );

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("btn-light");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("active");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).addClass("btn-m-warning");
        }
      } else if (teethBtnsClass == "teeth-btn-prosthetic-and-hollywood-smile") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          // $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-secondary");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("btn-light");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("active");

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).addClass("btn-m-secondary");
        }
      } else if (teethBtnsClass == "teeth-btn-Paedodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-orange");
        }
      }
    }

    const chiefComplaint = ref([]);
    const chiefComplaintOtherInput = ref("");
    const chiefComplaintOtherCheck = ref(false);
    const oralHabits = ref([]);
    const oralHabitsOtherInput = ref("");
    const oralHabitsOtherCheck = ref(false);
    const treatmentPlan3 = ref([]);
    const treatmentPlan3OtherInput = ref("");
    const treatmentPlan3OtherCheck = ref(false);
    const eo = ref("");
    const io = ref("");
    const investigatioms = ref("");
    const notes = ref("");
    const cost = ref("");
    const inputFile = ref(null);
    const arrayOfFiles = ref([]);
    const activeFile = ref(null);
    let activeFile2 = ref(null);
    let imagesArray = ref([]);

    const getData = () => {
      let teeth = getTeethBtnSelected("teeth-btn-Paedodontic");
      if (cost.value == "") {
        cost.value = 0;
      }
      let treatmentPlan = {
        selectedTeeth: teeth,
        chiefComplaint: chiefComplaint.value,
        chiefComplaintOtherInput: chiefComplaintOtherInput.value,
        oralHabits: oralHabits.value,
        oralHabitsOtherInput: oralHabitsOtherInput.value,
        treatmentPlan3: treatmentPlan3.value,
        treatmentPlan3OtherInput: treatmentPlan3OtherInput.value,
        eo: eo.value,
        io: io.value,
        investigatioms: investigatioms.value,
        notes: notes.value,
        cost: cost.value,
        dateOfNextVisit: props.dateOfNextVisitPaedodontic,
      };

      return treatmentPlan;
    };

    const saveData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let res = await addDoc({
        type: "Paedodontic",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        createdAt: timestamp(),
        userId: user.value.uid,
        haveSubCases: 0,
      });
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
    };
    const counterForNewVisitsAdds = computed(
      () => store.state.counterForNewVisitsAdds
    );
    const incrementCounterForNewVisitsAddsByOne = () => {
      store.commit("incrementCounterForNewVisitsAddsByOne");
    };
    const saveDataToNewVisit = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let newHaveSubCases =
        parseInt(props.haveSubCases) + counterForNewVisitsAdds.value;
      let res = await addNewVisit({
        type: "Paedodontic",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        createdAt: timestamp(),
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        userId: user.value.uid,
        mainCaseId: props.id,
        haveSubCases: 0,
      });
      await updateDoc(
        {
          haveSubCases: newHaveSubCases,
        },
        props.id
      );
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
      incrementCounterForNewVisitsAddsByOne();
    };
    const editData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          // if variable is not image it is file
          if (!arrayOfFiles.value[i].url) {
            await uploadImage(arrayOfFiles.value[i], props.treatmentPlan.id);
            images.push({
              url: url.value,
              filePath: filePath.value,
            });
          } else {
            images.push(arrayOfFiles.value[i]);
          }
        }
        if (props.sendFromSubCase == "true") {
          await updateVisits(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        } else {
          await updateDoc(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        }
      }
      if (props.sendFromSubCase == "true") {
        await updateVisits(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      } else {
        await updateDoc(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      }
    };

    onMounted(() => {
      imagesArray.value = props.images;
      // if the page is edit take arrayOfFiles to be array of images => show arrayOfFiles ui , adding image case in arrayOfFiles functions
      if (props.edit == "true") {
        arrayOfFiles.value = imagesArray.value;
      }
      $(".teeth-btn-Paedodontic").on("click", function() {
        if ($(this).hasClass("active") == true) {
          $(this).removeClass("btn-light");
          $(this).addClass("btn-orange");
        } else {
          $(this).removeClass("btn-orange");
          $(this).addClass("btn-light");
        }
      });

      if (props.treatmentPlan && props.treatmentPlan.type == "Paedodontic") {
        showTeethBtnSelected(
          props.treatmentPlan.selectedTeeth,
          "teeth-btn-Paedodontic"
        );
        chiefComplaint.value = props.treatmentPlan.chiefComplaint;
        if (props.treatmentPlan.chiefComplaintOtherInput) {
          chiefComplaintOtherInput.value =
            props.treatmentPlan.chiefComplaintOtherInput;
          chiefComplaintOtherCheck.value = true;
        }
        oralHabits.value = props.treatmentPlan.oralHabits;
        if (props.treatmentPlan.oralHabitsOtherInput) {
          oralHabitsOtherInput.value = props.treatmentPlan.oralHabitsOtherInput;
          oralHabitsOtherCheck.value = true;
        }
        treatmentPlan3.value = props.treatmentPlan.treatmentPlan3;
        if (props.treatmentPlan.treatmentPlan3OtherInput) {
          treatmentPlan3OtherInput.value =
            props.treatmentPlan.treatmentPlan3OtherInput;
          treatmentPlan3OtherCheck.value = true;
        }

        eo.value = props.treatmentPlan.eo;
        io.value = props.treatmentPlan.io;
        investigatioms.value = props.treatmentPlan.investigatioms;
        notes.value = props.treatmentPlan.notes;
        cost.value = props.treatmentPlan.cost;
      }
    });
    const triggerFunc = () => {
      $("#no-need").modal("show");
      return;
      inputFile.value.click();
    };
    const imageUpload = (e) => {
      if (e.target.files.length > 0) {
        if (arrayOfFiles.value.length >= 5) {
          return;
        }
        if (!arrayOfFiles.value.length) {
          $("#paedodomtic-img").height("+=30");
        }
        let selected = e.target.files[0];
        arrayOfFiles.value.push(selected);
      }
    };

    const handleClickFile = (f) => {
      let src;
      activeFile.value = f;
      // if it is image , not file
      if (f.url) {
        src = f.url;
      } else {
        src = URL.createObjectURL(f);
      }
      let preview = document.getElementById("file-ip-8-preview");
      preview.src = src;
      preview.style.display = "block";
      $("#modal-of-file-paedodontic").modal("show");
    };
    const handleClickFile2 = (img) => {
      activeFile2.value = img.filePath;
      let preview = document.getElementById("file-ip-8-preview");
      preview.src = img.url;
      preview.style.display = "block";
      $("#modal-of-file-paedodontic").modal("show");
    };
    const deleteImage = async () => {
      if (activeFile2.value) {
        await delImage(activeFile2.value);
        imagesArray.value.splice(
          imagesArray.value.indexOf(activeFile2.value),
          1
        );
        await updateDoc(
          {
            images: imagesArray.value,
          },
          props.treatmentPlan.id
        );
      } else {
        arrayOfFiles.value.splice(
          arrayOfFiles.value.indexOf(activeFile.value),
          1
        );
        // the page is edit and the image is in the database
        if (props.edit == "true" && activeFile.value.filePath) {
          await delImage(activeFile.value.filePath);
          await updateDoc(
            {
              images: arrayOfFiles.value,
            },
            props.treatmentPlan.id
          );
        }
      }
      let preview = document.getElementById("file-ip-8-preview");
      preview.src = "";
      preview.style.display = "none";
      $("#modal-of-file-paedodontic").modal("hide");
    };
    return {
      chiefComplaint,
      chiefComplaintOtherInput,
      chiefComplaintOtherCheck,
      oralHabits,
      oralHabitsOtherInput,
      oralHabitsOtherCheck,
      treatmentPlan3,
      treatmentPlan3OtherInput,
      treatmentPlan3OtherCheck,
      eo,
      io,
      investigatioms,
      notes,
      cost,
      saveData,
      editData,
      inputFile,
      triggerFunc,
      imageUpload,
      arrayOfFiles,
      handleClickFile,
      deleteImage,
      handleClickFile2,
      imagesArray,
      saveDataToNewVisit,
    };
  },
};
</script>

<style></style>
