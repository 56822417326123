import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import NewPatient from "../views/NewPatient.vue";
import ShowPatients from "../views/ShowPatients.vue";
import Settings from "../views/Settings.vue";
import TodayAppointment from "../views/TodayAppointment.vue";
import Statistics from "../views/Statistics.vue";
import NewVisits from "../views/NewVisits.vue";
import $ from "jquery";
// route guard
import { projectAuth } from "../firebase/config";
let firestTime = true;
const requireAuth = (to, from, next) => {
  let user = projectAuth.currentUser;
  $(".modal").modal("hide");
  if (user && user.emailVerified) {
    if (from.fullPath == "/new-patient" && to.fullPath == "/TodayAppointment") {
      next();
      window.location.reload();
      return;
    }
    next();
  } else {
    next({ name: "Home" });
  }
};

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/new-patient",
    name: "NewPatient",
    component: NewPatient,
    props: true,
    beforeEnter: requireAuth,
  },
  {
    path: "/Show-Patients",
    name: "ShowPatients",
    component: ShowPatients,
    beforeEnter: requireAuth,
  },
  {
    path: "/todayAppointment",
    name: "TodayAppointment",
    component: TodayAppointment,
    beforeEnter: requireAuth,
  },
  {
    path: "/settings",
    name: "Settings",
    component: Settings,
    beforeEnter: requireAuth,
  },
  {
    path: "/statistics",
    name: "Statistics",
    component: Statistics,
    beforeEnter: requireAuth,
  },
  {
    path: "/new-visits/:mainCaseId/:haveSubCases",
    name: "NewVisits",
    component: NewVisits,
    props: true,
    beforeEnter: requireAuth,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
