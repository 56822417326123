<template>
  <!-- Doctors Modal -->
  <div class="modal fade" id="doctors-modal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Add Dentists</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="mb-0">add dentists by press enter key</p>
          <small class="text-muted"
            >the limit of dentists is {{ numberOfDoctors }}, to incrase get me
            an
            <span
              class="text-dark font-weight-bold pointer"
              @click="showIncremental = !showIncremental"
              >incremental code</span
            >
          </small>
          <div class="form-group">
            <div v-if="showIncremental">
              <label class="text-uppercase">incremental code</label>
              <div class="input-group mb-3">
                <input type="text" class="form-control" v-model="paymentCode" />
                <div class="input-group-prepend">
                  <button
                    class="btn btn-outline-secondary"
                    type="button"
                    @click="incraseLimit"
                  >
                    Ok
                  </button>
                </div>
              </div>
            </div>

            <label class="text-uppercase">Dentists</label>
            <div class="input-group mb-3">
              <input
                type="text"
                class="form-control"
                v-model="doctor"
                @keyup.enter="addDoctor()"
              />
              <div class="input-group-append">
                <button
                  class="btn btn-outline-secondary"
                  type="button"
                  @click="addDoctor()"
                >
                  Add
                </button>
              </div>
            </div>
          </div>
          <ul class="list-group">
            <li
              class="list-group-item d-flex justify-content-between align-items-center"
              v-for="d in doctors"
              :key="d"
            >
              {{ d }}
              <span
                class="badge badge-danger badge-pill pointer"
                @click="deleteDoctor(d)"
                >X</span
              >
            </li>
          </ul>
        </div>
        <div class="d-flex justify-content-center">
          <div class="copy-text-general m-2">
            يمكنكم شراء كود التفعيل من خلال صفحتنا على الفيسبوك عدد الأطباء
            المسموح به للعيادات هو 3 اطباء فقط , للزيادة يمكنكم شراء كود تفعيل
            نسخة المراكز الطبية
            <a href="https://www.facebook.com/Myclinic2022">عيادتي</a>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-warning" @click="uploadDoctors">
            Save
          </button>
        </div>
      </div>
    </div>
  </div>
  <Navbar navBackground="white" :fixedTop="false" />
  <div class="container pt-5">
    <!-- <img
      src="https://img.icons8.com/external-kiranshastry-lineal-kiranshastry/64/000000/external-settings-business-and-management-kiranshastry-lineal-kiranshastry-8.png"
    />
    <button
      class="btn btn-warning btn-lg m-2"
      data-toggle="modal"
      data-target="#rx-modal"
    >
      Rx
    </button>
    <button
      class="btn btn-warning btn-lg m-2"
      data-toggle="modal"
      data-target="#rx-modal"
    >
      Instructions
    </button>
    <button
      class="btn btn-warning btn-lg m-2"
      data-toggle="modal"
      data-target="#rx-modal"
    >
      Password
    </button> -->
    <div class="row mt-5">
      <div class="col-12 col-md-4">
        <div class="card">
          <img
            class="card-img-top"
            src="@/assets/images/rx-with-book.svg"
            alt="Card image"
            style="height:300px"
          />
          <div class="card-body d-flex justify-content-center">
            <button
              class="btn btn-warning btn-lg"
              data-toggle="modal"
              data-target="#rx-modal"
            >
              Rx Settings
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <img
            class="card-img-top"
            src="@/assets/images/password.svg"
            alt="Card image"
            style="height:300px"
          />
          <div class="card-body d-flex justify-content-center">
            <!-- <h4 class="card-title">John Doe</h4>
            <p class="card-text">
              Some example text some example text. John Doe is an architect and
              engineer
            </p>
            <a href="#" class="btn btn-primary">See Profile</a> -->
            <button
              class="btn btn-warning btn-lg"
              data-toggle="modal"
              data-target="#changePasswordModal"
            >
              Password
            </button>
          </div>
        </div>
      </div>
      <div class="col-12 col-md-4">
        <div class="card">
          <img
            class="card-img-top"
            src="@/assets/images/Doctors.svg"
            alt="Card image"
            style="height:300px"
          />
          <div class="card-body d-flex justify-content-center">
            <button
              class="btn btn-warning btn-lg"
              data-toggle="modal"
              data-target="#doctors-modal"
            >
              Doctors
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>

  <Rx :mode="2" />
  <ChangePawwsord />
</template>

<script>
import Rx from "../components/Rx.vue";
import Navbar from "../components/Navbar.vue";
import ChangePawwsord from "../components/ChangePawwsord.vue";
import { projectFirestore } from "@/firebase/config";
import getUser from "@/composables/getUser";
import useDocument from "@/composables/useDocument";
import { onMounted, onBeforeUnmount, ref } from "@vue/runtime-core";
import $ from "jquery";
export default {
  components: { Rx, Navbar, ChangePawwsord },
  setup() {
    const { user } = getUser();
    const { updateDoc } = useDocument("users");
    const doctor = ref("");
    const doctors = ref([]);
    const numberOfDoctors = ref(3);
    let idForUpdate;
    const showIncremental = ref(false);
    const paymentCode = ref("");
    const addDoctor = () => {
      if (doctors.value.length < numberOfDoctors.value) {
        doctors.value.push(doctor.value);
        doctor.value = "";
      }
    };
    const deleteDoctor = (doctor) => {
      let index = doctors.value.indexOf(doctor);
      if (index > -1) {
        doctors.value.splice(index, 1);
      }
    };
    const uploadDoctors = async () => {
      await updateDoc(
        {
          numberOfDoctors: numberOfDoctors.value,
          doctors: doctors.value,
        },
        idForUpdate
      );
      $("#doctors-modal").modal("hide");
    };
    const incraseLimit = async () => {
      let codes = await projectFirestore
        .collection("paymentCodes")
        .where(...["code", "==", paymentCode.value])
        .get();
      let code = [];
      codes.forEach((doc) => {
        code.push(doc.data());
      });
      if (code.length > 0) {
        numberOfDoctors.value = code[0].numberOfDoctors;
        await updateDoc(
          {
            numberOfDoctors: numberOfDoctors.value,
            doctors: doctors.value,
          },
          idForUpdate
        );
      }
    };
    onMounted(() => {
      $(".selectpicker").selectpicker("refresh");
      $("#doctors-modal").on("show.bs.modal", async function(e) {
        let userPaymentObj = await projectFirestore
          .collection("users")
          .where(...["userId", "==", user.value.uid])
          .get();
        let userPaymentObjData;
        userPaymentObj.forEach((doc) => {
          idForUpdate = doc.id;
          userPaymentObjData = doc.data();
        });
        numberOfDoctors.value = userPaymentObjData.numberOfDoctors;
        doctors.value = userPaymentObjData.doctors;
      });
    });
    onBeforeUnmount(() => {
      document.body.className = document.body.className.replace("settings", "");
    });
    return {
      doctor,
      doctors,
      addDoctor,
      deleteDoctor,
      numberOfDoctors,
      uploadDoctors,
      showIncremental,
      incraseLimit,
      paymentCode,
    };
  },
  beforeCreate: function() {
    document.body.className = "settings";
  },
};
</script>

<style>
body.settings {
  background-color: #f8f0e3;
}
</style>
<style scoped>
.card {
  /* background: #f4f0db; */
  background: #f8f0e3;
}
/* .btn {
  display: inline-block;
  font-weight: 400;
  color: #212529;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
    border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
} */

/* .btn:hover {
  color: #212529;
  text-decoration: none;
}

.btn:focus,
.btn.focus {
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
}

.btn.disabled,
.btn:disabled {
  opacity: 0.65;
}

a.btn.disabled,
fieldset:disabled a.btn {
  pointer-events: none;
}

.btn-settings {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
} */

/* .btn-settings:hover {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-settings:focus,
.btn-settings.focus {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-settings.disabled,
.btn-settings:disabled {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-settings:not(:disabled):not(.disabled):active,
.btn-settings:not(:disabled):not(.disabled).active,
.show > .btn-settings.dropdown-toggle {
  color: #fff;
  background-color: #ffc107;
  border-color: #ffc107;
} */

/* .btn-settings:not(:disabled):not(.disabled):active:focus,
.btn-settings:not(:disabled):not(.disabled).active:focus,
.show > .btn-settings.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, 0.5);
} */

/* .btn-lg,
.btn-group-lg > .btn {
  padding: 0.9rem 1.4rem;
  font-size: 1.75rem;
  line-height: 1.9;
  border-radius: 0.7rem;
} */
</style>
