<template>
  <Navbar navBackground="white" :fixedTop="false" />
  <div id="calendar"></div>
</template>

<script>
import { onMounted } from "@vue/runtime-core";
import { ref } from "@vue/reactivity";
import Navbar from "../components/Navbar.vue";
import { projectFirestore } from "@/firebase/config";
import getUser from "@/composables/getUser";
import $ from "jquery";
import { useRouter } from "vue-router";
// import evoCalendar from 'evo-calendar/evo-calendar/js/evo-calendar'
export default {
  components: { Navbar },
  setup() {
    const { user } = getUser();
    const router = useRouter();
    const patients = ref([]);
    onMounted(async () => {
      let now = new Date();
      // today date
      let timeForNewVisitStart = new Date().setDate(now.getDate() - 1);
      let timeForNewVisitEnd = new Date().setDate(now.getDate() + 21);
      let ref = projectFirestore
        .collection("patients")
        .where("userId", "==", user.value.uid)
        .where("timestampOfNewVisit", ">=", timeForNewVisitStart)
        .where("timestampOfNewVisit", "<=", timeForNewVisitEnd);
      //   let dataObject;
      //   let arrayOfData = [];
      const data = await ref.get();

      data.forEach((doc) => {
        let numberOfCanalsAndLengthObjJustLengthArray = [];
        let numberOfCanalsAndLengthObjJustCanalArray = [];

        let imagesJustUrl = [];
        let imageJustFilePath = [];

        let prostheticObj = false;
        let hollywoodSmileObj = false;

        let periodontalTreatmentObj = false;
        let TeethBleachingObj = false;

        let btnType;
        if (doc.data().images) {
          doc.data().images.forEach((img) => {
            imagesJustUrl.push(img.url);
            imageJustFilePath.push(img.filePath);
          });
        }
        if (doc.data().type == "Endodontic Treatment") {
          btnType = "btn-primary";

          doc
            .data()
            .treatmentPlan.numberOfCanalsAndLengthObj.forEach(
              (canalAndLenth) => {
                numberOfCanalsAndLengthObjJustLengthArray.push(
                  canalAndLenth.lengthOfCanal
                );
                numberOfCanalsAndLengthObjJustCanalArray.push(
                  canalAndLenth.canalName
                );
              }
            );
        } else if (doc.data().type == "Composite Restoration") {
          btnType = "btn-success";
        } else if (doc.data().type == "Extraction") {
          btnType = "btn-danger";
        } else if (doc.data().type == "Orthodontic Treatment") {
          btnType = "btn-dark";
        } else if (doc.data().type == "Prosthetic And Hollywood Smile") {
          btnType = "btn-secondary";
          if (doc.data().treatmentPlan.prostheticObj) {
            prostheticObj = doc.data().treatmentPlan.prostheticObj;
          } else if (doc.data().treatmentPlan.hollywoodSmileObj) {
            hollywoodSmileObj = doc.data().treatmentPlan.hollywoodSmileObj;
          }
        } else if (
          doc.data().type == "Periodontal Treatment And Teeth Bleaching"
        ) {
          btnType = "btn-warning";
          if (doc.data().treatmentPlan.periodontalTreatmentObj) {
            periodontalTreatmentObj = doc.data().treatmentPlan
              .periodontalTreatmentObj;
          } else if (doc.data().treatmentPlan.TeethBleachingObj) {
            // console.log(doc.data().treatmentPlan.TeethBleachingObj);
            TeethBleachingObj = doc.data().treatmentPlan.TeethBleachingObj;
          }
        } else if (doc.data().type == "Teeth Implant") {
          btnType = "btn-info";
        } else if (doc.data().type == "Paedodontic") {
          btnType = "btn-orange";
        }

        patients.value.push({
          ...doc.data(),
          id: doc.id,
          btnType: btnType,
          numberOfCanalsAndLengthObjJustLengthArray: numberOfCanalsAndLengthObjJustLengthArray,
          numberOfCanalsAndLengthObjJustCanalArray: numberOfCanalsAndLengthObjJustCanalArray,
          prostheticObj,
          hollywoodSmileObj,
          periodontalTreatmentObj,
          TeethBleachingObj,
          imagesJustUrl: imagesJustUrl,
          imageJustFilePath: imageJustFilePath,
        });
      });
      // for visits
      // start operation for visits
      let refForVisits = projectFirestore
        .collection("visits")
        .where("userId", "==", user.value.uid)
        .where("timestampOfNewVisit", ">=", timeForNewVisitStart)
        .where("timestampOfNewVisit", "<=", timeForNewVisitEnd);
      const dataForVisits = await refForVisits.get();
      // end operation for visits
      dataForVisits.forEach((doc) => {
        let numberOfCanalsAndLengthObjJustLengthArray = [];
        let numberOfCanalsAndLengthObjJustCanalArray = [];

        let imagesJustUrl = [];
        let imageJustFilePath = [];

        let prostheticObj = false;
        let hollywoodSmileObj = false;

        let periodontalTreatmentObj = false;
        let TeethBleachingObj = false;

        let btnType;
        if (doc.data().images) {
          doc.data().images.forEach((img) => {
            imagesJustUrl.push(img.url);
            imageJustFilePath.push(img.filePath);
          });
        }
        if (doc.data().type == "Endodontic Treatment") {
          btnType = "btn-primary";

          doc
            .data()
            .treatmentPlan.numberOfCanalsAndLengthObj.forEach(
              (canalAndLenth) => {
                numberOfCanalsAndLengthObjJustLengthArray.push(
                  canalAndLenth.lengthOfCanal
                );
                numberOfCanalsAndLengthObjJustCanalArray.push(
                  canalAndLenth.canalName
                );
              }
            );
        } else if (doc.data().type == "Composite Restoration") {
          btnType = "btn-success";
        } else if (doc.data().type == "Extraction") {
          btnType = "btn-danger";
        } else if (doc.data().type == "Orthodontic Treatment") {
          btnType = "btn-dark";
        } else if (doc.data().type == "Prosthetic And Hollywood Smile") {
          btnType = "btn-secondary";
          if (doc.data().treatmentPlan.prostheticObj) {
            prostheticObj = doc.data().treatmentPlan.prostheticObj;
          } else if (doc.data().treatmentPlan.hollywoodSmileObj) {
            hollywoodSmileObj = doc.data().treatmentPlan.hollywoodSmileObj;
          }
        } else if (
          doc.data().type == "Periodontal Treatment And Teeth Bleaching"
        ) {
          btnType = "btn-warning";
          if (doc.data().treatmentPlan.periodontalTreatmentObj) {
            periodontalTreatmentObj = doc.data().treatmentPlan
              .periodontalTreatmentObj;
          } else if (doc.data().treatmentPlan.TeethBleachingObj) {
            // console.log(doc.data().treatmentPlan.TeethBleachingObj);
            TeethBleachingObj = doc.data().treatmentPlan.TeethBleachingObj;
          }
        } else if (doc.data().type == "Teeth Implant") {
          btnType = "btn-info";
        } else if (doc.data().type == "Paedodontic") {
          btnType = "btn-orange";
        }

        patients.value.push({
          ...doc.data(),
          id: doc.id,
          btnType: btnType,
          numberOfCanalsAndLengthObjJustLengthArray: numberOfCanalsAndLengthObjJustLengthArray,
          numberOfCanalsAndLengthObjJustCanalArray: numberOfCanalsAndLengthObjJustCanalArray,
          prostheticObj,
          hollywoodSmileObj,
          periodontalTreatmentObj,
          TeethBleachingObj,
          imagesJustUrl: imagesJustUrl,
          imageJustFilePath: imageJustFilePath,
        });
      });
      let myEvents = [];
      let event;
      let color;
      patients.value.forEach((p) => {
        switch (p.type) {
          case "Endodontic Treatment": {
            color = "#007bff";
            break;
          }
          case "Composite Restoration": {
            color = "#28a745";
            break;
          }
          case "Extraction": {
            color = "#dc3545";
            break;
          }
          case "Orthodontic Treatment": {
            color = "#343a40";
            break;
          }
          case "Teeth Implant": {
            color = "#17a2b8";
            break;
          }
          case "Prosthetic And Hollywood Smile": {
            color = "#B2BABB";
            break;
          }
          case "Periodontal Treatment And Teeth Bleaching": {
            color = "#ffc107";
            break;
          }
          case "Paedodontic": {
            color = "#ff6a47";
            break;
          }
        }
        event = {
          name: p.patientInformation.name,
          ...p,
          color: color,
          date: new Date(p.timestampOfNewVisit),
        };
        // console.log(event);
        myEvents.push(event);
      });
      //   console.log(data);
      //   data.forEach((doc) => {
      //     dataObject = {
      //       data: doc.data(),
      //       id: doc.id,
      //     };
      //     arrayOfData.push(dataObject);
      //   });

      //   let myEvents = [];
      //   let event;
      //   let color;
      //   arrayOfData.forEach((d) => {
      //     switch (d.data.type) {
      //       case "Endodontic Treatment": {
      //         color = "#007bff";
      //         break;
      //       }
      //       case "Composite Restoration": {
      //         color = "#28a745";
      //         break;
      //       }
      //       case "Extraction": {
      //         color = "#dc3545";
      //         break;
      //       }
      //       case "Orthodontic Treatment": {
      //         color = "#343a40";
      //         break;
      //       }
      //       case "Teeth Implant": {
      //         color = "#17a2b8";
      //         break;
      //       }
      //       case "Prosthetic And Hollywood Smile": {
      //         color = "#B2BABB";
      //         break;
      //       }
      //       case "Periodontal Treatment And Teeth Bleaching": {
      //         color = "#ffc107";
      //         break;
      //       }
      //     }
      //     event = {
      //       id: d.id,
      //       patientInformation: d.data.patientInformation,
      //       treatmentPlan:d.data.treatmentPlan,
      //       rx: d.data.rx,
      //       date: new Date(d.data.timestampOfNewVisit),
      //       type: d.data.type,
      //       color: color,
      //     };
      //     myEvents.push(event);
      //   });
      $("#calendar").evoCalendar({
        calendarEvents: myEvents,
        sidebarToggler: true,
        sidebarDisplayDefault: true,
        eventListToggler: true,
        eventDisplayDefault: true,
        todayHighlight: true,
        theme: "Orange Coral",
      });
      $("#calendar").evoCalendar({
        format: "mm/dd/yyyy",
        titleFormat: "MM yyyy",
        eventHeaderFormat: "MM d, yyyy",
      });

      $("#calendar").on("selectEvent", function(event, activeEvent) {
        // console.log(activeEvent);
        router.push({
          name: "NewPatient",
          params: {
            ...activeEvent.patientInformation,
            ...activeEvent.rx,
            id: activeEvent.id,
            haveInput: true,
            disabledMode: "1",
            edit: false,
            ...activeEvent.treatmentPlan,
            type: activeEvent.type,
            numberOfCanalsAndLengthObjJustLengthArray:
              activeEvent.numberOfCanalsAndLengthObjJustLengthArray,
            numberOfCanalsAndLengthObjJustCanalArray:
              activeEvent.numberOfCanalsAndLengthObjJustCanalArray,
            ...activeEvent.prostheticObj,
            ...activeEvent.hollywoodSmileObj,
            ...activeEvent.periodontalTreatmentObj,
            ...activeEvent.TeethBleachingObj,
            print: false,
            imagesJustUrl: activeEvent.imagesJustUrl,
            imageJustFilePath: activeEvent.imageJustFilePath,
          },
        });
      });
    });
  },
};
</script>

<style scoped>
@import "~evo-calendar/evo-calendar/css/evo-calendar.css";
@import "~evo-calendar/evo-calendar/css/evo-calendar.orange-coral.css";
</style>
