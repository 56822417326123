import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import { projectAuth } from "./firebase/config";

import "jquery";
import "popper.js";
import "./assets/app.css";
import "bootstrap/dist/js/bootstrap.js";
import "bootstrap-select";
import "jquery-datetimepicker/jquery.datetimepicker.css";
import "jquery-datetimepicker";
import "evo-calendar";
import store from "./store/index";
// import VueHtmlToPaper from "vue-html-to-paper";
// deleted this -> .use(router, VueHtmlToPaper)
let app;

projectAuth.onAuthStateChanged(() => {
  if (!app) {
    app = createApp(App)
      .use(router)
      .use(store)
      .mount("#app");
  }
});
// createApp(App)
//   .use(router)
//   .mount("#app");
