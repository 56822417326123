<template>
  <!-- teeth implant modal -->
  <div class="modal fade" id="teeth-implant">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Teeth Implant</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <!-- Modal body -->
        <div
          class="modal-body"
          :class="{ disableform: haveInput == 'true' }"
          id="TeethImplantPrintModal"
        >
          <div class="card">
            <div class="row">
              <div class="d-none col-xl-6 d-xl-block">
                <img
                  src="@/assets/images/teeth-implant.webp"
                  class="rounded"
                  style="width:100%; height:100%;"
                  id="teeth-implant"
                />
              </div>
              <div class="col-12 col-xl-6">
                <div class="card-body ml-0 pl-0">
                  <div class="card-title ">
                    <h2>Tooth Number</h2>
                  </div>
                  <div class="card-text">
                    <div
                      class="btn-group btn-group-lg-teeth btn-group-toggle special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div
                      class="btn-group btn-group-toggle btn-group-lg-teeth special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-Implant">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-12"
                        v-if="!images.length || edit == 'true'"
                      >
                        <input
                          type="file"
                          id="imgupload1"
                          style="display:none"
                          ref="inputFile"
                          @change="imageUpload"
                        />
                        <button
                          type="button"
                          class="btn btn-rx btn-lg btn-block py-0 h4 upload-image"
                          id="OpenImgUpload1"
                          @click="triggerFunc"
                        >
                          Image Upload
                        </button>
                        <div
                          class="div-of-files d-flex justify-content-center"
                          v-if="arrayOfFiles.length"
                        >
                          <div
                            class="paragraph-file"
                            v-for="file in arrayOfFiles"
                            :key="file"
                            @click="handleClickFile(file)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-12" style="pointer-events: auto;" v-else>
                        <div class="div-of-files d-flex justify-content-center">
                          <div
                            class="paragraph-file"
                            v-for="image in imagesArray"
                            :key="image.url"
                            @click="handleClickFile2(image)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div>
                      <div class="row">
                        <div class="col mt-2">
                          <div class="row mt-3">
                            <div class="col">
                              <h2>Mdical History</h2>
                              <div class="row d-none d-sm-flex">
                                <div class="col-sm-6 col-md-5 col-lg-6">
                                  <label for="name" class="h4 mb-4 d-block"
                                    >Blood Pressure</label
                                  >
                                  <label for="name" class="h4 mb-4 d-block"
                                    >Blood Suger Level</label
                                  >
                                  <label for="name" class="h4 mb-4 d-block"
                                    >Bone Ratio</label
                                  >
                                </div>
                                <div class="col-sm-5 col-md-4 col-lg-4">
                                  <div class="row">
                                    <div class="col-12">
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        id="teeth-implant-blood-pressure"
                                        v-model="bloodPressure"
                                      />
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        id="teeth-implant-blood-suger-level"
                                        v-model="bloodSugerLevel"
                                      />
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="boneRatio"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row d-flex d-sm-none">
                                <div class="col-12">
                                  <label for="name" class="h4 mb-4 d-block"
                                    >Blood Pressure</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    v-model="bloodPressure"
                                  />
                                  <label for="name" class="h4 mb-4 d-block"
                                    >Blood Suger Level</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    v-model="bloodSugerLevel"
                                  />
                                  <label for="name" class="h4 mb-4 d-block"
                                    >Bone Ratio</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    id="teeth-implant-bone-ratio"
                                    v-model="boneRatio"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col">
                              <div class="row">
                                <div class="col">
                                  <h2>Clinical Examination</h2>
                                  <div class="row mt-3">
                                    <div class="col">
                                      <div class="form-group">
                                        <textarea
                                          class="form-control eng-placeholder-lg"
                                          rows="3"
                                          id="teeth-implant-EO"
                                          placeholder="E/O"
                                          v-model="eo"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col">
                                      <div class="form-group">
                                        <textarea
                                          class="form-control eng-placeholder-lg"
                                          rows="3"
                                          id="teeth-implant-IO"
                                          placeholder="I/O"
                                          v-model="io"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col">
                                      <div class="form-group">
                                        <textarea
                                          class="form-control eng-placeholder-lg"
                                          rows="3"
                                          id="teeth-implant-investigatioms"
                                          placeholder="Investigatioms"
                                          v-model="investigatioms"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="col-12">
                                  <table
                                    class="table table-bordered"
                                    style="width: 100%;"
                                  >
                                    <thead>
                                      <tr>
                                        <th scope="col">
                                          <h4>Treatment Procedure</h4>
                                        </th>
                                        <th scope="col">
                                          <h4>Date</h4>
                                        </th>
                                        <th scope="col">
                                          <h4>Notes</h4>
                                        </th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr>
                                        <td width="30%">Placement Surgery</td>
                                        <td width="30%">
                                          <input
                                            type="text"
                                            class="form-control  bg-light datetimepicker"
                                            id="placement-surgery-date"
                                            v-model="placementSurgeryD"
                                          />
                                        </td>
                                        <td width="40%">
                                          <input
                                            type="text"
                                            class="form-control bg-light"
                                            id="placement-surgery-notes"
                                            v-model="placementSurgeryN"
                                          />
                                        </td>
                                      </tr>
                                      <tr>
                                        <td width="30%">Stage 2 Surgery</td>
                                        <td width="30%">
                                          <input
                                            type="text"
                                            class="form-control  bg-light datetimepicker"
                                            id="stage-2-surgery-date"
                                            v-model="stage2SurgeryD"
                                          />
                                        </td>
                                        <td width="40%">
                                          <input
                                            type="text"
                                            class="form-control bg-light"
                                            id="stage-2-surgery-notes"
                                            v-model="stage2SurgeryN"
                                          />
                                        </td>
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                <div class="col-12">
                                  <div class="row d-none d-sm-flex">
                                    <div class="col-7 col-sm-5">
                                      <label for="name" class="h4 mb-4 d-block"
                                        >Type Of Implant</label
                                      >
                                      <label for="name" class="h4 mb-4 d-block"
                                        >Color Shade</label
                                      >
                                    </div>
                                    <div class="col-5 col-sm-4">
                                      <div class="row">
                                        <div class="col-12">
                                          <input
                                            type="text"
                                            class="form-control bg-light mb-2"
                                            id="teeth-implant-type-of-implant"
                                            v-model="typeOfImplant"
                                          />
                                          <input
                                            type="text"
                                            class="form-control bg-light mb-2"
                                            id="teeth-implant-color-shade"
                                            v-model="colorShade"
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row d-flex d-sm-none">
                                    <div class="col">
                                      <label for="name" class="h4 mb-4 d-block"
                                        >Type Of Implant</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="typeOfImplant"
                                      />
                                      <label for="name" class="h4 mb-4 d-block"
                                        >Color Shade</label
                                      >
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="colorShade"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col">
                                  <div class="form-group">
                                    <textarea
                                      class="form-control eng-placeholder-lg"
                                      rows="3"
                                      id="teeth-implant-notes"
                                      placeholder="Your Notes"
                                      v-model="notes"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 col-sm-6">
                                  <label for="name" class="h4"
                                    >Date Of Next Visit</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control  bg-light datetimepicker"
                                    id="datetimepicker8"
                                  />
                                </div>
                                <div class="col-6 col-sm-3">
                                  <label for="name" class="h4">Cost</label>
                                  <input
                                    type="text"
                                    class="form-control bg-light"
                                    v-model="cost"
                                  />
                                </div>
                                <div class="col-6 col-sm-3">
                                  <label class="h4" style="color: #ffffff;"
                                    >Cost</label
                                  >
                                  <br />
                                  <button
                                    type="button"
                                    class="btn btn-rx px-4"
                                    data-toggle="modal"
                                    data-target="#rx-modal"
                                    :class="{
                                      pointerEventAuto: rxContainer != null,
                                    }"
                                  >
                                    RX
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer" :class="{ disableform: haveInput == 'true' }">
          <button
            type="button"
            class="btn btn-info px-5"
            data-dismiss="modal"
            id="teeth-implant-btn"
            @click="saveData"
            :class="{ disableformForInput: haveInput == 'true' }"
            v-if="
              (edit == 'false' || !edit) && (newVisit == 'false' || !newVisit)
            "
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-info px-5"
            data-dismiss="modal"
            id="teeth-implant-btn"
            @click="editData"
            v-if="edit == 'true'"
          >
            Save changes
          </button>
          <button
            type="button"
            class="btn btn-info px-5"
            data-dismiss="modal"
            id="teeth-implant-btn"
            @click="saveDataToNewVisit"
            v-if="newVisit == 'true'"
          >
            New Visit
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of file  -->
  <div
    class="modal"
    id="modal-of-file-teeth-implant"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-body" :class="{ disableform: haveInput == 'true' }">
          <img
            id="file-ip-6-preview"
            style="display:none;"
            class="uploaded-img"
          />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-danger m-2"
              @click="deleteImage"
              :class="{ disableformForInput: haveInput == 'true' }"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2 not-disabled"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { ref, computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import { timestamp } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { useStore } from "vuex";
export default {
  props: [
    "patientInformation",
    "dateOfNextVisitTeethImplant",
    "treatmentPlan",
    "haveInput",
    "edit",
    "newVisit",
    "haveSubCases",
    "id",
    "rx",
    "rxContainer",
    "images",
    "sendFromSubCase",
  ],
  emits: ["needSomeRequiredData"],
  setup(props, context) {
    const { updateDoc } = useDocument("patients");
    const { updateDoc: updateVisits } = useDocument("visits");
    const { addDoc } = useCollection("patients");
    const { addDoc: addNewVisit } = useCollection("visits");
    const { user } = getUser();
    const { filePath, url, uploadImage, deleteImage: delImage } = useStorage();
    const store = useStore();

    function getTeethBtnSelected(teethBtnsClass) {
      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);
      console.log(arrayOfTeeth);
      let arrayOfSelectedTeeth = [];
      for (let i = 0; i < arrayOfTeeth.length; i++) {
        if ($(arrayOfTeeth[i]).hasClass("active") == false) {
          arrayOfSelectedTeeth.push(i + 1);
        }
      }
      return arrayOfSelectedTeeth;
    }
    function showTeethBtnSelected(arrayOfSelectedTeeth, teethBtnsClass) {
      // remove class is redundant

      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);

      if (teethBtnsClass == "teeth-btn-endodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-primary"
          );
        }
      } else if (teethBtnsClass == "teeth-btn-composite") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-faroze");
        }
      } else if (teethBtnsClass == "teeth-btn-extraction") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-danger");
        }
      } else if (teethBtnsClass == "teeth-btn-orthodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-dark");
        }
      } else if (teethBtnsClass == "teeth-btn-Implant") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-info");
        }
      } else if (teethBtnsClass == "teeth-btn-periodontal-treatment") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-warning"
          );

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("btn-light");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("active");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).addClass("btn-m-warning");
        }
      } else if (teethBtnsClass == "teeth-btn-prosthetic-and-hollywood-smile") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          // $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-secondary");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("btn-light");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("active");

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).addClass("btn-m-secondary");
        }
      }
    }

    const bloodPressure = ref("");
    const bloodSugerLevel = ref("");
    const boneRatio = ref("");
    const eo = ref("");
    const io = ref("");
    const investigatioms = ref("");
    const placementSurgeryD = ref("");
    const placementSurgeryN = ref("");
    const stage2SurgeryD = ref("");
    const stage2SurgeryN = ref("");
    const typeOfImplant = ref("");
    const colorShade = ref("");
    const notes = ref("");
    const cost = ref("");
    const inputFile = ref(null);
    const arrayOfFiles = ref([]);
    const activeFile = ref(null);
    let activeFile2 = ref(null);
    let imagesArray = ref([]);
    if (cost.value == "") {
      cost.value = 0;
    }
    const getData = () => {
      let teeth = getTeethBtnSelected("teeth-btn-Implant");
      let treatmentPlan = {
        selectedTeeth: teeth,
        bloodPressure: bloodPressure.value,
        bloodSugerLevel: bloodSugerLevel.value,
        boneRatio: boneRatio.value,
        eo: eo.value,
        io: io.value,
        investigatioms: investigatioms.value,
        placementSurgeryD: placementSurgeryD.value,
        placementSurgeryN: placementSurgeryN.value,
        stage2SurgeryD: stage2SurgeryD.value,
        stage2SurgeryN: stage2SurgeryN.value,
        typeOfImplant: typeOfImplant.value,
        colorShade: colorShade.value,
        notes: notes.value,
        cost: cost.value,
        dateOfNextVisit: props.dateOfNextVisitTeethImplant,
      };
      return treatmentPlan;
    };

    const saveData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let res = await addDoc({
        type: "Teeth Implant",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        createdAt: timestamp(),
        userId: user.value.uid,
        haveSubCases: 0,
      });
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
    };
    const counterForNewVisitsAdds = computed(
      () => store.state.counterForNewVisitsAdds
    );
    const incrementCounterForNewVisitsAddsByOne = () => {
      store.commit("incrementCounterForNewVisitsAddsByOne");
    };
    const saveDataToNewVisit = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let newHaveSubCases =
        parseInt(props.haveSubCases) + counterForNewVisitsAdds.value;
      let res = await addNewVisit({
        type: "Teeth Implant",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        createdAt: timestamp(),
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        userId: user.value.uid,
        mainCaseId: props.id,
        haveSubCases: 0,
      });
      await updateDoc(
        {
          haveSubCases: newHaveSubCases,
        },
        props.id
      );
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
      incrementCounterForNewVisitsAddsByOne();
    };
    const editData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          // if variable is not image it is file
          if (!arrayOfFiles.value[i].url) {
            await uploadImage(arrayOfFiles.value[i], props.treatmentPlan.id);
            images.push({
              url: url.value,
              filePath: filePath.value,
            });
          } else {
            images.push(arrayOfFiles.value[i]);
          }
        }
        if (props.sendFromSubCase == "true") {
          await updateVisits(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        } else {
          await updateDoc(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        }
      }
      if (props.sendFromSubCase == "true") {
        await updateVisits(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      } else {
        await updateDoc(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      }
    };

    onMounted(() => {
      imagesArray.value = props.images;
      // if the page is edit take arrayOfFiles to be array of images => show arrayOfFiles ui , adding image case in arrayOfFiles functions
      if (props.edit == "true") {
        arrayOfFiles.value = imagesArray.value;
      }
      $(".teeth-btn-Implant").on("click", function() {
        if ($(this).hasClass("active") == true) {
          $(this).removeClass("btn-light");
          $(this).addClass("btn-info");
        } else {
          $(this).removeClass("btn-info");
          $(this).addClass("btn-light");
        }
      });

      if (props.treatmentPlan && props.treatmentPlan.type == "Teeth Implant") {
        showTeethBtnSelected(
          props.treatmentPlan.selectedTeeth,
          "teeth-btn-Implant"
        );
        bloodPressure.value = props.treatmentPlan.bloodPressure;
        bloodSugerLevel.value = props.treatmentPlan.bloodSugerLevel;
        boneRatio.value = props.treatmentPlan.boneRatio;
        eo.value = props.treatmentPlan.eo;
        io.value = props.treatmentPlan.io;
        investigatioms.value = props.treatmentPlan.investigatioms;
        placementSurgeryD.value = props.treatmentPlan.placementSurgeryD;
        placementSurgeryN.value = props.treatmentPlan.placementSurgeryN;
        stage2SurgeryD.value = props.treatmentPlan.stage2SurgeryD;
        stage2SurgeryN.value = props.treatmentPlan.stage2SurgeryN;
        typeOfImplant.value = props.treatmentPlan.typeOfImplant;
        colorShade.value = props.treatmentPlan.colorShade;
        notes.value = props.treatmentPlan.notes;
        cost.value = props.treatmentPlan.cost;
      }
    });
    const triggerFunc = () => {
      $("#no-need").modal("show");
      return;
      inputFile.value.click();
    };
    const imageUpload = (e) => {
      if (e.target.files.length > 0) {
        if (arrayOfFiles.value.length >= 5) {
          return;
        }
        if (!arrayOfFiles.value.length) {
          $("#teeth-implant").height("+=30");
        }
        let selected = e.target.files[0];
        arrayOfFiles.value.push(selected);
      }
    };

    const handleClickFile = (f) => {
      let src;
      activeFile.value = f;
      // if it is image , not file
      if (f.url) {
        src = f.url;
      } else {
        src = URL.createObjectURL(f);
      }
      let preview = document.getElementById("file-ip-6-preview");
      preview.src = src;
      preview.style.display = "block";
      $("#modal-of-file-teeth-implant").modal("show");
    };
    const handleClickFile2 = (img) => {
      activeFile2.value = img.filePath;
      let preview = document.getElementById("file-ip-6-preview");
      preview.src = img.url;
      preview.style.display = "block";
      $("#modal-of-file-teeth-implant").modal("show");
    };
    const deleteImage = async () => {
      if (activeFile2.value) {
        await delImage(activeFile2.value);
        imagesArray.value.splice(
          imagesArray.value.indexOf(activeFile2.value),
          1
        );
        await updateDoc(
          {
            images: imagesArray.value,
          },
          props.treatmentPlan.id
        );
      } else {
        arrayOfFiles.value.splice(
          arrayOfFiles.value.indexOf(activeFile.value),
          1
        );
        // the page is edit and the image is in the database
        if (props.edit == "true" && activeFile.value.filePath) {
          await delImage(activeFile.value.filePath);
          await updateDoc(
            {
              images: arrayOfFiles.value,
            },
            props.treatmentPlan.id
          );
        }
      }
      let preview = document.getElementById("file-ip-6-preview");
      preview.src = "";
      preview.style.display = "none";
      $("#modal-of-file-teeth-implant").modal("hide");
    };
    return {
      bloodPressure,
      bloodSugerLevel,
      boneRatio,
      eo,
      io,
      investigatioms,
      placementSurgeryD,
      placementSurgeryN,
      stage2SurgeryD,
      stage2SurgeryN,
      typeOfImplant,
      colorShade,
      notes,
      cost,
      saveData,
      editData,
      inputFile,
      triggerFunc,
      imageUpload,
      arrayOfFiles,
      handleClickFile,
      deleteImage,
      handleClickFile2,
      imagesArray,
      saveDataToNewVisit,
    };
  },
};
</script>

<style scoped>
@media (min-width: 500px) {
  h4,
  .h4 {
    font-size: 1.2rem !important;
  }
}
@media (min-width: 800px) {
  h4,
  .h4 {
    font-size: 1.5rem !important;
  }
}
</style>
