import getUser from "@/composables/getUser";
import { projectFirestore } from "@/firebase/config";
import { ref } from "@vue/runtime-core";
// drawAllData must be call first in component because it get the data from the firestore
const { user } = getUser();
let planetChartData;
let PieChartData;
const totalCost = ref(0);
const doctors = ref([]);
const arrayOfDoctorsCost = ref(null);
// [0] => Endodontic Treatment
// [1] => Composite Restoration
// [2] => Extraction
// [3] => Orthodontic Treatment
// [4] => Periodontal Treatment And Teeth Bleaching
// [5] => Prosthetic And Hollywood Smile
// [6] => Teeth Implant
// [7] => Paedodontic
let arrayOfPieChartData = [0, 0, 0, 0, 0, 0, 0, 0];

const drawAllData = async () => {
  // for doctor selection
  let userPaymentObj = await projectFirestore
    .collection("users")
    .where(...["userId", "==", user.value.uid])
    .get();
  let userPaymentObjData;
  userPaymentObj.forEach((doc) => {
    userPaymentObjData = doc.data();
  });

  doctors.value = userPaymentObjData.doctors;

  arrayOfDoctorsCost.value = new Array(doctors.value.length).fill(0);

  let labelsArray = [];
  let arrayOfCosts = [];
  // get the numbe of days in this month
  let dt = new Date();
  let month = dt.getMonth() + 1;
  let year = dt.getFullYear();
  let daysInMonth = new Date(year, month, 0).getDate();
  // fill labelsArray
  for (let i = 1; i <= daysInMonth; i++) {
    let fullDate = month + "-" + i;
    labelsArray.push(fullDate);
    arrayOfCosts.push(0);
  }
  // get the date of the last day of the month
  let lastDayOfTheMonthDate = new Date(year, month - 1, daysInMonth);

  let timeForStart = new Date().setDate(
    lastDayOfTheMonthDate.getDate() - daysInMonth
  );
  let timeForEnd = new Date().setDate(lastDayOfTheMonthDate.getDate());

  let ref = projectFirestore
    .collection("patients")
    .where("userId", "==", user.value.uid)
    .where("timestampOfVisit", ">=", timeForStart)
    .where("timestampOfVisit", "<=", timeForEnd);
  const data = await ref.get();

  let timestampOfVisit = data.docs[0].data().timestampOfVisit;
  let dateObject = new Date(timestampOfVisit);
  let pointer = dateObject.getDate();
  let cost = 0;
  for (let i = 0; i < data.docs.length; i++) {
    totalCost.value += parseInt(data.docs[i].data().treatmentPlan.cost);
    // start doctors section
    for (let j = 0; j < doctors.value.length; j++) {
      if (
        data.docs[i].data().patientInformation.doctorName.trim() ==
        doctors.value[j].trim()
      ) {
        arrayOfDoctorsCost.value[j] += parseInt(
          data.docs[i].data().treatmentPlan.cost
        );
        break;
      }
    }
    // end doctors section
    if (data.docs[i].data().type == "Endodontic Treatment") {
      // start cercular section => PieChartData
      arrayOfPieChartData[0]++;
    } else if (data.docs[i].data().type == "Composite Restoration") {
      arrayOfPieChartData[1]++;
    } else if (data.docs[i].data().type == "Extraction") {
      arrayOfPieChartData[2]++;
    } else if (data.docs[i].data().type == "Orthodontic Treatment") {
      arrayOfPieChartData[3]++;
    } else if (
      data.docs[i].data().type == "Periodontal Treatment And Teeth Bleaching"
    ) {
      arrayOfPieChartData[4]++;
    } else if (data.docs[i].data().type == "Prosthetic And Hollywood Smile") {
      arrayOfPieChartData[5]++;
    } else if (data.docs[i].data().type == "Teeth Implant") {
      arrayOfPieChartData[6]++;
    } else if (data.docs[i].data().type == "Paedodontic") {
      arrayOfPieChartData[7]++;
    }
    // end cercular section => PieChartData
    // console.log(data.docs[i].data().treatmentPlan.cost);
    timestampOfVisit = data.docs[i].data().timestampOfVisit;

    dateObject = new Date(timestampOfVisit);

    if (pointer == dateObject.getDate()) {
      cost += parseInt(data.docs[i].data().treatmentPlan.cost);
      pointer = dateObject.getDate();
      if (i == data.docs.length - 1) {
        // arrayOfCosts.push(cost);
        arrayOfCosts[pointer - 1] = cost;
      }
    } else {
      // arrayOfCosts.push(cost);
      arrayOfCosts[pointer - 1] = cost;
      cost = parseInt(data.docs[i].data().treatmentPlan.cost);
      pointer = dateObject.getDate();
      if (i == data.docs.length - 1) {
        // arrayOfCosts.push(cost);
        arrayOfCosts[pointer - 1] = cost;
      }
    }
  }
  // start visits section
  let refForVisits = projectFirestore
    .collection("visits")
    .where("userId", "==", user.value.uid)
    .where("timestampOfVisit", ">=", timeForStart)
    .where("timestampOfVisit", "<=", timeForEnd);
  const dataForVisits = await refForVisits.get();
  let timestampOfVisitForVisits;
  let dateObjectForVisits;
  let pointerForVisits;
  if (dataForVisits.docs[0]) {
    timestampOfVisitForVisits = dataForVisits.docs[0].data().timestampOfVisit;
    dateObjectForVisits = new Date(timestampOfVisitForVisits);
    pointerForVisits = dateObjectForVisits.getDate();
  }

  for (let i = 0; i < dataForVisits.docs.length; i++) {
    totalCost.value += parseInt(
      dataForVisits.docs[i].data().treatmentPlan.cost
    );
    // start doctors section
    for (let j = 0; j < doctors.value.length; j++) {
      if (
        dataForVisits.docs[i].data().patientInformation.doctorName.trim() ==
        doctors.value[j].trim()
      ) {
        arrayOfDoctorsCost.value[j] += parseInt(
          dataForVisits.docs[i].data().treatmentPlan.cost
        );
        break;
      }
    }

    if (dataForVisits.docs[i].data().type == "Endodontic Treatment") {
      // end doctors section
      // start cercular section => PieChartData
      arrayOfPieChartData[0]++;
    } else if (dataForVisits.docs[i].data().type == "Composite Restoration") {
      arrayOfPieChartData[1]++;
    } else if (dataForVisits.docs[i].data().type == "Extraction") {
      arrayOfPieChartData[2]++;
    } else if (dataForVisits.docs[i].data().type == "Orthodontic Treatment") {
      arrayOfPieChartData[3]++;
    } else if (
      data.docs[i].data().type == "Periodontal Treatment And Teeth Bleaching"
    ) {
      arrayOfPieChartData[4]++;
    } else if (
      dataForVisits.docs[i].data().type == "Prosthetic And Hollywood Smile"
    ) {
      arrayOfPieChartData[5]++;
    } else if (dataForVisits.docs[i].data().type == "Teeth Implant") {
      arrayOfPieChartData[6]++;
    } else if (dataForVisits.docs[i].data().type == "Paedodontic") {
      arrayOfPieChartData[7]++;
    }
    // end cercular section => PieChartData
    // console.log(data.docs[i].data().treatmentPlan.cost);
    timestampOfVisitForVisits = dataForVisits.docs[i].data().timestampOfVisit;

    dateObjectForVisits = new Date(timestampOfVisitForVisits);
    console.log(pointerForVisits, dateObjectForVisits.getDate());
    if (pointerForVisits == dateObjectForVisits.getDate()) {
      cost += parseInt(dataForVisits.docs[i].data().treatmentPlan.cost);
      pointerForVisits = dateObjectForVisits.getDate();
      if (i == dataForVisits.docs.length - 1) {
        // arrayOfCosts.push(cost);
        arrayOfCosts[pointerForVisits - 1] = cost;
      }
    } else {
      // arrayOfCosts.push(cost);
      arrayOfCosts[pointerForVisits - 1] = cost;
      cost = parseInt(dataForVisits.docs[i].data().treatmentPlan.cost);
      pointerForVisits = dateObject.getDate();
      if (i == dataForVisits.docs.length - 1) {
        // arrayOfCosts.push(cost);
        arrayOfCosts[pointerForVisits - 1] = cost;
      }
    }
  }
  // end visits section

  planetChartData = {
    type: "bar",
    data: {
      labels: labelsArray,
      datasets: [
        {
          label: "الربح اليومي خلال الشهر",
          data: arrayOfCosts,
          backgroundColor: [
            "rgba(255, 99, 132, 0.8)",
            "rgba(54, 162, 235, 0.8)",
            "rgba(255, 206, 86, 0.8)",
            "rgba(75, 192, 192, 0.8)",
            "rgba(153, 102, 255, 0.8)",
            "rgba(255, 159, 64, 0.8)",
            "rgba(255, 99, 132, 0.8)",
            "rgba(54, 162, 235, 0.8)",
            "rgba(255, 206, 86, 0.8)",
            "rgba(75, 192, 192, 0.8)",
            "rgba(153, 102, 255, 0.8)",
            "rgba(255, 159, 64, 0.8)",
            "rgba(255, 99, 132, 0.8)",
            "rgba(54, 162, 235, 0.8)",
            "rgba(255, 206, 86, 0.8)",
            "rgba(75, 192, 192, 0.8)",
            "rgba(153, 102, 255, 0.8)",
            "rgba(255, 159, 64, 0.8)",
            "rgba(255, 99, 132, 0.8)",
            "rgba(54, 162, 235, 0.8)",
            "rgba(255, 206, 86, 0.8)",
            "rgba(75, 192, 192, 0.8)",
            "rgba(153, 102, 255, 0.8)",
            "rgba(255, 159, 64, 0.8)",
            "rgba(255, 99, 132, 0.8)",
            "rgba(54, 162, 235, 0.8)",
            "rgba(255, 206, 86, 0.8)",
            "rgba(75, 192, 192, 0.8)",
            "rgba(153, 102, 255, 0.8)",
            "rgba(255, 159, 64, 0.8)",
          ],
          borderColor: [
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
            "rgba(255, 99, 132, 1)",
            "rgba(54, 162, 235, 1)",
            "rgba(255, 206, 86, 1)",
            "rgba(75, 192, 192, 1)",
            "rgba(153, 102, 255, 1)",
            "rgba(255, 159, 64, 1)",
          ],
          borderWidth: 1,
        },
      ],
    },
    options: {
      responsive: true,
      lineTension: 1,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 25,
            },
          },
        ],
      },
    },
  };
  return planetChartData;
};

const circularDraw = () => {
  PieChartData = {
    type: "pie",
    data: {
      labels: [
        "Endodontic Treatment",
        "Composite Restoration",
        "Extraction",
        "Orthodontic Treatment",
        "Periodontal Treatment And Teeth Bleaching",
        "Prosthetic And Hollywood Smile",
        "Teeth Implant",
        "Paedodontic",
      ],
      datasets: [
        {
          data: arrayOfPieChartData,
          backgroundColor: [
            "#007bff",
            "#28a745",
            "#dc3545",
            "#343a40",
            "#ffc107",
            "#B2BABB",
            "#17a2b8",
            "#ff6a47",
          ],
        },
      ],
    },
    options: {
      responsive: true,
      lineTension: 1,
      scales: {
        yAxes: [
          {
            ticks: {
              beginAtZero: true,
              padding: 25,
            },
          },
        ],
      },
    },
  };
  return PieChartData;
};
const debtFunction = () => {};
const usePlanetData = () => {
  return { drawAllData, circularDraw, totalCost, doctors, arrayOfDoctorsCost };
};

export default usePlanetData;
