<template>
  <div class="container">
    <div class="row m-5">
      <div class="col-12 ">
        <div class="card">
          <div class="card-header text-center">الأحصائيات الشهرية للوارد</div>
          <div class="card-body">
            <div>
              <canvas id="planet-chart"></canvas>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 ">
        <div class="card">
          <div class="card-header text-center">الأكثر خلال الشهر</div>
          <div class="card-body">
            <div>
              <div>
                <canvas id="pie-chart"></canvas>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-12 text-arab">
        <div class="card">
          <div class="card-header text-center">الوارد من بداية الشهر</div>
          <div class="card-body">
            <div>
              <p class="font-in-chart">
                الوارد الكلي
                <span class="border-in-chart px-3">{{ totalCost }}</span>
              </p>
              <p
                class="font-in-chart"
                v-for="(doctor, i) in doctors"
                :key="doctor"
              >
                {{ doctor }}
                <span class="border-in-chart px-3">{{
                  arrayOfDoctorsCost[i]
                }}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import usePlanetData from "../../composables/usePlanetData.js";
import { onMounted } from "@vue/runtime-core";
export default {
  name: "AllCharts",
  setup() {
    const {
      drawAllData,
      circularDraw,
      totalCost,
      doctors,
      arrayOfDoctorsCost,
    } = usePlanetData();
    let planetChartData;
    let PieChartData;
    onMounted(async () => {
      planetChartData = await drawAllData();
      const ctx1 = document.getElementById("planet-chart");
      new Chart(ctx1, planetChartData);
      PieChartData = circularDraw();
      const ctx2 = document.getElementById("pie-chart");
      new Chart(ctx2, PieChartData);
      console.log(totalCost.value);
    });
    return { totalCost, doctors, arrayOfDoctorsCost };
  },
};
</script>
<style scoped>
.font-in-chart {
  font-size: 1.5rem;
}
.border-in-chart {
  background-color: rgba(0, 0, 0, 0.03);
}
</style>
