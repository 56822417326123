<template>
  <GeneralPatientInfo
    :patientInformation="patientInformation"
    :treatmentPlan="treatmentPlan"
    :haveInput="haveInput"
    :disabledMode="disabledMode"
    :edit="edit"
    :newVisit="newVisit"
    :haveSubCases="haveSubCases"
    :id="id"
    :print="print"
    :rxContainer="rxContainer"
    :images="images"
    :sendFromSubCase="sendFromSubCase"
  />
</template>

<script>
import { ref } from "@vue/reactivity";
import GeneralPatientInfo from "../components/GeneralPatientInfo.vue";

export default {
  props: [
    "type",
    "haveInput",
    "name",
    "occupation",
    "phoneNumber",
    "gender",
    "maritalStatus",
    "doctorName",
    "age",
    "address",
    "dateOfVisit",
    "diseases",
    "moreInformation",
    "medicationAllergy",
    "previousOperations",
    "monthOfPregnant",
    "selectedTeeth",
    "historyOfTooth",
    "reactionToPercusionStimulus",
    "vitalityTest",
    "swelling",
    "numberOfCanalsAndLengthObj",
    "notes",
    "cost",
    "dateOfNextVisit",
    "historyOfToothEndodonticOtherInput",
    "numberOfCanalsAndLengthObjJustLengthArray",
    "numberOfCanalsAndLengthObjJustCanalArray",
    "disabledMode",
    "edit",
    "newVisit",
    "id",
    "classOfRestoration",
    "heightOfRestoration",
    "locationOfRestorationToGingiva",
    "materialsForPulpProtectOrBases",
    "materialsForPulpProtectOrBasesType",
    "colorShade",
    "chiefComplaint",
    "chiefComplaintOtherInput",
    "bloodPressure",
    "bloodSugarLevel",
    "hepatitisHIV",
    "eo",
    "io",
    "investigatioms",
    "anteriorPosteriorRelation",
    "verticalRelation",
    "horizontalRelation",
    "facialProfile",
    "anglesClassification",
    "caninesClassification",
    "incisorsClassification",
    "overJet",
    "overBit",
    "crossBit",
    "Diagnosis",
    "Plan",
    "applianceDesign",
    "typeOfProsthetic",
    "typesOfProsthetic",
    "fixedProsthetic",
    "fixedProstheticTypeOfMeterials",
    "removableProsthetic",
    "hollywoodSmileType",
    "hollywoodSmileTypeOtherInput",
    "prostheticObj",
    "hollywoodSmileObj",
    "periodontalTreatmentObj",
    "TeethBleachingObj",
    "periodontalTreatmentOrTeethBleaching",
    "habit",
    "somking",
    "plaque",
    "calculus",
    "diagnosis",
    "nameOfMaterialUsed",
    "typeOfMaterialUsed",
    "colorShadeBeforeBleaching",
    "colorShadeAfterBleaching",
    "teethSensitivity",
    "plan",
    "bloodSugerLevel", // different spell from extraction bloodSugarLevel
    "boneRatio",
    "placementSurgeryD",
    "placementSurgeryN",
    "stage2SurgeryD",
    "stage2SurgeryN",
    "typeOfImplant",
    "oralHabits",
    "oralHabitsOtherInput",
    "treatmentPlan3",
    "treatmentPlan3OtherInput",
    "print",
    "rxOther",
    "selectedAnalgesics",
    "selectedAntibiotic",
    "selectedAntifungalDrugs",
    "selectedSedationDrugs",
    "imagesJustUrl",
    "imageJustFilePath",
    "haveSubCases",
    "sendFromSubCase",
  ],
  setup(props) {
    // patients id is sending with treatmentPlan obj
    let patientInformation = null;
    let treatmentPlan = null;
    let rxContainer = null;
    let images = [];

    // haveInput init by props so when there is no props will be undifined
    if (props.name) {
      patientInformation = ref({
        name: props.name,
        occupation: props.occupation,
        phoneNumber: props.phoneNumber,
        gender: props.gender,
        maritalStatus: props.maritalStatus,
        doctorName: props.doctorName,
        age: props.age,
        address: props.address,
        dateOfVisit: props.dateOfVisit,
        diseases: props.diseases,
        moreInformation: props.moreInformation,
        medicationAllergy: props.medicationAllergy,
        previousOperations: props.previousOperations,
        // dateOfNextVisitEndodontic: props.dateOfNextVisitEndodontic,
        monthOfPregnant: props.monthOfPregnant,
      });
      console.log(typeof patientInformation.value.monthOfPregnant);
      if (
        props.selectedAntibiotic ||
        props.selectedAnalgesics ||
        props.selectedAntifungalDrugs ||
        props.selectedSedationDrugs ||
        props.rxOther
      ) {
        rxContainer = ref({
          rxOther: props.rxOther,
          selectedAnalgesics: props.selectedAnalgesics,
          selectedAntibiotic: props.selectedAntibiotic,
          selectedAntifungalDrugs: props.selectedAntifungalDrugs,
          selectedSedationDrugs: props.selectedSedationDrugs,
        });
      } else {
        rxContainer = ref(null);
      }
      if (props.imagesJustUrl && props.imageJustFilePath) {
        for (let i = 0; i < props.imageJustFilePath.length; i++) {
          images.push({
            url: props.imagesJustUrl[i],
            filePath: props.imageJustFilePath[i],
          });
        }
      }
      if (props.type == "Endodontic Treatment") {
        let numberOfCanalsAndLengthObj = [];
        for (
          let i = 0;
          i < props.numberOfCanalsAndLengthObjJustLengthArray.length;
          i++
        ) {
          numberOfCanalsAndLengthObj.push({
            canalName: props.numberOfCanalsAndLengthObjJustCanalArray[i],
            lengthOfCanal: props.numberOfCanalsAndLengthObjJustLengthArray[i],
          });
        }
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          historyOfTooth: props.historyOfTooth,
          historyOfToothEndodonticOtherInput:
            props.historyOfToothEndodonticOtherInput,
          reactionToPercusionStimulus: props.reactionToPercusionStimulus,
          vitalityTest: props.vitalityTest,
          swelling: props.swelling,
          numberOfCanalsAndLengthObj: numberOfCanalsAndLengthObj,
          notes: props.notes,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      } else if (props.type == "Composite Restoration") {
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          classOfRestoration: props.classOfRestoration,
          heightOfRestoration: props.heightOfRestoration,
          locationOfRestorationToGingiva: props.locationOfRestorationToGingiva,
          materialsForPulpProtectOrBases: props.materialsForPulpProtectOrBases,
          materialsForPulpProtectOrBasesType:
            props.materialsForPulpProtectOrBasesType,
          colorShade: props.colorShade,
          notes: props.notes,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      } else if (props.type == "Extraction") {
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          chiefComplaint: props.chiefComplaint,
          chiefComplaintOtherInput: props.chiefComplaintOtherInput,
          bloodPressure: props.bloodPressure,
          bloodSugarLevel: props.bloodSugarLevel,
          hepatitisHIV: props.hepatitisHIV,
          eo: props.eo,
          io: props.io,
          investigatioms: props.investigatioms,
          notes: props.notes,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      } else if (props.type == "Orthodontic Treatment") {
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          anteriorPosteriorRelation: props.anteriorPosteriorRelation,
          verticalRelation: props.verticalRelation,
          horizontalRelation: props.horizontalRelation,
          facialProfile: props.facialProfile,
          anglesClassification: props.anglesClassification,
          caninesClassification: props.caninesClassification,
          incisorsClassification: props.incisorsClassification,
          overJet: props.overJet,
          overBit: props.overBit,
          crossBit: props.crossBit,
          Diagnosis: props.Diagnosis,
          Plan: props.Plan,
          notes: props.notes,
          applianceDesign: props.applianceDesign,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      } else if (props.type == "Prosthetic And Hollywood Smile") {
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          typeOfProsthetic: props.typeOfProsthetic,
          typesOfProsthetic: props.typesOfProsthetic,
          fixedProsthetic: props.fixedProsthetic,
          fixedProstheticTypeOfMeterials: props.fixedProstheticTypeOfMeterials,
          removableProsthetic: props.removableProsthetic,
          hollywoodSmileType: props.hollywoodSmileType,
          hollywoodSmileTypeOtherInput: props.hollywoodSmileTypeOtherInput,
          colorShade: props.colorShade,
          notes: props.notes,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      } else if (props.type == "Periodontal Treatment And Teeth Bleaching") {
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          periodontalTreatmentOrTeethBleaching:
            props.periodontalTreatmentOrTeethBleaching,
          chiefComplaint: props.chiefComplaint,
          chiefComplaintOtherInput: props.chiefComplaintOtherInput,
          habit: props.habit,
          somking: props.somking,
          plan: props.plan,
          plaque: props.plaque,
          calculus: props.calculus,
          diagnosis: props.diagnosis,
          nameOfMaterialUsed: props.nameOfMaterialUsed,
          typeOfMaterialUsed: props.typeOfMaterialUsed,
          colorShadeBeforeBleaching: props.colorShadeBeforeBleaching,
          colorShadeAfterBleaching: props.colorShadeAfterBleaching,
          teethSensitivity: props.teethSensitivity,
          notes: props.notes,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      } else if (props.type == "Teeth Implant") {
        console.log(props.type);
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          bloodPressure: props.bloodPressure,
          bloodSugerLevel: props.bloodSugerLevel,
          boneRatio: props.boneRatio,
          eo: props.eo,
          io: props.io,
          investigatioms: props.investigatioms,
          placementSurgeryD: props.placementSurgeryD,
          placementSurgeryN: props.placementSurgeryN,
          stage2SurgeryD: props.stage2SurgeryD,
          stage2SurgeryN: props.stage2SurgeryN,
          typeOfImplant: props.typeOfImplant,
          colorShade: props.colorShade,
          notes: props.notes,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      } else if (props.type == "Paedodontic") {
        treatmentPlan = ref({
          selectedTeeth: props.selectedTeeth,
          chiefComplaint: props.chiefComplaint,
          chiefComplaintOtherInput: props.chiefComplaintOtherInput,
          oralHabits: props.oralHabits,
          oralHabitsOtherInput: props.oralHabitsOtherInput,
          treatmentPlan3: props.treatmentPlan3,
          treatmentPlan3OtherInput: props.treatmentPlan3OtherInput,
          eo: props.eo,
          io: props.io,
          investigatioms: props.investigatioms,
          notes: props.notes,
          cost: props.cost,
          dateOfNextVisit: props.dateOfNextVisit,
          type: props.type,
          id: props.id,
        });
      }
    }

    return {
      patientInformation,
      treatmentPlan,
      rxContainer,
      images,
    };
  },
  components: { GeneralPatientInfo },
};
</script>

<style></style>
