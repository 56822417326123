<template>
  <Signup />
  <Login />
  <nav
    class="navbar navbar-expand-lg navbar-default navbar-light"
    :class="{
      'nav-bg-black': navBackground == 'black',
      'nav-bg-white': navBackground == 'white',
      'fixed-top': fixedTop,
    }"
  >
    <router-link class="navbar-brand" :to="{ name: 'Home' }">
      <img src="@/assets/images/logo.webp" />
    </router-link>
    <button
      type="button"
      class="navbar-toggler bg-light"
      data-toggle="collapse"
      data-target="#nav"
    >
      <span class="navbar-toggler-icon text-dark"></span>
    </button>

    <div class="collapse navbar-collapse justify-content-between" id="nav">
      <ul class="navbar-nav" v-if="user">
        <li class="nav-item">
          <router-link
            :to="{ name: 'NewPatient' }"
            class="nav-link text-light text-uppercase font-weight-bold px-3"
          >
            اضافة مريض
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'ShowPatients' }"
            class="nav-link text-light text-uppercase font-weight-bold px-3"
          >
            الأرشيف
          </router-link>
        </li>
        <li class="nav-item">
          <!-- <router-link
            :to="{ name: 'TodayAppointment' }"
            
            class="nav-link text-light text-uppercase font-weight-bold px-3"
          >
            المواعيد
          </router-link> -->

          <a
            href="TodayAppointment"
            class="nav-link text-light text-uppercase font-weight-bold px-3"
          >
            المواعيد
          </a>
        </li>
        <!-- <li class="nav-item dropdown" data-toggle="dropdown">
          <a
            class="nav-link text-light text-uppercase font-weight-bold px-3 dropdown-toggle"
            href="#"
            >تعليمي</a
          >
          <div class="dropdown-menu">
            <a class="dropdown-item" href="#">مقالات لطب الأسنان</a>
            <a class="dropdown-item" href="#">فديوات لطب الأسنان</a>
            <a class="dropdown-item" href="#">تعليم استعمال النظام</a>
          </div>
        </li> -->
        <li class="nav-item">
          <router-link
            :to="{ name: 'Statistics' }"
            class="nav-link text-light text-uppercase font-weight-bold px-3"
          >
            الأحصائيات
          </router-link>
        </li>
        <li class="nav-item">
          <router-link
            :to="{ name: 'Settings' }"
            class="nav-link text-light text-uppercase font-weight-bold px-3"
          >
            الأعدادات
          </router-link>
        </li>
      </ul>
      <div v-else class="w-50"></div>
      <div class="social px-3">
        <div v-if="user">
          <button
            v-if="user && !user.emailVerified"
            class="btn btn-warning text-white mr-1"
            data-toggle="modal"
            data-target="#sendVerifyToEmail"
            type="button"
          >
            details
          </button>
          <button class="btn btn-warning text-white mr-1" @click="logoutFunc">
            Logout
          </button>
        </div>

        <div v-else>
          <button
            class="btn btn-warning text-white mr-1"
            data-toggle="modal"
            data-target="#loginModal"
            type="button"
          >
            Login
          </button>

          <button
            class="btn btn-warning text-white mr-1"
            data-toggle="modal"
            data-target="#signupModal"
            type="button"
          >
            Signup
          </button>
        </div>
      </div>
    </div>
  </nav>
</template>

<script>
import Signup from "../views/auth/Signup.vue";
import getUser from "../composables/getUser";
import useLogout from "../composables/useLogout";
import Login from "../views/auth/Login.vue";
import { useRouter } from "vue-router";
export default {
  components: { Signup, Login },
  props: ["navBackground", "fixedTop"],
  setup() {
    const { user } = getUser();
    const { logout } = useLogout();
    const router = useRouter();

    const logoutFunc = () => {
      router.push({ name: "Home" });
      logout();
    };

    return { user, logoutFunc };
  },
};
</script>

<style>
.navbar-brand img {
  width: 4.0625rem;
  height: 4.0625rem;
}
.container {
  box-shadow: none !important;
}
.nav-bg-white .navbar-nav .nav-link.text-light {
  color: rgb(0, 0, 0) !important;
}
.nav-bg-black .navbar-nav .nav-link:hover {
  color: #fac10a !important;
}
.nav-bg-black .navbar-nav .nav-link:focus {
  color: #fac10a !important;
}
.nav-bg-black.navbar-nav .dropdown-item:hover {
  background: #fac10a;
}

.nav-bg-white .navbar-nav .nav-link:hover {
  color: #fac10a !important;
}
.nav-bg-white .navbar-nav .nav-link:focus {
  color: #fac10a !important;
}
.nav-bg-white.navbar-nav .dropdown-item:hover {
  background: #fac10a;
}
@media (max-width: 1200px) {
  .container {
    width: 100% !important;
    max-width: none !important;
  }
}
.nav-bg-black {
  /* background: black; */
  /* opacity: 0.1;
  z-index: 1; */
  background: rgba(0, 0, 0, 0.5);
}
.nav-bg-white {
  background: white;
}
@media only screen and (max-width: 700px) {
  .fixed-top {
    position: static !important;
    top: 0;
    right: 0;
    left: 0;
    z-index: 1030;
  }
  .nav-bg-black {
    background: rgb(241, 239, 233);
  }
  .navbar-nav .nav-link.text-light {
    color: rgb(0, 0, 0) !important;
  }
}
</style>
