import firebase from "firebase/compat/app";
import "firebase/compat/firestore";
import "firebase/compat/auth";
import "firebase/compat/storage";
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyBLliJAfHV1u4m6TIyQ96de2pbAOP78L3Y",
  authDomain: "your-clinic-5b15c.firebaseapp.com",
  projectId: "your-clinic-5b15c",
  storageBucket: "your-clinic-5b15c.appspot.com",
  messagingSenderId: "560000041176",
  appId: "1:560000041176:web:1fae489a692ef8861bd7ad",
  measurementId: "G-T0YHLK1G89",
};

firebase.initializeApp(firebaseConfig);

// init services
const projectFirestore = firebase.firestore();
const projectAuth = firebase.auth();
const projectStorage = firebase.storage();

// timestamp
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
const fieldValue = firebase.firestore.FieldValue;
export { projectFirestore, projectAuth, projectStorage, timestamp, fieldValue };
