<template>
  <Navbar navBackground="black" :fixedTop="true" />
  <div class="carousel slide" data-ride="carousel">
    <div class="carousel-inner">
      <div class="carousel-item active">
        <img
          class="d-block w-100"
          src="@/assets/images/image-slider-1.webp"
          alt="First slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/images/image-slider-2.webp"
          alt="Second slide"
        />
      </div>
      <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/images/image-slider-3.webp"
          alt="Third slide"
        />
      </div>
      <!-- <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/images/image-4.webp"
          alt="Third slide"
        />
      </div> -->
      <!-- <div class="carousel-item">
        <img
          class="d-block w-100"
          src="@/assets/images/image-1.webp"
          alt="Third slide"
        />
      </div> -->
      <div class="d-none d-md-block">
        <div
          class="carousel-caption d-flex align-items-center justify-content-center"
        >
          <router-link
            class="btn btn-warning text-white p-3 px-5"
            :to="{ name: 'NewPatient' }"
          >
            اضافة مريض
          </router-link>
        </div>
      </div>
    </div>
  </div>
  <section id="printMe">
    <div class="container-fluid bg-offwhite p-5">
      <div class="row justify-content-center mb-3">
        <div class="col-sm-10 text-center">
          <h1 class="display-2 text-capitalize text-warning">My Clinic</h1>
          <p class="lead text-secondary">
            The First Comprehensive Arabic Web Application For Management And
            Orgnization Of Dental Clinics
          </p>
        </div>
      </div>
      <div class="row text-center">
        <div class="col-lg-4 col-sm-10 mx-auto mb-5">
          <i class="fas fa-desktop fa-6x text-warning"></i>
          <h1 class="text-secondary ">Development</h1>
          <p class="text-muted">
            this web application was developed by
          </p>
          <p
            class="capitalize text-warning d-block mb-2 cursor"
            data-toggle="modal"
            data-target="#ali-hamid-info-modal"
          >
            Eng.Ali Hamid Allami
          </p>
          <!-- <a href="#" class="capitalize btn btn-outline-warning">Learn more</a> -->
        </div>
        <div class="col-lg-4 col-sm-10 mx-auto mb-5">
          <i class="far fa-edit fa-6x text-warning"></i>
          <h1 class="text-secondary ">Design</h1>
          <p class="text-muted">
            the program interfaces are designed and medical information
            coordinated by
          </p>
          <p
            class="capitalize text-warning d-block mb-2 cursor"
            data-toggle="modal"
            data-target="#yehiya-info-modal"
          >
            Dr.Yehiya Amer Al Joubory
          </p>
          <p
            class="capitalize text-warning d-block mb-2 cursor"
            data-toggle="modal"
            data-target="#mustafa-info-modal"
          >
            Dr.Mustafa Ameer Alsultany
          </p>

          <!-- <a href="#" class="capitalize btn btn-outline-warning">learn more</a> -->
        </div>
        <div class="col-lg-4 col-sm-10 mx-auto mb-5">
          <i class="fas fa-cogs fa-6x text-warning"></i>
          <h1 class="text-secondary ">Creativity</h1>
          <p class="text-muted">
            this web app is created by
          </p>
          <p
            class="capitalize text-warning d-block mb-2 cursor"
            data-toggle="modal"
            data-target="#odevs-info-modal"
          >
            odevs
          </p>

          <!-- <a href="#" class="capitalize btn btn-outline-warning">learn more</a> -->
        </div>
      </div>
    </div>
  </section>
  <!-- modal of ali hamid  -->
  <div class="modal fade" id="ali-hamid-info-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="background-color:#f8f0e3;">
        <div class="modal-body">
          <a href="https://www.facebook.com/ali.hamed.12720"
            ><i
              class="fab fa-facebook fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary">
              Ali Hamed
            </p>
          </a>
          <br />
          <a href="https://www.instagram.com/eng_alihamid/?utm_medium=copy_link"
            ><i
              class="fab fa-instagram fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary up-5">
              eng_alihamid
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of yehiya  -->
  <div class="modal fade" id="yehiya-info-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="background-color:#f8f0e3;">
        <div class="modal-body">
          <a href="https://www.facebook.com/yehiyaamer.aljoubory"
            ><i
              class="fab fa-facebook fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary">
              Yehiya Amer Al Joubory
            </p>
          </a>
          <br />
          <a
            href="https://www.instagram.com/dr_yehiya_hummadi/?utm_medium=copy_link"
            ><i
              class="fab fa-instagram fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary up-5">
              dr_yehiya_hummadi
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of mustafa  -->
  <div class="modal fade" id="mustafa-info-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="background-color:#f8f0e3;">
        <div class="modal-body">
          <a href="https://www.facebook.com/mustafa.amer.3158"
            ><i
              class="fab fa-facebook fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary">
              Mustafa Ameer
            </p>
          </a>
          <br />
          <a
            href="https://www.instagram.com/mustafa_amer9943/?utm_medium=copy_link"
            ><i
              class="fab fa-instagram fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary up-5">
              mustafa_amer9943
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of odevs  -->
  <div class="modal fade" id="odevs-info-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="background-color:#f8f0e3;">
        <div class="modal-body">
          <a href="https://www.instagram.com/ODevs25/"
            ><i
              class="fab fa-facebook fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary">
              ODevs25
            </p>
          </a>
          <br />
          <a href="https://www.instagram.com/ODevs25/"
            ><i
              class="fab fa-instagram fa-4x cursor"
              style="color: #ffc107;"
            ></i>
            <p class="d-inline ml-2 social-media-p-size text-secondary up-5">
              ODevs25
            </p>
          </a>
        </div>
      </div>
    </div>
  </div>
  <!-- free modal -->
  <!-- <div class="modal fade" id="freeModal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content" style="background-color:#f8f0e3;">
        <div class="modal-body">
          <p>free for {{ remainderdays }} days</p>
        </div>
      </div>
    </div>
  </div> -->
  <div class="modal fade" id="freeModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Free trial</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p class="text-warning mb-0">free for {{ remainderdays }} days</p>
          <p class="text-warning mt-0">
            If you do not purchase the activation code, we will delete your
            account
          </p>
          <div class="form-group">
            <label class="text-uppercase">payment code</label>
            <div class="input-group mb-3">
              <input type="text" class="form-control" v-model="paymentCode" />
            </div>
            <div class="text-danger">{{ error }}</div>
          </div>
          <div class="d-flex justify-content-center">
            <div class="copy-text-general">
              يمكنكم شراء كود التفعيل من خلال صفحتنا على الفيسبوك عدد الأطباء
              المسموح به للعيادات هو 3 اطباء فقط , للزيادة يمكنكم شراء كود تفعيل
              نسخة المراكز الطبية
              <a href="http://grafreez.com">عيادتي</a>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-warning" @click="activateAcount">
            Ok
          </button>
        </div>
      </div>
    </div>
  </div>

  <div
    class="modal fade"
    id="sendVerifyToEmail"
    tabindex="-1"
    role="dialog"
    aria-labelledby="sendVerifyToEmail"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-lg modal-dialog-centered" role="document">
      <div class="modal-content bg-offwhite">
        <div class="modal-header">
          <h5 class="modal-title" id="sendVerifyToEmailLabel">finsih Login</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="row">
            <div class="col-md-12 login-sec">
              <p class="my-1">
                verifie message is sent please check your email and then click
                Log in
              </p>
              <p class="my-1">
                if there is no message please signup again with real email
              </p>

              <hr />
              <div class="d-flex justify-content-between">
                <button
                  class="btn btn-warning mt-4 mb-0"
                  @click="sendMessageAgin"
                >
                  send verifie message agin
                </button>
                <button
                  class="btn btn-warning mt-4 mb-0"
                  @click="redirectToLogin"
                >
                  Login
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { onMounted, ref } from "@vue/runtime-core";
import getUser from "../composables/getUser";
import { projectFirestore } from "@/firebase/config";
import { projectAuth } from "@/firebase/config";
import useStorage from "@/composables/useStorage";
import { timestamp } from "@/firebase/config";
import useDocument from "@/composables/useDocument";
import Navbar from "../components/Navbar.vue";
export default {
  components: { Navbar },
  name: "Home",
  setup() {
    const { user } = getUser();
    const { deleteImage } = useStorage();
    const { updateDoc, deleteDoc } = useDocument("users");
    const remainderdays = ref(0);
    const paymentCode = ref("");
    const codes = ref("");
    const code = ref([]);
    const error = ref("");
    const numberOfDoctors = ref(3);

    onMounted(async () => {
      $(".carousel").carousel({
        interval: 4000,
        pause: false,
      });
      if (!user) $("#loginModal").modal();

      if (user.value && user.value.emailVerified) {
        let userCodeObj;
        let userCodeId;
        let userCodeObjRef = await projectFirestore
          .collection("users")
          .where(...["userId", "==", user.value.uid])
          .get();

        userCodeObjRef.forEach((doc) => {
          userCodeObj = doc.data();
          userCodeId = doc.id;
        });

        if (!userCodeObj.getPaymentCode && userCodeObj.free == false) {
          let pCodeId;
          let PcodeRef = await projectFirestore
            .collection("paymentCodes")
            .where(...["code", "==", userCodeObj.paymentCode])
            .get();
          PcodeRef.forEach((doc) => {
            pCodeId = doc.id;
          });
          const { deleteDoc } = useDocument("paymentCodes");
          await deleteDoc(pCodeId);
          await updateDoc(
            {
              getPaymentCode: true,
            },
            userCodeId
          );
        }
        let createdAt = userCodeObj.createdAt.seconds * 1000;
        let n = new Date();
        let now = new Date().setDate(n.getDate());
        let endTime = userCodeObj.endTime;
        let minusOp = endTime - now;
        remainderdays.value = Math.ceil(minusOp / 86400000);
        // if (userCodeObj.free) {
        if (remainderdays.value > 0 && remainderdays.value < 60) {
          $("#freeModal").modal("show");
        } else if (remainderdays.value == 0) {
          let patientsRef = projectFirestore
            .collection("patients")
            .where("userId", "==", user.value.uid);
          const patients = await patientsRef.get();
          patients.forEach(async (p) => {
            let images = p.data().images;
            for (let i = 0; i < images.length; i++) {
              await deleteImage(images[i].filePath);
            }
            p.ref.delete();
          });
          // await deleteDoc(userCodeId);
          // await projectAuth.currentUser.delete();
        }

        // let endTime = new Date().setDate(now.getDate() + 365);
        // console.log(endTime);
        // console.log(time);
        // console.log(new Date(time * 1000));
        // console.log(new Date(endTime));
        // }
      }
    });
    const sendMessageAgin = async () => {
      try {
        console.log("sending email", user.value);
        await user.value.sendEmailVerification();
      } catch (e) {
        console.log(e);
      }
    };
    const checkCode = async () => {
      codes.value = await projectFirestore
        .collection("paymentCodes")
        .where(...["code", "==", paymentCode.value])
        .get();
      codes.value.forEach((doc) => {
        code.value.push(doc.data());
      });
      // if there is a code take the number of doctors
      if (code.value.length)
        numberOfDoctors.value = code.value[0].numberOfDoctors;
    };
    const activateAcount = async () => {
      await checkCode();
      if (!code.value.length) {
        error.value = "the payment code is incorrect";
      } else {
        let userCodeObj;
        let userCodeId;
        let userCodeObjRef = await projectFirestore
          .collection("users")
          .where(...["userId", "==", user.value.uid])
          .get();

        userCodeObjRef.forEach((doc) => {
          userCodeObj = doc.data();
          userCodeId = doc.id;
        });
        // console.log(userCodeId, user.value.uid);
        let now1 = new Date();
        let endTime1 = new Date().setDate(now1.getDate() + 365);
        await updateDoc(
          {
            paymentCode: paymentCode.value,
            getPaymentCode: true,
            free: false,
            numberOfDoctors: numberOfDoctors.value,
            createdAt: timestamp(),
            endTime: endTime1,
          },
          userCodeId
        );
        // delete paymentcode from database
        let pCodeId;
        let PcodeRef = await projectFirestore
          .collection("paymentCodes")
          .where(...["code", "==", userCodeObj.paymentCode])
          .get();
        PcodeRef.forEach((doc) => {
          pCodeId = doc.id;
        });
        const { deleteDoc } = useDocument("paymentCodes");
        await deleteDoc(pCodeId);
      }
    };
    const redirectToLogin = () => {
      $(".modal").modal("hide");
      logout();
      $("#loginModal").modal("show");
    };
    return {
      remainderdays,
      paymentCode,
      activateAcount,
      redirectToLogin,
      error,
      sendMessageAgin,
    };
  },
};
</script>
<style>
body {
  padding: 0 !important;
}
.up-5 {
  position: absolute;
  bottom: 0.5rem !important;
}
/* .carousel-caption h2 {
  background: #fac10a;
  border-radius: 3px;
  opacity: 0.9;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
} */
.social-media-p-size {
  font-size: 1.8rem;
  font-family: "Merriweather";
}
a {
  text-decoration: none !important;
}
.cursor {
  cursor: pointer;
}
@media (min-width: 1200px) {
  .carousel-item img {
    object-fit: cover;
    width: 100%;
    height: 720px;
  }
}
</style>
