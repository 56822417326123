<template>
  <Navbar navBackground="white" :fixedTop="false" />

  <AllCharts />
</template>

<script>
import AllCharts from "../components/charts/AllCharts.vue";
import Navbar from "../components/Navbar.vue";
export default {
  components: { Navbar, AllCharts },
};
</script>

<style></style>
