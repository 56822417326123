<template>
  <Navbar navBackground="white" :fixedTop="false" />
  <div class="container">
    <table class="table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Date Of Visit</th>
          <th>Information</th>
          <th>Edit</th>
          <th>Print</th>
          <th>Delete</th>
        </tr>
      </thead>
      <tbody v-if="patients || mainCaseObj">
        <tr>
          <td>{{ mainCaseObj.patientInformation.name }}</td>
          <td>{{ mainCaseObj.patientInformation.dateOfVisit }}</td>
          <td>
            <router-link
              class="btn"
              :class="mainCaseObj.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...mainCaseObj.patientInformation,
                  ...mainCaseObj.rx,
                  id: mainCaseObj.id,
                  haveInput: true,
                  disabledMode: '1',
                  edit: false,
                  newVisit: false,
                  ...mainCaseObj.treatmentPlan,
                  type: mainCaseObj.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    mainCaseObj.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    mainCaseObj.numberOfCanalsAndLengthObjJustCanalArray,
                  ...mainCaseObj.prostheticObj,
                  ...mainCaseObj.hollywoodSmileObj,
                  ...mainCaseObj.periodontalTreatmentObj,
                  ...mainCaseObj.TeethBleachingObj,
                  print: false,
                  imagesJustUrl: mainCaseObj.imagesJustUrl,
                  imageJustFilePath: mainCaseObj.imageJustFilePath,
                  sendFromSubCase: true,
                },
              }"
              >show Information</router-link
            >
          </td>
          <td>
            <router-link
              class="btn disabled"
              :class="mainCaseObj.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...mainCaseObj.patientInformation,
                  ...mainCaseObj.rx,
                  id: mainCaseObj.id,
                  haveInput: false,
                  disabledMode: '1',
                  edit: true,
                  newVisit: false,
                  ...mainCaseObj.treatmentPlan,
                  type: mainCaseObj.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    mainCaseObj.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    mainCaseObj.numberOfCanalsAndLengthObjJustCanalArray,
                  ...mainCaseObj.prostheticObj,
                  ...mainCaseObj.hollywoodSmileObj,
                  ...mainCaseObj.periodontalTreatmentObj,
                  ...mainCaseObj.TeethBleachingObj,
                  print: false,
                  imagesJustUrl: mainCaseObj.imagesJustUrl,
                  imageJustFilePath: mainCaseObj.imageJustFilePath,
                  sendFromSubCase: true,
                },
              }"
            >
              Edit</router-link
            >
          </td>
          <td>
            <router-link
              class="btn"
              :class="mainCaseObj.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...mainCaseObj.patientInformation,
                  ...mainCaseObj.rx,
                  id: mainCaseObj.id,
                  haveInput: true,
                  disabledMode: '1',
                  edit: false,
                  newVisit: false,
                  ...mainCaseObj.treatmentPlan,
                  type: mainCaseObj.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    mainCaseObj.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    mainCaseObj.numberOfCanalsAndLengthObjJustCanalArray,
                  ...mainCaseObj.prostheticObj,
                  ...mainCaseObj.hollywoodSmileObj,
                  ...mainCaseObj.periodontalTreatmentObj,
                  ...mainCaseObj.TeethBleachingObj,
                  print: true,
                  imagesJustUrl: mainCaseObj.imagesJustUrl,
                  imageJustFilePath: mainCaseObj.imageJustFilePath,
                  sendFromSubCase: true,
                },
              }"
            >
              Print</router-link
            >
          </td>
          <td>
            <button class="btn disabled" :class="mainCaseObj.btnType">
              Delete
            </button>
          </td>
        </tr>
        <tr v-for="p in patients" :key="p.id">
          <td>{{ p.patientInformation.name }}</td>
          <td>{{ p.patientInformation.dateOfVisit }}</td>
          <td>
            <router-link
              class="btn"
              :class="p.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...p.patientInformation,
                  ...p.rx,
                  id: p.id,
                  haveInput: true,
                  disabledMode: '1',
                  edit: false,
                  newVisit: false,
                  ...p.treatmentPlan,
                  type: p.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    p.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    p.numberOfCanalsAndLengthObjJustCanalArray,
                  ...p.prostheticObj,
                  ...p.hollywoodSmileObj,
                  ...p.periodontalTreatmentObj,
                  ...p.TeethBleachingObj,
                  print: false,
                  imagesJustUrl: p.imagesJustUrl,
                  imageJustFilePath: p.imageJustFilePath,
                  sendFromSubCase: true,
                },
              }"
            >
              show Information</router-link
            >
          </td>
          <td>
            <router-link
              class="btn"
              :class="p.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...p.patientInformation,
                  ...p.rx,
                  id: p.id,
                  haveInput: false,
                  disabledMode: '1',
                  edit: true,
                  newVisit: false,
                  ...p.treatmentPlan,
                  type: p.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    p.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    p.numberOfCanalsAndLengthObjJustCanalArray,
                  ...p.prostheticObj,
                  ...p.hollywoodSmileObj,
                  ...p.periodontalTreatmentObj,
                  ...p.TeethBleachingObj,
                  print: false,
                  imagesJustUrl: p.imagesJustUrl,
                  imageJustFilePath: p.imageJustFilePath,
                  sendFromSubCase: true,
                },
              }"
            >
              Edit</router-link
            >
          </td>

          <td>
            <router-link
              class="btn"
              :class="p.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...p.patientInformation,
                  ...p.rx,
                  id: p.id,
                  haveInput: true,
                  disabledMode: '1',
                  edit: false,
                  newVisit: false,
                  ...p.treatmentPlan,
                  type: p.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    p.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    p.numberOfCanalsAndLengthObjJustCanalArray,
                  ...p.prostheticObj,
                  ...p.hollywoodSmileObj,
                  ...p.periodontalTreatmentObj,
                  ...p.TeethBleachingObj,
                  print: true,
                  imagesJustUrl: p.imagesJustUrl,
                  imageJustFilePath: p.imageJustFilePath,
                  sendFromSubCase: true,
                },
              }"
            >
              Print</router-link
            >
          </td>

          <!-- <td><button class="btn" :class="p.btnType">New Visit</button></td> -->
          <td>
            <button
              class="btn"
              :class="p.btnType"
              @click="getIdForDelete(p.id)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
  <!-- modal of delete  -->

  <div class="modal fade" id="delete" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            are you sure you want to delete this patient informations ?
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-flex justify-content-center">
          <button
            class="btn btn-danger m-2 px-3"
            data-dismiss="modal"
            style="font-family:'Merriweather'"
            @click="deletePatient(idForDelete)"
          >
            Yes
          </button>
          <button
            class="btn btn-success m-2 px-3"
            data-dismiss="modal"
            style="font-family:'Merriweather'"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import Navbar from "@/components/Navbar.vue";
import { computed, onMounted, ref } from "@vue/runtime-core";
import useDocument from "@/composables/useDocument";
import { projectFirestore } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { fieldValue } from "@/firebase/config";
import { useStore } from "vuex";
export default {
  props: ["mainCaseId", "haveSubCases"],
  components: { Navbar },
  setup(props) {
    const { user } = getUser();
    const { updateDoc } = useDocument("patients");
    const { deleteDoc } = useDocument("visits");
    const store = useStore();
    const patients = ref([]);
    // const domFunc = async () => {
    //   let ref = projectFirestore.collection("patients");

    //   const data = await ref.get();
    //   data.forEach((doc) => {
    //     console.log(doc.data());
    //     console.log(doc.id);
    //     updateDoc({ haveSubCases: 0 }, doc.id);
    //   });
    // };
    const mainCaseObj = computed(() => store.state.newVisitsObj);
    onMounted(async () => {
      let ref = projectFirestore
        .collection("visits")
        .where("userId", "==", user.value.uid)
        .where("mainCaseId", "==", props.mainCaseId)
        .orderBy("createdAt", "desc");
      const data = await ref.get();
      data.forEach((doc) => {
        let numberOfCanalsAndLengthObjJustLengthArray = [];
        let numberOfCanalsAndLengthObjJustCanalArray = [];

        let imagesJustUrl = [];
        let imageJustFilePath = [];

        let prostheticObj = false;
        let hollywoodSmileObj = false;

        let periodontalTreatmentObj = false;
        let TeethBleachingObj = false;

        let btnType;

        if (doc.data().images) {
          doc.data().images.forEach((img) => {
            imagesJustUrl.push(img.url);
            imageJustFilePath.push(img.filePath);
          });
        }
        if (doc.data().type == "Endodontic Treatment") {
          btnType = "btn-primary";

          doc
            .data()
            .treatmentPlan.numberOfCanalsAndLengthObj.forEach(
              (canalAndLenth) => {
                numberOfCanalsAndLengthObjJustLengthArray.push(
                  canalAndLenth.lengthOfCanal
                );
                numberOfCanalsAndLengthObjJustCanalArray.push(
                  canalAndLenth.canalName
                );
              }
            );
        } else if (doc.data().type == "Composite Restoration") {
          btnType = "btn-success";
        } else if (doc.data().type == "Extraction") {
          btnType = "btn-danger";
        } else if (doc.data().type == "Orthodontic Treatment") {
          btnType = "btn-dark";
        } else if (doc.data().type == "Prosthetic And Hollywood Smile") {
          btnType = "btn-secondary";
          if (doc.data().treatmentPlan.prostheticObj) {
            prostheticObj = doc.data().treatmentPlan.prostheticObj;
          } else if (doc.data().treatmentPlan.hollywoodSmileObj) {
            hollywoodSmileObj = doc.data().treatmentPlan.hollywoodSmileObj;
          }
        } else if (
          doc.data().type == "Periodontal Treatment And Teeth Bleaching"
        ) {
          btnType = "btn-warning";
          if (doc.data().treatmentPlan.periodontalTreatmentObj) {
            periodontalTreatmentObj = doc.data().treatmentPlan
              .periodontalTreatmentObj;
          } else if (doc.data().treatmentPlan.TeethBleachingObj) {
            console.log(doc.data().treatmentPlan.TeethBleachingObj);
            TeethBleachingObj = doc.data().treatmentPlan.TeethBleachingObj;
          }
        } else if (doc.data().type == "Teeth Implant") {
          btnType = "btn-info";
        } else if (doc.data().type == "Paedodontic") {
          btnType = "btn-orange";
        }

        patients.value.push({
          ...doc.data(),
          id: doc.id,
          btnType: btnType,
          numberOfCanalsAndLengthObjJustLengthArray: numberOfCanalsAndLengthObjJustLengthArray,
          numberOfCanalsAndLengthObjJustCanalArray: numberOfCanalsAndLengthObjJustCanalArray,
          prostheticObj,
          hollywoodSmileObj,
          periodontalTreatmentObj,
          TeethBleachingObj,
          imagesJustUrl: imagesJustUrl,
          imageJustFilePath: imageJustFilePath,
        });
      });
      // domFunc();
    });
    const idForDelete = ref(null);
    const getIdForDelete = (id) => {
      idForDelete.value = id;
      $("#delete").modal("show");
    };
    const deletePatient = async (id) => {
      // get the patients that we want to delete it
      let patientToDelete = patients.value.filter(
        (patient) => patient.id == id
      );
      // delete patient from dome
      patients.value = patients.value.filter((patient) => patient.id != id);
      // delete images of this patients
      if (patientToDelete[0].images) {
        for (let i = 0; i < patientToDelete[0].images.length; i++) {
          console.log(patientToDelete[0].images[i].filePath);
          await delImage(patientToDelete[0].images[i].filePath);
        }
      }

      //  delete from firebase
      await deleteDoc(id);

      // decrase the number of haveSubCases
      let cases = props.haveSubCases - 1;
      await updateDoc({ haveSubCases: cases }, props.mainCaseId);
    };
    return {
      patients,
      getIdForDelete,
      deletePatient,
      idForDelete,
      mainCaseObj,
    };
  },
};
</script>

<style></style>
