<template>
  <!-- The Modal -->
  <div class="modal fade" id="loginModal">
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <section>
            <div class="container">
              <div class="row">
                <div class="col-12 login-sec bg-offwhite">
                  <h2 class="text-center">Login Now</h2>
                  <form class="login-form" @submit.prevent="loginFunc">
                    <div class="row">
                      <div class="col-12">
                        <div class="form-group">
                          <label for="exampleInputEmail1" class="text-uppercase"
                            >Email</label
                          >
                          <input
                            type="text"
                            class="form-control"
                            placeholder=""
                            v-model="email"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-group">
                          <label
                            for="exampleInputPassword1"
                            class="text-uppercase"
                            >Password</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            placeholder=""
                            v-model="password"
                          />
                        </div>
                      </div>
                      <div class="col-12">
                        <div class="form-check">
                          <!-- <label class="form-check-label"> -->
                          <small
                            class="text-warning pointer"
                            @click="changePassword"
                            >Change Password</small
                          >
                          <!-- </label> -->
                          <button
                            type="submit"
                            class="btn btn-login float-right"
                          >
                            Login
                          </button>
                        </div>
                        <div class="error" v-if="error">
                          {{ error }}
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </div>
  <ChangePawwsord />
</template>

<script>
import $ from "jquery";
import useLogin from "@/composables/useLogin";
import getUser from "@/composables/getUser";
import ChangePawwsord from "@/components/ChangePawwsord.vue";
import { onMounted, ref } from "@vue/runtime-core";
import { projectFirestore } from "@/firebase/config";
import { projectAuth } from "@/firebase/config";
export default {
  components: { ChangePawwsord },
  setup() {
    const email = ref("");
    const password = ref("");
    const { user } = getUser();
    const { login, error } = useLogin();
    const loginFunc = async () => {
      await login(email.value, password.value);
      checkingEmailVerified();
      if (!error.value) $("#loginModal").modal("hide");
    };
    const changePassword = async () => {
      $("#changePasswordModal").modal("show");
    };
    const checkingEmailVerified = () => {
      projectAuth.currentUser.reload().then(async () => {
        if (projectAuth.currentUser.emailVerified) {
          let userRef = await projectFirestore
            .collection("users")
            .where(...["userId", "==", user.value.uid])
            .get();
          let user;
          let userId;
          userRef.forEach((doc) => {
            user = doc.data();
            userId = doc.id;
          });
          if (!user.getPaymentCode && !user.free) {
            let pCodeId;
            let paymentCodesRef = await projectFirestore
              .collection("paymentCodes")
              .where(...["code", "==", user.paymentCode])
              .get();
            paymentCodesRef.forEach((doc) => {
              pCodeId = doc.id;
            });
            const { deleteDoc } = useDocument("paymentCodes");
            await deleteDoc(pCodeId);
            await updateDoc(
              {
                getPaymentCode: true,
              },
              userId
            );
          }
        } else {
          $("#sendVerifyToEmail").modal("show");
        }
      });
    };
    onMounted(() => {
      // if (!user.value) {
      //   $("#loginModal").modal({
      //     backdrop: "static",
      //     keyboard: false,
      //   });
      // }
    });
    return { email, password, loginFunc, error, changePassword };
  },
};
</script>

<style scoped>
.login-block {
  background: #fac10a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #ffb88c,
    #fac10a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffb88c,
    #fac10a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
}
/* .banner-sec{background:url(https://static.pexels.com/photos/33972/pexels-photo.jpg)  no-repeat left bottom; background-size:cover; min-height:500px; border-radius: 0 10px 10px 0; padding:0;} */
.container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}
.carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px;
  position: relative;
}
.login-sec .copy-text {
  position: absolute;
  width: 80%;
  bottom: 20px;
  font-size: 13px;
  text-align: center;
}
.login-sec .copy-text i {
  color: #feb58a;
}
.login-sec .copy-text a {
  color: #fac10a;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #fac10a;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fac10a;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #fac10a;
  color: #fff;
  font-weight: 600;
}
/* .banner-text{width:70%; position:absolute; bottom:40px; padding-left:20px;}
.banner-text h2{color:#fff; font-weight:600;}
.banner-text h2:after{content:" "; width:100px; height:5px; background:#FFF; display:block; margin-top:20px; border-radius:3px;}
.banner-text p{color:#fff;} */
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fdf7ee !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}
.img-fluid {
  width: 100%;
  height: auto !important;
}

@media (min-width: 700px) and (max-width: 1400px) {
  .img-fluid {
    width: 120%;
    height: 400px !important;
  }
}
/* @media (min-width: 1000px) and (max-width: 4000px) {
  .img-fluid {
    display: block;
  }
}
@media (min-width: 100px) and (max-width: 800px) {
  .img-fluid {
    display: none;
  }
} */

.modal-content {
  background-color: rgb(248, 240, 227);
}
.bg-offwhite {
  background-color: rgb(248, 240, 227);
}
</style>
