<template>
  <!-- Periodontal Treatment And Teeth Bleaching modal-->
  <div class="modal fade" id="periodontal-treatment-and-teeth-bleaching">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Periodontal Treatment And Teeth Bleaching</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <!-- Modal body -->
        <div
          class="modal-body"
          :class="{ disableform: haveInput == 'true' }"
          id="PeriodontalTreatmentAndTeethBleachingPrintModal"
        >
          <div class="card">
            <div class="row">
              <div class="d-none col-xl-6 d-xl-block">
                <img
                  src="@/assets/images/periodontal.webp"
                  class="rounded"
                  style="width:100%; height:96%;"
                  id="periodontal-img"
                />
              </div>
              <div class="col-12 col-xl-6">
                <div class="card-body ml-0 pl-0">
                  <div class="card-title ">
                    <h2>Tooth Number</h2>
                  </div>
                  <div class="card-text">
                    <div
                      class="btn-group btn-group-lg-teeth btn-group-toggle special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment "
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment "
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment "
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div
                      class="btn-group btn-group-toggle btn-group-lg-teeth special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-periodontal-treatment"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div class="row mt-3">
                      <div
                        class="col-12"
                        v-if="!images.length || edit == 'true'"
                      >
                        <input
                          type="file"
                          id="imgupload1"
                          style="display:none"
                          ref="inputFile"
                          @change="imageUpload"
                        />
                        <button
                          type="button"
                          class="btn btn-rx btn-lg btn-block py-0 h4 upload-image"
                          id="OpenImgUpload1"
                          @click="triggerFunc"
                        >
                          Image Upload
                        </button>
                        <div
                          class="div-of-files d-flex justify-content-center"
                          v-if="arrayOfFiles.length"
                        >
                          <div
                            class="paragraph-file"
                            v-for="file in arrayOfFiles"
                            :key="file"
                            @click="handleClickFile(file)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-12" style="pointer-events: auto;" v-else>
                        <div class="div-of-files d-flex justify-content-center">
                          <div
                            class="paragraph-file"
                            v-for="image in imagesArray"
                            :key="image.url"
                            @click="handleClickFile2(image)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="pretty p-default p-round p-thick h1">
                            <input
                              type="radio"
                              name="periodontal-treatment-and-teeth-bleaching-select"
                              value="Periodontal Treatment"
                              v-model="periodontalTreatmentOrTeethBleaching"
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h1"
                                >Periodontal Treatment</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <p class="h3 mb-3">Chief Complaint</p>
                          <div class="row">
                            <div class="col-7">
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Bleeding"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Bleeding</label
                                  >
                                </div>
                              </div>
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Pain"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Pain</label
                                  >
                                </div>
                              </div>
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Halitosis"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Halitosis</label
                                  >
                                </div>
                              </div>
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Drymouth"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Drymouth</label
                                  >
                                </div>
                              </div>
                              <div class="h4 mb-0 pb-0 form-inline ">
                                <div class="mb-0 pb-0">
                                  <div
                                    class="pretty p-default p-round p-thick h4 mr-2"
                                  >
                                    <input
                                      type="checkbox"
                                      :value="chiefComplaintOtherInput"
                                      :disabled="
                                        periodontalTreatmentOrTeethBleaching ==
                                          'Teeth Bleaching'
                                      "
                                      v-model="chiefComplaintOtherCheck"
                                    />
                                    <div class="state p-warning-o">
                                      <label
                                        class="pretty p-default p-curve p-fill h4"
                                      ></label>
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    class="form-control eng-placeholder d-inline mb-1"
                                    placeholder="others......"
                                    id="periodontal-treatment-chief-complaint-other-input"
                                    style="width: 50%;"
                                    :disabled="
                                      periodontalTreatmentOrTeethBleaching ==
                                        'Teeth Bleaching'
                                    "
                                    v-model="chiefComplaintOtherInput"
                                  />
                                </div>
                              </div>
                            </div>
                            <div class="col-5">
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Checking"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Checking</label
                                  >
                                </div>
                              </div>
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Esthetic"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Esthetic</label
                                  >
                                </div>
                              </div>
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="Mobility"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Mobility</label
                                  >
                                </div>
                              </div>
                              <div
                                class="pretty p-default p-round p-thick h4 d-block"
                              >
                                <input
                                  type="checkbox"
                                  value="sensitivity"
                                  v-model="chiefComplaint"
                                  :disabled="
                                    periodontalTreatmentOrTeethBleaching ==
                                      'Teeth Bleaching'
                                  "
                                />
                                <div class="state p-warning-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >sensitivity</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <p class="h3">Habit</p>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="habit"
                              value="Bruxism"
                              v-model="habit"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >Bruxism</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="habit"
                              value="Clenching"
                              v-model="habit"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >Clenching</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <p class="h3">Somking</p>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="smoking"
                              v-model="somking"
                              value="Yes"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >Yes</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="smoking"
                              v-model="somking"
                              value="No"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >No</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <p class="h3">Plaque</p>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="plaque"
                              value="0"
                              v-model="plaque"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >0</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="plaque"
                              value="1"
                              v-model="plaque"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >1</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="plaque"
                              value="2"
                              v-model="plaque"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >2</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="plaque"
                              value="3"
                              v-model="plaque"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >3</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <p class="h3">Calculus</p>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="calculus"
                              value="0"
                              v-model="calculus"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >0</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="calculus"
                              value="1"
                              v-model="calculus"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >1</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="calculus"
                              value="2"
                              v-model="calculus"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >2</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="calculus"
                              value="3"
                              v-model="calculus"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Teeth Bleaching'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >3</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <div class="form-group">
                            <textarea
                              class="form-control eng-placeholder-lg"
                              rows="2"
                              placeholder="Diagnosis"
                              v-model="diagnosis"
                              id="periodontal-diagnosis"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-1">
                        <div class="col">
                          <div class="form-group">
                            <textarea
                              class="form-control eng-placeholder-lg"
                              rows="2"
                              placeholder="Treatment Plan"
                              v-model="plan"
                              id="periodontal-treatment-plan"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card">
            <div class="row">
              <div class="d-none col-xl-6 d-xl-block">
                <img
                  src="@/assets/images/teeth-whiteneess.webp"
                  class="rounded"
                  style="width:100%; height:96%;"
                />
              </div>
              <div class="col-12 col-xl-6">
                <div class="card-body ml-0 pl-0">
                  <div class="card-text">
                    <div>
                      <div class="row">
                        <div class="col-12">
                          <div class="pretty p-default p-round p-thick h1">
                            <input
                              type="radio"
                              name="periodontal-treatment-and-teeth-bleaching-select"
                              value="Teeth Bleaching"
                              v-model="periodontalTreatmentOrTeethBleaching"
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h1"
                                >Teeth Bleaching</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <div class="form-inline">
                            <label class="h3 mr-2">Name Of Material Used</label>
                            <input
                              type="text"
                              class="form-control bg-light"
                              id="teeth-bleaching-name-of-material-used"
                              v-model="nameOfMaterialUsed"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Periodontal Treatment'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="bleaching-material"
                              v-model="typeOfMaterialUsed"
                              value="Chemical"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Periodontal Treatment'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >Chemical</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="bleaching-material"
                              v-model="typeOfMaterialUsed"
                              value="Mechincal"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Periodontal Treatment'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >Mechincal</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <div class="form-inline">
                            <label class="h3 mr-2"
                              >Color Shade Before Bleaching</label
                            >
                            <input
                              type="text"
                              class="form-control bg-light"
                              id="teeth-bleaching-color-shade-before-bleaching"
                              v-model="colorShadeBeforeBleaching"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Periodontal Treatment'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-2">
                        <div class="col-12">
                          <div class="form-inline">
                            <label class="h3 mr-2"
                              >Color Shade After Bleaching</label
                            >
                            <input
                              type="text"
                              class="form-control bg-light"
                              id="teeth-bleaching-color-shade-after-bleaching"
                              v-model="colorShadeAfterBleaching"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Periodontal Treatment'
                              "
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <p class="h3">Teeth Sensitivity</p>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="teeth-sesitivity"
                              v-model="teethSensitivity"
                              value="Yes"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Periodontal Treatment'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >Yes</label
                              >
                            </div>
                          </div>
                          <div class="pretty p-default p-round p-thick h4">
                            <input
                              type="radio"
                              name="teeth-sesitivity"
                              v-model="teethSensitivity"
                              value="No"
                              :disabled="
                                periodontalTreatmentOrTeethBleaching ==
                                  'Periodontal Treatment'
                              "
                            />
                            <div class="state p-warning-o">
                              <label class="pretty p-default p-curve p-fill h4"
                                >No</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col">
                          <div class="form-group">
                            <textarea
                              class="form-control eng-placeholder-lg"
                              rows="3"
                              placeholder="Your Notes"
                              id="periodontal-treatment-and-teeth-bleaching-notes"
                              v-model="notes"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <label for="name" class="h4"
                            >Date Of Next Visit</label
                          >
                          <input
                            type="text"
                            class="form-control  bg-light datetimepicker"
                            id="datetimepicker7"
                          />
                        </div>
                        <div class="col-6 col-sm-3">
                          <label for="name" class="h4">Cost</label>
                          <input
                            type="text"
                            class="form-control bg-light"
                            v-model="cost"
                          />
                        </div>
                        <div class="col-6 col-sm-3">
                          <label class="h4" style="color: #ffffff;">Cost</label>
                          <br />
                          <button
                            type="button"
                            class="btn btn-rx px-4"
                            data-toggle="modal"
                            data-target="#rx-modal"
                            :class="{
                              pointerEventAuto: rxContainer != null,
                            }"
                          >
                            RX
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer" :class="{ disableform: haveInput == 'true' }">
          <button
            type="button"
            class="btn btn-warning px-5"
            data-dismiss="modal"
            id="periodontal-treatment-and-teeth-bleaching-btn"
            @click="saveData"
            :class="{ disableformForInput: haveInput == 'true' }"
            v-if="
              (edit == 'false' || !edit) && (newVisit == 'false' || !newVisit)
            "
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-warning px-5"
            data-dismiss="modal"
            id="periodontal-treatment-and-teeth-bleaching-btn"
            v-if="edit == 'true'"
            @click="editData"
          >
            Save changes
          </button>
          <button
            type="button"
            class="btn btn-warning px-5"
            data-dismiss="modal"
            id="periodontal-treatment-and-teeth-bleaching-btn"
            @click="saveDataToNewVisit"
            v-if="newVisit == 'true'"
          >
            New Visit
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of file  -->
  <div class="modal" id="modal-of-file-periodontal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-body" :class="{ disableform: haveInput == 'true' }">
          <img
            id="file-ip-7-preview"
            style="display:none;"
            class="uploaded-img"
          />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-danger m-2"
              @click="deleteImage"
              :class="{ disableformForInput: haveInput == 'true' }"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2 not-disabled"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { ref, computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import { timestamp } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { useStore } from "vuex";
export default {
  props: [
    "patientInformation",
    "dateOfNextVisitPeriodontal",
    "treatmentPlan",
    "haveInput",
    "edit",
    "newVisit",
    "id",
    "haveSubCases",
    "rx",
    "rxContainer",
    "images",
    "sendFromSubCase",
  ],
  emits: ["needSomeRequiredData"],
  setup(props, context) {
    const { updateDoc } = useDocument("patients");
    const { updateDoc: updateVisits } = useDocument("visits");
    const { addDoc } = useCollection("patients");
    const { addDoc: addNewVisit } = useCollection("visits");
    const { user } = getUser();
    const { filePath, url, uploadImage, deleteImage: delImage } = useStorage();
    const store = useStore();

    function getTeethBtnSelected(teethBtnsClass) {
      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);
      console.log(arrayOfTeeth);
      let arrayOfSelectedTeeth = [];
      for (let i = 0; i < arrayOfTeeth.length; i++) {
        if ($(arrayOfTeeth[i]).hasClass("active") == false) {
          arrayOfSelectedTeeth.push(i + 1);
        }
      }
      return arrayOfSelectedTeeth;
    }

    function showTeethBtnSelected(arrayOfSelectedTeeth, teethBtnsClass) {
      // remove class is redundant

      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);

      if (teethBtnsClass == "teeth-btn-endodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-primary"
          );
        }
      } else if (teethBtnsClass == "teeth-btn-composite") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-faroze");
        }
      } else if (teethBtnsClass == "teeth-btn-extraction") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-danger");
        }
      } else if (teethBtnsClass == "teeth-btn-orthodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-dark");
        }
      } else if (teethBtnsClass == "teeth-btn-Implant") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-info");
        }
      } else if (teethBtnsClass == "teeth-btn-periodontal-treatment") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-warning"
          );
        }
      } else if (teethBtnsClass == "teeth-btn-prosthetic-and-hollywood-smile") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          // $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-secondary");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("btn-light");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("active");

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).addClass("btn-m-secondary");
        }
      }
    }

    const periodontalTreatmentOrTeethBleaching = ref("");
    const chiefComplaint = ref([]);
    const chiefComplaintOtherInput = ref("");
    const chiefComplaintOtherCheck = ref(false);
    const habit = ref("");
    const somking = ref("");
    const plaque = ref("");
    const calculus = ref("");
    const diagnosis = ref("");
    const plan = ref("");

    const nameOfMaterialUsed = ref("");
    const typeOfMaterialUsed = ref("");
    const colorShadeBeforeBleaching = ref("");
    const colorShadeAfterBleaching = ref("");
    const teethSensitivity = ref("");

    const notes = ref("");
    const cost = ref("");
    const inputFile = ref(null);
    const arrayOfFiles = ref([]);
    const activeFile = ref(null);
    let activeFile2 = ref(null);
    let imagesArray = ref([]);

    const getData = () => {
      let teeth = getTeethBtnSelected("teeth-btn-periodontal-treatment");
      let periodontalTreatmentObj = false;
      let TeethBleachingObj = false;
      if (
        periodontalTreatmentOrTeethBleaching.value == "Periodontal Treatment"
      ) {
        periodontalTreatmentObj = {
          chiefComplaint: chiefComplaint.value,
          chiefComplaintOtherInput: chiefComplaintOtherInput.value,
          habit: habit.value,
          somking: somking.value,
          plaque: plaque.value,
          calculus: calculus.value,
          diagnosis: diagnosis.value,
          plan: plan.value,
        };
      } else if (
        periodontalTreatmentOrTeethBleaching.value == "Teeth Bleaching"
      ) {
        TeethBleachingObj = {
          nameOfMaterialUsed: nameOfMaterialUsed.value,
          typeOfMaterialUsed: typeOfMaterialUsed.value,
          colorShadeBeforeBleaching: colorShadeBeforeBleaching.value,
          colorShadeAfterBleaching: colorShadeAfterBleaching.value,
          teethSensitivity: teethSensitivity.value,
        };
      }
      if (cost.value == "") {
        cost.value = 0;
      }
      let treatmentPlan = {
        selectedTeeth: teeth,
        periodontalTreatmentOrTeethBleaching:
          periodontalTreatmentOrTeethBleaching.value,
        periodontalTreatmentObj: periodontalTreatmentObj,
        TeethBleachingObj: TeethBleachingObj,
        notes: notes.value,
        cost: cost.value,
        dateOfNextVisit: props.dateOfNextVisitPeriodontal,
      };
      return treatmentPlan;
    };

    const saveData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let res = await addDoc({
        type: "Periodontal Treatment And Teeth Bleaching",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        createdAt: timestamp(),
        userId: user.value.uid,
        haveSubCases: 0,
      });
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
    };
    const counterForNewVisitsAdds = computed(
      () => store.state.counterForNewVisitsAdds
    );
    const incrementCounterForNewVisitsAddsByOne = () => {
      store.commit("incrementCounterForNewVisitsAddsByOne");
    };
    const saveDataToNewVisit = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let newHaveSubCases =
        parseInt(props.haveSubCases) + counterForNewVisitsAdds.value;
      let res = await addNewVisit({
        type: "Periodontal Treatment And Teeth Bleaching",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        createdAt: timestamp(),
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        userId: user.value.uid,
        mainCaseId: props.id,
        haveSubCases: 0,
      });
      await updateDoc(
        {
          haveSubCases: newHaveSubCases,
        },
        props.id
      );
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
      incrementCounterForNewVisitsAddsByOne();
    };
    const editData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          // if variable is not image it is file
          if (!arrayOfFiles.value[i].url) {
            await uploadImage(arrayOfFiles.value[i], props.treatmentPlan.id);
            images.push({
              url: url.value,
              filePath: filePath.value,
            });
          } else {
            images.push(arrayOfFiles.value[i]);
          }
        }
        if (props.sendFromSubCase == "true") {
          await updateVisits(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        } else {
          await updateDoc(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        }
      }
      if (props.sendFromSubCase == "true") {
        await updateVisits(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      } else {
        await updateDoc(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      }
    };

    onMounted(() => {
      imagesArray.value = props.images;
      // if the page is edit take arrayOfFiles to be array of images => show arrayOfFiles ui , adding image case in arrayOfFiles functions
      if (props.edit == "true") {
        arrayOfFiles.value = imagesArray.value;
      }
      $(".teeth-btn-periodontal-treatment").on("click", function() {
        if ($(this).hasClass("active") == true) {
          $(this).removeClass("btn-light");
          $(this).addClass("btn-warning");
        } else {
          $(this).removeClass("btn-warning");
          $(this).addClass("btn-light");
        }
      });
      if (
        props.treatmentPlan &&
        props.treatmentPlan.type == "Periodontal Treatment And Teeth Bleaching"
      ) {
        showTeethBtnSelected(
          props.treatmentPlan.selectedTeeth,
          "teeth-btn-periodontal-treatment"
        );
        periodontalTreatmentOrTeethBleaching.value =
          props.treatmentPlan.periodontalTreatmentOrTeethBleaching;
        notes.value = props.treatmentPlan.notes;
        cost.value = props.treatmentPlan.cost;
        console.log(props.treatmentPlan.periodontalTreatmentOrTeethBleaching);
        if (
          props.treatmentPlan.periodontalTreatmentOrTeethBleaching ==
          "Periodontal Treatment"
        ) {
          chiefComplaint.value = props.treatmentPlan.chiefComplaint;
          if (props.treatmentPlan.chiefComplaintOtherInput) {
            chiefComplaintOtherInput.value =
              props.treatmentPlan.chiefComplaintOtherInput;
            chiefComplaintOtherCheck.value = true;
          }
          habit.value = props.treatmentPlan.habit;
          somking.value = props.treatmentPlan.somking;
          plaque.value = props.treatmentPlan.plaque;
          calculus.value = props.treatmentPlan.calculus;
          diagnosis.value = props.treatmentPlan.diagnosis;
          console.log(props.treatmentPlan);
          plan.value = props.treatmentPlan.plan;
        } else if (
          props.treatmentPlan.periodontalTreatmentOrTeethBleaching ==
          "Teeth Bleaching"
        ) {
          nameOfMaterialUsed.value = props.treatmentPlan.nameOfMaterialUsed;
          typeOfMaterialUsed.value = props.treatmentPlan.typeOfMaterialUsed;
          colorShadeBeforeBleaching.value =
            props.treatmentPlan.colorShadeBeforeBleaching;
          colorShadeAfterBleaching.value =
            props.treatmentPlan.colorShadeAfterBleaching;
          teethSensitivity.value = props.treatmentPlan.teethSensitivity;
        }
      }
    });
    const triggerFunc = () => {
      $("#no-need").modal("show");
      return;
      inputFile.value.click();
    };
    const imageUpload = (e) => {
      if (e.target.files.length > 0) {
        if (arrayOfFiles.value.length >= 5) {
          return;
        }
        if (!arrayOfFiles.value.length) {
          $("#periodontal-img").height("+=30");
        }
        let selected = e.target.files[0];
        arrayOfFiles.value.push(selected);
      }
    };

    const handleClickFile = (f) => {
      let src;
      activeFile.value = f;
      // if it is image , not file
      if (f.url) {
        src = f.url;
      } else {
        src = URL.createObjectURL(f);
      }
      let preview = document.getElementById("file-ip-7-preview");
      preview.src = src;
      preview.style.display = "block";
      $("#modal-of-file-periodontal").modal("show");
    };
    const handleClickFile2 = (img) => {
      activeFile2.value = img.filePath;
      let preview = document.getElementById("file-ip-7-preview");
      preview.src = img.url;
      preview.style.display = "block";
      $("#modal-of-file-periodontal").modal("show");
    };
    const deleteImage = async () => {
      if (activeFile2.value) {
        await delImage(activeFile2.value);
        imagesArray.value.splice(
          imagesArray.value.indexOf(activeFile2.value),
          1
        );
        await updateDoc(
          {
            images: imagesArray.value,
          },
          props.treatmentPlan.id
        );
      } else {
        arrayOfFiles.value.splice(
          arrayOfFiles.value.indexOf(activeFile.value),
          1
        );
        // the page is edit and the image is in the database
        if (props.edit == "true" && activeFile.value.filePath) {
          await delImage(activeFile.value.filePath);
          await updateDoc(
            {
              images: arrayOfFiles.value,
            },
            props.treatmentPlan.id
          );
        }
      }
      let preview = document.getElementById("file-ip-7-preview");
      preview.src = "";
      preview.style.display = "none";
      $("#modal-of-file-periodontal").modal("hide");
    };
    return {
      periodontalTreatmentOrTeethBleaching,
      chiefComplaint,
      chiefComplaintOtherInput,
      chiefComplaintOtherCheck,
      habit,
      somking,
      plaque,
      calculus,
      diagnosis,
      plan,
      nameOfMaterialUsed,
      typeOfMaterialUsed,
      colorShadeBeforeBleaching,
      colorShadeAfterBleaching,
      teethSensitivity,
      notes,
      cost,
      saveData,
      editData,
      inputFile,
      triggerFunc,
      imageUpload,
      arrayOfFiles,
      handleClickFile,
      deleteImage,
      handleClickFile2,
      imagesArray,
      saveDataToNewVisit,
    };
  },
};
</script>

<style scoped>
@media (min-width: 500px) {
  h1,
  .h1 {
    font-size: 2.2rem !important;
  }
}
@media (min-width: 200px) and (max-width: 500px) {
  h3,
  .h3 {
    font-size: 0.8rem !important;
  }
  h1,
  .h1 {
    font-size: 1rem !important;
  }
}
</style>
