<template>
  <!-- The Modal -->
  <div class="modal fade" id="signupModal">
    <div class="modal-dialog modal-lg modal-dialog-centered">
      <div class="modal-content">
        <!-- Modal body -->
        <div class="modal-body">
          <section>
            <div class="container">
              <div class="row" v-if="finishSignup">
                <div class="col-md-12 login-sec bg-offwhite">
                  <p class="my-1">
                    verifie message is sent please check your email and then
                    click Log in
                  </p>
                  <p class="my-1">
                    if there is no message please signup again with real email
                  </p>

                  <hr />
                  <div class="d-flex justify-content-between">
                    <button
                      class="btn btn-warning mt-4 mb-0"
                      @click="sendMessageAgin"
                    >
                      send verifie message agin
                    </button>
                    <button
                      class="btn btn-warning mt-4 mb-0"
                      @click="redirectToLogin"
                    >
                      Login
                    </button>
                  </div>
                </div>
              </div>
              <div class="row" v-if="!finishSignup">
                <div class="col-12 login-sec bg-offwhite pb-0">
                  <div class="login-form" v-show="level == 1">
                    <div class="row">
                      <h2 class="text-center col-12">Signup Now</h2>
                      <div class="col-12 mb-3">
                        <label class="text-capitalize"
                          >select the methode of sign in</label
                        >
                        <select
                          class="form-control selectpicker"
                          v-model="selectPayOrFree"
                        >
                          <option value="1">completly sign in</option>
                          <option value="2">try free for 10 days</option>
                        </select>
                      </div>

                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="text-uppercase">Full Name</label>
                          <input
                            type="text"
                            class="form-control"
                            required
                            v-model="name"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label class="text-uppercase">Email</label>
                          <input
                            type="text"
                            class="form-control"
                            required
                            v-model="userName"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label
                            for="exampleInputPassword1"
                            class="text-uppercase"
                            >password</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            required
                            v-model="password"
                          />
                        </div>
                      </div>
                      <div class="col-12 col-sm-6">
                        <div class="form-group">
                          <label
                            for="exampleInputPassword1"
                            class="text-uppercase"
                            >confirm password</label
                          >
                          <input
                            type="password"
                            class="form-control"
                            required
                            v-model="confirmPassword"
                          />
                        </div>
                      </div>

                      <div
                        class="col-12 col-sm-8"
                        v-if="selectPayOrFree == '1'"
                      >
                        <div class="form-group">
                          <label
                            for="exampleInputPassword1"
                            class="text-uppercase"
                            >payment code</label
                          >
                          <input
                            class="form-control"
                            required
                            v-model="paymentCode"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="login-form" v-if="level == 2">
                    <div class="col-10">
                      <h4>the number of doctors is {{ numberOfDoctors }}</h4>
                      <div class="form-group">
                        <label class="text-uppercase"
                          >Enter the doctor names in your clinic, by press enter
                          key</label
                        >
                        <div class="input-group mb-3">
                          <input
                            class="form-control"
                            v-model="doctorName"
                            @keyup.enter="addDoctor()"
                          />

                          <div class="input-group-append">
                            <button
                              class="btn btn-outline-secondary"
                              type="button"
                              @click="addDoctor()"
                            >
                              Add
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <ul class="list-group">
                          <li
                            class="list-group-item d-flex justify-content-between align-items-center"
                            v-for="d in doctors"
                            :key="d"
                          >
                            {{ d }}
                            <span
                              class="badge badge-danger badge-pill pointer"
                              @click="removeDoctor(d)"
                              >X</span
                            >
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>

                  <div v-if="error" class="error ml-2">{{ error }}</div>
                  <div class="d-flex justify-content-center">
                    <div class="copy-text">
                      يمكنكم شراء كود التفعيل من خلال صفحتنا على الفيسبوك عدد
                      الأطباء المسموح به للعيادات هو 3 اطباء فقط , للزيادة
                      يمكنكم شراء كود تفعيل نسخة المراكز الطبية
                      <a href="https://www.facebook.com/Myclinic2022">عيادتي</a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          class="modal-footer d-flex justify-content-between"
          v-if="!finishSignup"
        >
          <button
            class="btn btn-login"
            @click="backFunc"
            :disabled="level == 1"
          >
            Back
          </button>
          <button class="btn btn-login" @click="handleSubmit1">
            Next
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import useSignup from "@/composables/useSignup";
import useLogout from "@/composables/useLogout";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser";
import { timestamp } from "@/firebase/config";
import { projectFirestore } from "@/firebase/config";
import { projectAuth } from "@/firebase/config";
import { onMounted, ref } from "@vue/runtime-core";
export default {
  setup() {
    const { error, signup } = useSignup();
    const { addDoc } = useCollection("users");
    const { updateDoc } = useDocument("users");
    const { user } = getUser();
    const name = ref("");
    const userName = ref("");
    const password = ref("");
    const confirmPassword = ref("");
    const paymentCode = ref("");
    const finishSignup = ref(false);
    const doctorName = ref("");
    const doctors = ref([]);
    const numberOfDoctors = ref(3);
    const level = ref(1);
    const selectPayOrFree = ref("1");
    const { logout } = useLogout();

    let checkingEmailVerifiedInterval;
    // to get data from firebase you need two variables one for all docs (using with foreach) and another for single doc that you want
    const codes = ref("");
    const code = ref([]);

    const checkCode = async () => {
      codes.value = await projectFirestore
        .collection("paymentCodes")
        .where(...["code", "==", paymentCode.value])
        .get();
      codes.value.forEach((doc) => {
        code.value.push(doc.data());
      });
      // if there is a code take the number of doctors
      if (code.value.length)
        numberOfDoctors.value = code.value[0].numberOfDoctors;
    };
    // const sendEmailFunc = () => {
    //   projectAuth.currentUser.reload().then(async (ok) => {

    //   })
    // }
    const checkingEmailVerified = () => {
      projectAuth.currentUser.reload().then(async (ok) => {
        if (projectAuth.currentUser.emailVerified) {
          $("#signupModal").modal("hide");
          clearInterval(checkingEmailVerifiedInterval);
          if (selectPayOrFree.value != "2") {
            let userCodeObj;
            let userCodeId;
            let userCodeObjRef = await projectFirestore
              .collection("users")
              .where(...["userId", "==", user.value.uid])
              .get();

            userCodeObjRef.forEach((doc) => {
              userCodeObj = doc.data();
              userCodeId = doc.id;
            });
            if (!userCodeObj.getPaymentCode && !userCodeObj.free) {
              let pCodeId;
              let PcodeRef = await projectFirestore
                .collection("paymentCodes")
                .where(...["code", "==", userCodeObj.paymentCode])
                .get();
              PcodeRef.forEach((doc) => {
                pCodeId = doc.id;
              });
              const { deleteDoc } = useDocument("paymentCodes");
              await deleteDoc(pCodeId);
              await updateDoc(
                {
                  getPaymentCode: true,
                },
                userCodeId
              );
            }
          }
        }
      });
    };
    const addDoctor = () => {
      if (doctors.value.length < numberOfDoctors.value) {
        doctors.value.push(doctorName.value);
        doctorName.value = "";
      }
    };
    const removeDoctor = (doctor) => {
      let index = doctors.value.indexOf(doctor);
      if (index > -1) {
        doctors.value.splice(index, 1);
      }
    };
    const handleSubmit1 = async () => {
      if (level.value == 2) {
        handleSubmit2();
      }
      // validation for name
      let regName = /^[a-zA-Z]/;
      if (!regName.test(name.value)) {
        error.value = "the name field is invalid";
        return;
      } else {
        error.value = "";
      }
      if (password.value != confirmPassword.value) {
        error.value = "the password field dont match confirm password field";
        return;
      } else {
        error.value = "";
      }
      if (selectPayOrFree.value == "1") {
        await checkCode();
        if (!code.value.length) {
          error.value = "the payment code is incorrect";
          return;
        } else {
          error.value = "";
        }
      }
      level.value = 2;
    };
    const handleSubmit2 = async () => {
      await signup(userName.value, password.value, name.value);
      let free = false;
      let now = new Date();
      let endTime = new Date().setDate(now.getDate() + 365);
      if (selectPayOrFree.value == "1") {
        paymentCode.value = code.value[0].code;
      }

      if (selectPayOrFree.value == "2") {
        free = true;
        paymentCode.value = "no code";
        endTime = new Date().setDate(now.getDate() + 10);
      }
      if (!error.value) {
        finishSignup.value = true;
        let paymentObj = {
          userId: user.value.uid,
          getPaymentCode: false,
          paymentCode: paymentCode.value,
          name: name.value,
          doctors: doctors.value,
          numberOfDoctors: numberOfDoctors.value,
          free: free,
          emailVerified: false,
          createdAt: timestamp(),
          endTime: endTime,
        };

        await addDoc(paymentObj);

        try {
          console.log("sending email");
          await user.value.sendEmailVerification();
        } catch (e) {
          console.log(e);
        }

        // checkingEmailVerifiedInterval = setInterval(
        //   checkingEmailVerified,
        //   5000
        // );
      }
    };
    const redirectToLogin = () => {
      $("#signupModal").modal("hide");
      logout();
      $("#loginModal").modal("show");
    };
    const sendMessageAgin = async () => {
      try {
        await user.value.sendEmailVerification();
      } catch (e) {
        console.log(e);
      }
    };
    const backFunc = () => {
      level.value = 1;
    };
    onMounted(() => {
      if (user.value && !user.value.emailVerified) {
        finishSignup.value = true;
      }
      // if (!user.value) {
      //   $("#signupModal").modal({
      //     backdrop: "static",
      //     keyboard: false,
      //   });
      // }
      $(".selectpicker").selectpicker("refresh");
    });

    return {
      name,
      userName,
      password,
      confirmPassword,
      paymentCode,
      handleSubmit1,
      finishSignup,
      checkingEmailVerified,
      checkingEmailVerifiedInterval,
      error,
      doctorName,
      doctors,
      addDoctor,
      removeDoctor,
      level,
      numberOfDoctors,
      selectPayOrFree,
      backFunc,
      sendMessageAgin,
      redirectToLogin,
    };
  },
};
</script>

<style scoped>
.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fdf7ee !important;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.login-block {
  background: #fac10a; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #ffb88c,
    #fac10a
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to bottom,
    #ffb88c,
    #fac10a
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
  float: left;
  width: 100%;
  padding: 50px 0;
}
/* .banner-sec{background:url(https://static.pexels.com/photos/33972/pexels-photo.jpg)  no-repeat left bottom; background-size:cover; min-height:500px; border-radius: 0 10px 10px 0; padding:0;} */
.container {
  background: #fff;
  border-radius: 10px;
  box-shadow: 15px 20px 0px rgba(0, 0, 0, 0.1);
}
.carousel-inner {
  border-radius: 0 10px 10px 0;
}
.carousel-caption {
  text-align: left;
  left: 5%;
}
.login-sec {
  padding: 50px 30px;
  position: relative;
}
.login-sec .copy-text {
  /* position: absolute; */
  width: 100%;
  /* bottom: 20px; */
  font-size: 0.8rem;
  text-align: center;
}
.login-sec .copy-text i {
  color: #fac10a;
}
.login-sec .copy-text a {
  color: #fac10a;
}
.login-sec h2 {
  margin-bottom: 30px;
  font-weight: 800;
  font-size: 30px;
  color: #fac10a;
}
.login-sec h2:after {
  content: " ";
  width: 100px;
  height: 5px;
  background: #fac10a;
  display: block;
  margin-top: 20px;
  border-radius: 3px;
  margin-left: auto;
  margin-right: auto;
}
.btn-login {
  background: #fac10a;
  color: #fff;
  font-weight: 600;
}
/* .banner-text{width:70%; position:absolute; bottom:40px; padding-left:20px;}
.banner-text h2{color:#fff; font-weight:600;}
.banner-text h2:after{content:" "; width:100px; height:5px; background:#FFF; display:block; margin-top:20px; border-radius:3px;}
.banner-text p{color:#fff;} */

@media (min-width: 768px) {
  .modal-xl {
    width: 90%;
    max-width: 1200px;
  }
}
.img-fluid {
  max-width: 100%;
  /* object-fit: cover; */
  height: 680px !important;
}
/* @media (min-width: 768px) and (max-width: 1060px) {
  .img-fluid {
    max-width: 100%;
    height: 400px !important;
  }
} */

.modal-content {
  background-color: rgb(248, 240, 227);
}
.bg-offwhite {
  background-color: rgb(248, 240, 227);
}

.login-sec .copy-text {
  /* position: absolute; */
  /* width: 80%; */
  /* bottom: 5px; */
  font-size: 0.8rem;
  text-align: center;
}
</style>
