<template>
  <Navbar navBackground="white" :fixedTop="false" />
  <!-- haveSubCases: p.haveSubCases have not be undifined -->
  <div class="container">
    <form class="example mb-4" @submit.prevent="searchFun">
      <input
        type="text"
        placeholder="Search.."
        name="search"
        v-model="searchVal"
      />
      <button type="submit"><i class="fa fa-search text-dark"></i></button>
    </form>
    <table class="table" id="datatable">
      <thead>
        <tr>
          <th>Name</th>
          <th>Date Of Visit</th>
          <th>Information</th>
          <th>Visits</th>
          <th>Edit</th>
          <th>Print</th>
          <th>New Visit</th>
          <th>Delete</th>
        </tr>
      </thead>
      <!-- disabledMode => '1' show Information , disabledMode => '1' Edit ,   disabledMode => '2' new visit -->
      <tbody v-if="computedPatients">
        <tr v-for="p in computedPatients" :key="p.id">
          <td>{{ p.patientInformation.name }}</td>
          <td>{{ p.patientInformation.dateOfVisit }}</td>
          <td>
            <router-link
              class="btn"
              :class="p.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...p.patientInformation,
                  ...p.rx,
                  id: p.id,
                  haveInput: true,
                  disabledMode: '1',
                  edit: false,
                  newVisit: false,
                  ...p.treatmentPlan,
                  type: p.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    p.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    p.numberOfCanalsAndLengthObjJustCanalArray,
                  ...p.prostheticObj,
                  ...p.hollywoodSmileObj,
                  ...p.periodontalTreatmentObj,
                  ...p.TeethBleachingObj,
                  print: false,
                  imagesJustUrl: p.imagesJustUrl,
                  imageJustFilePath: p.imageJustFilePath,
                  haveSubCases: p.haveSubCases,
                },
              }"
            >
              show Information</router-link
            >
          </td>
          <td>
            <router-link
              class="btn"
              :class="[{ disabled: p.haveSubCases == 0 }, p.btnType]"
              :to="{
                name: 'NewVisits',
                params: {
                  mainCaseId: p.id,
                  haveSubCases: p.haveSubCases,
                },
              }"
              @click="saveNewVisitsObj(p)"
            >
              All Visits
              <kbd>{{ p.haveSubCases }}</kbd></router-link
            >
          </td>
          <td>
            <router-link
              class="btn"
              :class="p.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...p.patientInformation,
                  ...p.rx,
                  id: p.id,
                  haveInput: false,
                  disabledMode: '1',
                  edit: true,
                  newVisit: false,
                  ...p.treatmentPlan,
                  type: p.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    p.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    p.numberOfCanalsAndLengthObjJustCanalArray,
                  ...p.prostheticObj,
                  ...p.hollywoodSmileObj,
                  ...p.periodontalTreatmentObj,
                  ...p.TeethBleachingObj,
                  print: false,
                  imagesJustUrl: p.imagesJustUrl,
                  imageJustFilePath: p.imageJustFilePath,
                  haveSubCases: p.haveSubCases,
                },
              }"
            >
              Edit</router-link
            >
          </td>

          <td>
            <router-link
              class="btn"
              :class="p.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...p.patientInformation,
                  ...p.rx,
                  id: p.id,
                  haveInput: true,
                  disabledMode: '1',
                  edit: false,
                  newVisit: false,
                  ...p.treatmentPlan,
                  type: p.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    p.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    p.numberOfCanalsAndLengthObjJustCanalArray,
                  ...p.prostheticObj,
                  ...p.hollywoodSmileObj,
                  ...p.periodontalTreatmentObj,
                  ...p.TeethBleachingObj,
                  print: true,
                  imagesJustUrl: p.imagesJustUrl,
                  imageJustFilePath: p.imageJustFilePath,
                  haveSubCases: p.haveSubCases,
                },
              }"
            >
              Print</router-link
            >
          </td>

          <td>
            <router-link
              class="btn"
              :class="p.btnType"
              :to="{
                name: 'NewPatient',
                params: {
                  ...p.patientInformation,
                  ...p.rx,
                  id: p.id,
                  haveInput: false,
                  edit: false,
                  newVisit: true,
                  disabledMode: '2',
                  ...p.treatmentPlan,
                  type: p.type,
                  numberOfCanalsAndLengthObjJustLengthArray:
                    p.numberOfCanalsAndLengthObjJustLengthArray,
                  numberOfCanalsAndLengthObjJustCanalArray:
                    p.numberOfCanalsAndLengthObjJustCanalArray,
                  ...p.prostheticObj,
                  ...p.hollywoodSmileObj,
                  ...p.periodontalTreatmentObj,
                  ...p.TeethBleachingObj,
                  print: false,
                  haveSubCases: p.haveSubCases,
                },
              }"
            >
              New Visit</router-link
            >
          </td>

          <!-- <td><button class="btn" :class="p.btnType">New Visit</button></td> -->
          <td>
            <button
              class="btn"
              :class="p.btnType"
              @click="getIdForDelete(p.id)"
            >
              Delete
            </button>
          </td>
        </tr>
      </tbody>
    </table>
    <nav aria-label="Page navigation" v-if="computedPatients.length">
      <ul class="pagination justify-content-center">
        <li class="page-item">
          <a
            class="page-link"
            aria-label="Previous"
            @click="handleClick('back')"
          >
            <span aria-hidden="true">&laquo;</span>
            <span class="sr-only">Previous</span>
          </a>
        </li>

        <!-- <li class="page-item"><a class="page-link" href="#">1</a></li>
        <li class="page-item"><a class="page-link" href="#">2</a></li>
        <li class="page-item"><a class="page-link" href="#">3</a></li> -->
        <li class="page-item">
          <a class="page-link" aria-label="Next" @click="handleClick('next')">
            <span aria-hidden="true">&raquo;</span>
            <span class="sr-only">Next</span>
          </a>
        </li>
      </ul>
    </nav>
  </div>
  <!-- modal of delete  -->

  <div class="modal fade" id="delete-modal" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLabel">
            are you sure you want to delete this patient informations (all
            visits of this patient will delete) ?
          </h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body d-flex justify-content-center">
          <button
            class="btn btn-danger m-2 px-3"
            data-dismiss="modal"
            style="font-family:'Merriweather'"
            @click="deletePatient(idForDelete)"
          >
            Yes
          </button>
          <button
            class="btn btn-success m-2 px-3"
            data-dismiss="modal"
            style="font-family:'Merriweather'"
          >
            No
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { computed, ref } from "@vue/reactivity";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import getUser from "@/composables/getUser";
import Navbar from "@/components/Navbar.vue";
import { projectFirestore } from "@/firebase/config";
import { onMounted } from "@vue/runtime-core";
import { useStore } from "vuex";
export default {
  components: { Navbar },
  setup() {
    const { user } = getUser();
    const { deleteDoc } = useDocument("patients");
    const { deleteDoc: deleteSubDoc } = useDocument("visits");
    const { deleteImage: delImage } = useStorage();
    const store = useStore();
    // for getting all patients from database and ussed in computed function
    const patients = ref([]);
    const searchVal = ref("");
    // for detect the last patient
    let lastPatient = ref(null);
    const getLastPatient = async () => {
      let l = await projectFirestore
        .collection("patients")
        .orderBy("createdAt")
        .limit(1)
        .get();
      l.forEach((doc) => {
        lastPatient.value = doc.data();
      });
    };
    // because in mounted hook we call getPatients and that func increase pointer by 8 so that it will be 0
    let pointer = ref(-8);
    // used to stop next btn from work when we get the last part of data
    const stopFlagForNext = ref(false);
    // used to inform the system we click back so we dont need to fetch new data for next btn
    const doingBack = ref(false);
    // to get the length of all data
    const lenthOfdata = ref(0);
    // function for get the patients from database
    const getPatients = async (lastPatient) => {
      if (!lastPatient.value) {
        await getLastPatient();
      }

      let ref = projectFirestore
        .collection("patients")
        .where("userId", "==", user.value.uid)
        .orderBy("createdAt", "desc")
        .startAfter(lastPatient.value || 0)
        .limit(8);

      const data = await ref.get();

      // when we arrived to the last patient
      if (data.empty) {
        stopFlagForNext.value = true;
        return;
      }

      data.forEach((doc) => {
        let numberOfCanalsAndLengthObjJustLengthArray = [];
        let numberOfCanalsAndLengthObjJustCanalArray = [];

        let imagesJustUrl = [];
        let imageJustFilePath = [];

        let prostheticObj = false;
        let hollywoodSmileObj = false;

        let periodontalTreatmentObj = false;
        let TeethBleachingObj = false;

        let btnType;

        if (doc.data().images) {
          doc.data().images.forEach((img) => {
            imagesJustUrl.push(img.url);
            imageJustFilePath.push(img.filePath);
          });
        }
        if (doc.data().type == "Endodontic Treatment") {
          btnType = "btn-primary";

          doc
            .data()
            .treatmentPlan.numberOfCanalsAndLengthObj.forEach(
              (canalAndLenth) => {
                numberOfCanalsAndLengthObjJustLengthArray.push(
                  canalAndLenth.lengthOfCanal
                );
                numberOfCanalsAndLengthObjJustCanalArray.push(
                  canalAndLenth.canalName
                );
              }
            );
        } else if (doc.data().type == "Composite Restoration") {
          btnType = "btn-success";
        } else if (doc.data().type == "Extraction") {
          btnType = "btn-danger";
        } else if (doc.data().type == "Orthodontic Treatment") {
          btnType = "btn-dark";
        } else if (doc.data().type == "Prosthetic And Hollywood Smile") {
          btnType = "btn-secondary";
          if (doc.data().treatmentPlan.prostheticObj) {
            prostheticObj = doc.data().treatmentPlan.prostheticObj;
          } else if (doc.data().treatmentPlan.hollywoodSmileObj) {
            hollywoodSmileObj = doc.data().treatmentPlan.hollywoodSmileObj;
          }
        } else if (
          doc.data().type == "Periodontal Treatment And Teeth Bleaching"
        ) {
          btnType = "btn-warning";
          if (doc.data().treatmentPlan.periodontalTreatmentObj) {
            periodontalTreatmentObj = doc.data().treatmentPlan
              .periodontalTreatmentObj;
          } else if (doc.data().treatmentPlan.TeethBleachingObj) {
            console.log(doc.data().treatmentPlan.TeethBleachingObj);
            TeethBleachingObj = doc.data().treatmentPlan.TeethBleachingObj;
          }
        } else if (doc.data().type == "Teeth Implant") {
          btnType = "btn-info";
        } else if (doc.data().type == "Paedodontic") {
          btnType = "btn-orange";
        }

        patients.value.push({
          ...doc.data(),
          id: doc.id,
          btnType: btnType,
          numberOfCanalsAndLengthObjJustLengthArray: numberOfCanalsAndLengthObjJustLengthArray,
          numberOfCanalsAndLengthObjJustCanalArray: numberOfCanalsAndLengthObjJustCanalArray,
          prostheticObj,
          hollywoodSmileObj,
          periodontalTreatmentObj,
          TeethBleachingObj,
          imagesJustUrl: imagesJustUrl,
          imageJustFilePath: imageJustFilePath,
        });
      });

      lastPatient.value = data.docs[data.docs.length - 1];

      pointer.value += 8;
    };

    const updateLengthOfData = async () => {
      let lenthOfdataRef = projectFirestore
        .collection("patients")
        .orderBy("createdAt")
        .where("userId", "==", user.value.uid);
      const data = await lenthOfdataRef.get();
      lenthOfdata.value = data.docs.length;
    };

    onMounted(async () => {
      updateLengthOfData();
      getPatients(lastPatient);
    });

    const nextAndBackForMyPatients = (direction) => {
      if (direction == "next" && lenthOfdata.value > pointer.value + 8) {
        doingBack.value = false;
        pointer.value += 8;
      } else if (direction == "back" && pointer.value != 0) {
        pointer.value -= 8;
        doingBack.value = true;
      }
    };
    const idForDelete = ref(null);
    const getIdForDelete = (id) => {
      idForDelete.value = id;
      $("#delete-modal").modal("show");
    };

    const deletePatient = async (id) => {
      // get the patients that we want to delete it
      let patientToDelete = patients.value.filter(
        (patient) => patient.id == id
      );
      // delete patient from dome
      patients.value = patients.value.filter((patient) => patient.id != id);
      // delete images of this patients
      if (patientToDelete[0].images) {
        for (let i = 0; i < patientToDelete[0].images.length; i++) {
          console.log(patientToDelete[0].images[i].filePath);
          await delImage(patientToDelete[0].images[i].filePath);
        }
      }
      // delete sub patients
      if (patientToDelete[0].haveSubCases) {
        let subPatients = projectFirestore
          .collection("visits")
          .where("userId", "==", user.value.uid)
          .where("mainCaseId", "==", id)
          .orderBy("createdAt", "desc");
        const dataOfsubPatients = await subPatients.get();
        dataOfsubPatients.forEach(async (doc) => {
          await deleteSubDoc(doc.id);
        });
      }
      //  delete from firebase
      await deleteDoc(id);
      //  update the length of data after delete
      updateLengthOfData();
      // get new data instead of deleted element
      let ref = projectFirestore
        .collection("patients")
        .orderBy("createdAt", "desc")
        .where("userId", "==", user.value.uid)
        .startAfter(lastPatient.value || 0)
        .limit(1);

      const data = await ref.get();

      // when we arrived to the last patient
      if (data.empty) {
        stopFlagForNext.value = true;
        return;
      }

      data.forEach((doc) => {
        let numberOfCanalsAndLengthObjJustLengthArray = [];
        let numberOfCanalsAndLengthObjJustCanalArray = [];

        let imagesJustUrl = [];
        let imageJustFilePath = [];

        let prostheticObj = false;
        let hollywoodSmileObj = false;

        let periodontalTreatmentObj = false;
        let TeethBleachingObj = false;

        let btnType;
        if (doc.data().images) {
          doc.data().images.forEach((img) => {
            imagesJustUrl.push(img.url);
            imageJustFilePath.push(img.filePath);
          });
        }
        if (doc.data().type == "Endodontic Treatment") {
          btnType = "btn-primary";

          doc
            .data()
            .treatmentPlan.numberOfCanalsAndLengthObj.forEach(
              (canalAndLenth) => {
                numberOfCanalsAndLengthObjJustLengthArray.push(
                  canalAndLenth.lengthOfCanal
                );
                numberOfCanalsAndLengthObjJustCanalArray.push(
                  canalAndLenth.canalName
                );
              }
            );
        } else if (doc.data().type == "Composite Restoration") {
          btnType = "btn-success";
        } else if (doc.data().type == "Extraction") {
          btnType = "btn-danger";
        } else if (doc.data().type == "Orthodontic Treatment") {
          btnType = "btn-dark";
        } else if (doc.data().type == "Prosthetic And Hollywood Smile") {
          btnType = "btn-secondary";
          if (doc.data().treatmentPlan.prostheticObj) {
            prostheticObj = doc.data().treatmentPlan.prostheticObj;
          } else if (doc.data().treatmentPlan.hollywoodSmileObj) {
            hollywoodSmileObj = doc.data().treatmentPlan.hollywoodSmileObj;
          }
        } else if (
          doc.data().type == "Periodontal Treatment And Teeth Bleaching"
        ) {
          btnType = "btn-warning";
          if (doc.data().treatmentPlan.periodontalTreatmentObj) {
            periodontalTreatmentObj = doc.data().treatmentPlan
              .periodontalTreatmentObj;
          } else if (doc.data().treatmentPlan.TeethBleachingObj) {
            console.log(doc.data().treatmentPlan.TeethBleachingObj);
            TeethBleachingObj = doc.data().treatmentPlan.TeethBleachingObj;
          }
        } else if (doc.data().type == "Teeth Implant") {
          btnType = "btn-info";
        } else if (doc.data().type == "Paedodontic") {
          btnType = "btn-orange";
        }

        patients.value.push({
          ...doc.data(),
          id: doc.id,
          btnType: btnType,
          numberOfCanalsAndLengthObjJustLengthArray: numberOfCanalsAndLengthObjJustLengthArray,
          numberOfCanalsAndLengthObjJustCanalArray: numberOfCanalsAndLengthObjJustCanalArray,
          prostheticObj,
          hollywoodSmileObj,
          periodontalTreatmentObj,
          TeethBleachingObj,
        });
      });

      lastPatient.value = data.docs[data.docs.length - 1];
    };
    const handleClick = async (direction) => {
      // when we get the all data -> stopFlagForNext.value = true
      // when direction is back
      // when doing back -> direction is back and doing next
      //  all these cases there is no need to get new data so that we call nextAndBackForMyPatients(direction)
      if (
        stopFlagForNext.value == true ||
        direction == "back" ||
        doingBack.value == true
      ) {
        nextAndBackForMyPatients(direction);
        return;
      }

      if (direction != "back") {
        await getPatients(lastPatient);
      }
    };
    const computedPatients = computed(() => {
      console.log(pointer.value, pointer.value + 8);
      return patients.value.slice(pointer.value, pointer.value + 8);
    });
    const searchFun = async () => {
      let ref = projectFirestore
        .collection("patients")
        .where("userId", "==", user.value.uid)
        .where("patientInformation.name", "==", searchVal.value);
      const data = await ref.get();
      // console.log(data.docs.length);
      data.forEach((doc) => {
        let numberOfCanalsAndLengthObjJustLengthArray = [];
        let numberOfCanalsAndLengthObjJustCanalArray = [];

        let prostheticObj = false;
        let hollywoodSmileObj = false;

        let periodontalTreatmentObj = false;
        let TeethBleachingObj = false;

        let btnType;
        if (doc.data().type == "Endodontic Treatment") {
          btnType = "btn-primary";

          doc
            .data()
            .treatmentPlan.numberOfCanalsAndLengthObj.forEach(
              (canalAndLenth) => {
                numberOfCanalsAndLengthObjJustLengthArray.push(
                  canalAndLenth.lengthOfCanal
                );
                numberOfCanalsAndLengthObjJustCanalArray.push(
                  canalAndLenth.canalName
                );
              }
            );
        } else if (doc.data().type == "Composite Restoration") {
          btnType = "btn-success";
        } else if (doc.data().type == "Extraction") {
          btnType = "btn-danger";
        } else if (doc.data().type == "Orthodontic Treatment") {
          btnType = "btn-dark";
        } else if (doc.data().type == "Prosthetic And Hollywood Smile") {
          btnType = "btn-secondary";
          if (doc.data().treatmentPlan.prostheticObj) {
            prostheticObj = doc.data().treatmentPlan.prostheticObj;
          } else if (doc.data().treatmentPlan.hollywoodSmileObj) {
            hollywoodSmileObj = doc.data().treatmentPlan.hollywoodSmileObj;
          }
        } else if (
          doc.data().type == "Periodontal Treatment And Teeth Bleaching"
        ) {
          btnType = "btn-warning";
          if (doc.data().treatmentPlan.periodontalTreatmentObj) {
            periodontalTreatmentObj = doc.data().treatmentPlan
              .periodontalTreatmentObj;
          } else if (doc.data().treatmentPlan.TeethBleachingObj) {
            console.log(doc.data().treatmentPlan.TeethBleachingObj);
            TeethBleachingObj = doc.data().treatmentPlan.TeethBleachingObj;
          }
        } else if (doc.data().type == "Teeth Implant") {
          btnType = "btn-info";
        } else if (doc.data().type == "Paedodontic") {
          btnType = "btn-orange";
        }

        patients.value = patients.value.filter((patient) => {
          return patient.id != doc.id;
        });

        patients.value.splice(0, 0, {
          ...doc.data(),
          id: doc.id,
          btnType: btnType,
          numberOfCanalsAndLengthObjJustLengthArray: numberOfCanalsAndLengthObjJustLengthArray,
          numberOfCanalsAndLengthObjJustCanalArray: numberOfCanalsAndLengthObjJustCanalArray,
          prostheticObj,
          hollywoodSmileObj,
          periodontalTreatmentObj,
          TeethBleachingObj,
        });
      });
      searchVal.value = "";
    };
    const saveNewVisitsObj = (obj) => {
      store.commit("makeObj", obj);
    };
    return {
      handleClick,
      patients,
      computedPatients,
      deletePatient,
      searchFun,
      searchVal,
      idForDelete,
      getIdForDelete,
      saveNewVisitsObj,
    };
  },
};
</script>

<style scoped>
* {
  box-sizing: border-box;
}
::placeholder {
  font-size: 20px;
}
/* Style the search field */
form.example input[type="text"] {
  padding: 12px;
  font-size: 18px;
  border: 1px solid grey;
  float: left;
  width: 80%;
  background: #ffffff;
}
/* Style the submit button */
form.example button {
  float: left;
  width: 20%;
  padding: 12px;
  background: #f8f9fa;
  color: white;
  font-size: 18px;
  border: 1px solid grey;
  border-left: none; /* Prevent double borders */
  cursor: pointer;
}

form.example button:hover {
  background: #f8f9fa;
}
/* Clear floats */
form.example::after {
  content: "";
  clear: both;
  display: table;
}
</style>
