<template>
  <!-- teeth prosthetic and hollywood smile modal -->
  <div class="modal fade" id="teeth-prostheti-and-hollywood-smile">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Prosthetic And Hollywood Smile</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <!-- Modal body -->
        <div
          class="modal-body"
          :class="{ disableform: haveInput == 'true' }"
          id="ProstheticAndHollywoodSmilePrintModal"
        >
          <div class="card">
            <div class="row">
              <div class="d-none col-xl-5 d-xl-block">
                <img
                  src="@/assets/images/prosthetic.webp"
                  class="rounded"
                  style="width:100%; height:650px;"
                  id="prosthetic-img"
                />
              </div>
              <div class="col-12 col-xl-7">
                <div class="card-body ml-0 pl-0">
                  <div class="card-title ">
                    <h2>Tooth Number</h2>
                  </div>
                  <div class="card-text">
                    <div
                      class="btn-group btn-group-lg-teeth btn-group-toggle special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div
                      class="btn-group btn-group-toggle btn-group-lg-teeth special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label
                        class="btn btn-light active teeth-btn-prosthetic-and-hollywood-smile"
                      >
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div class="row mt-3 ">
                      <div
                        class="col-12"
                        v-if="!images.length || edit == 'true'"
                      >
                        <input
                          type="file"
                          id="imgupload1"
                          style="display:none"
                          ref="inputFile"
                          @change="imageUpload"
                        />
                        <button
                          type="button"
                          class="btn btn-rx btn-lg btn-block py-0 h4 upload-image"
                          id="OpenImgUpload1"
                          @click="triggerFunc"
                        >
                          Image Upload
                        </button>
                        <div
                          class="div-of-files d-flex justify-content-center"
                          v-if="arrayOfFiles.length"
                        >
                          <div
                            class="paragraph-file"
                            v-for="file in arrayOfFiles"
                            :key="file"
                            @click="handleClickFile(file)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-12" style="pointer-events: auto;" v-else>
                        <div class="div-of-files d-flex justify-content-center">
                          <div
                            class="paragraph-file"
                            v-for="image in imagesArray"
                            :key="image.url"
                            @click="handleClickFile2(image)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                    </div>

                    <div class="row mt-3">
                      <div class="col-12">
                        <div class="pretty p-default p-round p-thick h1">
                          <input
                            type="radio"
                            name="prosthetic-hollywood-smile-selection"
                            value="Prosthetic"
                            v-model="typeOfProsthetic"
                          />
                          <div class="state">
                            <label class="pretty p-default p-curve p-fill h1"
                              >Prosthetic</label
                            >
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col mt-2">
                        <h2 class=" mb-3 mt-2">Types Of Prosthetic</h2>
                        <div class="pretty p-default p-round p-thick h3">
                          <input
                            type="radio"
                            name="type-of-prosthetic"
                            :disabled="typeOfProsthetic == 'Hollywood Smile'"
                            value="Fixed Prosthetic"
                            v-model="typesOfProsthetic"
                          />
                          <div class="state">
                            <label class="pretty p-default p-curve p-fill h3"
                              >Fixed Prosthetic</label
                            >
                          </div>
                        </div>
                        <div class="ml-4">
                          <div class="row">
                            <div class="col-5">
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="fixed-p"
                                  :disabled="
                                    typesOfProsthetic ==
                                      'Removable Prosthetic' ||
                                      typeOfProsthetic == 'Hollywood Smile'
                                  "
                                  value="Crown"
                                  v-model="fixedProsthetic"
                                />
                                <div class="state">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Crown</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="fixed-p"
                                  :disabled="
                                    typesOfProsthetic ==
                                      'Removable Prosthetic' ||
                                      typeOfProsthetic == 'Hollywood Smile'
                                  "
                                  value="Bridge"
                                  v-model="fixedProsthetic"
                                />
                                <div class="state">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Bridge</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="h5 mb-0 pb-0">
                            <label class="my-3 pb-0"
                              >Type Of Meterials Used</label
                            >
                          </div>
                          <div class="row">
                            <div class="col-5">
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="type-of-m"
                                  :disabled="
                                    typesOfProsthetic ==
                                      'Removable Prosthetic' ||
                                      typeOfProsthetic == 'Hollywood Smile'
                                  "
                                  value="Ceramic"
                                  v-model="fixedProstheticTypeOfMeterials"
                                />
                                <div class="state">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Ceramic</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-5">
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="type-of-m"
                                  :disabled="
                                    typesOfProsthetic ==
                                      'Removable Prosthetic' ||
                                      typeOfProsthetic == 'Hollywood Smile'
                                  "
                                  value="Zircon"
                                  v-model="fixedProstheticTypeOfMeterials"
                                />
                                <div class="state">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Zircon</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="pretty p-default p-round p-thick h3">
                          <input
                            type="radio"
                            name="type-of-prosthetic"
                            value="Removable Prosthetic"
                            v-model="typesOfProsthetic"
                            :disabled="typeOfProsthetic == 'Hollywood Smile'"
                          />
                          <div class="state">
                            <label class="pretty p-default p-curve p-fill h3"
                              >Removable Prosthetic</label
                            >
                          </div>
                        </div>
                        <div class="ml-4">
                          <div class="row">
                            <div class="col-4">
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="removable-p"
                                  :disabled="
                                    typesOfProsthetic == 'Fixed Prosthetic' ||
                                      typeOfProsthetic == 'Hollywood Smile'
                                  "
                                  value="RPD"
                                  v-model="removableProsthetic"
                                />
                                <div class="state">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >RPD</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col-6">
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="removable-p"
                                  :disabled="
                                    typesOfProsthetic == 'Fixed Prosthetic' ||
                                      typeOfProsthetic == 'Hollywood Smile'
                                  "
                                  value="RCD"
                                  v-model="removableProsthetic"
                                />
                                <div class="state">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >RCD</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="row mt-4">
                          <div class="col-12">
                            <div class="form-inline">
                              <label class="h3 mr-2">Color Shade</label>
                              <input
                                type="text"
                                class="form-control bg-light"
                                id="prosthetic-color-shade"
                                v-model="colorShadeProsthetic"
                                :disabled="
                                  typeOfProsthetic == 'Hollywood Smile'
                                "
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="card mt-4">
            <div class="row">
              <div class="d-none col-xl-5 d-xl-block">
                <img
                  src="@/assets/images/hoolywood-smile.webp"
                  class="rounded"
                  style="width:100%; height:500px;"
                />
              </div>
              <div class="col-12 col-xl-7">
                <div class="card-body ml-0 pl-0">
                  <div class="card-text">
                    <div>
                      <div class="row">
                        <div class="col-12">
                          <div class="pretty p-default p-round p-thick h1">
                            <input
                              type="radio"
                              name="prosthetic-hollywood-smile-selection"
                              value="Hollywood Smile"
                              v-model="typeOfProsthetic"
                            />
                            <div class="state">
                              <label class="pretty p-default p-curve p-fill h1"
                                >Hollywood Smile</label
                              >
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="ml-3">
                        <h2 class="mb-3">Type</h2>
                        <div class="row">
                          <div class="col-12 col-md-4">
                            <div
                              class="pretty p-default p-round p-thick h4 d-block"
                            >
                              <input
                                type="checkbox"
                                :disabled="typeOfProsthetic == 'Prosthetic'"
                                value="Venner"
                                v-model="hollywoodSmileType"
                              />
                              <div class="state">
                                <label
                                  class="pretty p-default p-curve p-fill h4"
                                  >Venner</label
                                >
                              </div>
                            </div>
                            <div
                              class="pretty p-default p-round p-thick h4 d-block"
                            >
                              <input
                                type="checkbox"
                                :disabled="typeOfProsthetic == 'Prosthetic'"
                                value="E-Max"
                                v-model="hollywoodSmileType"
                              />
                              <div class="state">
                                <label
                                  class="pretty p-default p-curve p-fill h4"
                                  >E-Max</label
                                >
                              </div>
                            </div>
                            <div class="h4 mb-0 pb-0 form-inline">
                              <div class="mb-0 pb-0 ">
                                <div
                                  class="pretty p-default p-round p-thick h4 mr-2"
                                >
                                  <input
                                    type="checkbox"
                                    :disabled="typeOfProsthetic == 'Prosthetic'"
                                    :value="hollywoodSmileTypeOtherInput"
                                    v-model="hollywoodSmileTypeOtherCheck"
                                  />
                                  <div class="state">
                                    <label
                                      class="pretty p-default p-curve p-fill h4"
                                    ></label>
                                  </div>
                                </div>
                                <input
                                  type="text"
                                  id="hollywood-other-input"
                                  class="form-control eng-placeholder d-inline mb-1"
                                  placeholder="others......"
                                  style="width: 50%;"
                                  :disabled="typeOfProsthetic == 'Prosthetic'"
                                  v-model="hollywoodSmileTypeOtherInput"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="col-12 col-md-8">
                            <div
                              class="pretty p-default p-round p-thick h4 d-block"
                            >
                              <input
                                type="checkbox"
                                :disabled="typeOfProsthetic == 'Prosthetic'"
                                value="Lumineers"
                                v-model="hollywoodSmileType"
                              />
                              <div class="state">
                                <label
                                  class="pretty p-default p-curve p-fill h4"
                                  >Lumineers</label
                                >
                              </div>
                            </div>
                            <div
                              class="pretty p-default p-round p-thick h4 d-block"
                            >
                              <input
                                type="checkbox"
                                :disabled="typeOfProsthetic == 'Prosthetic'"
                                value="Dirct Venner (composite)"
                                v-model="hollywoodSmileType"
                              />
                              <div class="state">
                                <label
                                  class="pretty p-default p-curve p-fill h4"
                                  >Dirct Venner (composite)</label
                                >
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="row mt-3">
                        <div class="col-12">
                          <div class="form-inline">
                            <label class="h3 mr-2">Color Shade</label>
                            <input
                              type="text"
                              id="hollywood-smile-color-shade"
                              class="form-control bg-light"
                              :disabled="typeOfProsthetic == 'Prosthetic'"
                              v-model="colorShadehollywoodSmile"
                            />
                          </div>
                        </div>
                      </div>
                      <div class="row mt-5">
                        <div class="col">
                          <div class="form-group">
                            <textarea
                              class="form-control eng-placeholder-lg"
                              rows="3"
                              placeholder="Your Notes"
                              id="prosthetic-and-hollywood-smile-notes"
                              v-model="notes"
                            ></textarea>
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col-12 col-sm-6">
                          <label for="name" class="h4"
                            >Date Of Next Visit</label
                          >
                          <input
                            type="text"
                            class="form-control  bg-light datetimepicker"
                            id="datetimepicker6"
                          />
                        </div>
                        <div class="col-6 col-sm-3">
                          <label for="name" class="h4">Cost</label>
                          <input
                            type="text"
                            class="form-control bg-light"
                            v-model="cost"
                          />
                        </div>
                        <div class="col-6 col-sm-3">
                          <label class="h4" style="color: #ffffff;">Cost</label>
                          <br />
                          <button
                            type="button"
                            class="btn btn-rx px-4"
                            data-toggle="modal"
                            data-target="#rx-modal"
                            :class="{
                              pointerEventAuto: rxContainer != null,
                            }"
                          >
                            RX
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer" :class="{ disableform: haveInput == 'true' }">
          <button
            type="button"
            class="btn btn-secondary px-5"
            data-dismiss="modal"
            id="prosthetic-and-hollywood-smile-btn"
            v-if="
              (edit == 'false' || !edit) && (newVisit == 'false' || !newVisit)
            "
            :class="{ disableformForInput: haveInput == 'true' }"
            @click="saveData"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-secondary px-5"
            data-dismiss="modal"
            id="prosthetic-and-hollywood-smile-btn"
            v-if="edit == 'true'"
            @click="editData"
          >
            Save changes
          </button>
          <button
            type="button"
            class="btn btn-secondary px-5"
            data-dismiss="modal"
            id="prosthetic-and-hollywood-smile-btn"
            @click="saveDataToNewVisit"
            v-if="newVisit == 'true'"
          >
            New Visit
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of file  -->
  <div class="modal" id="modal-of-file-prosthetic" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-body" :class="{ disableform: haveInput == 'true' }">
          <img
            id="file-ip-4-preview"
            style="display:none;"
            class="uploaded-img"
          />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-danger m-2"
              @click="deleteImage"
              :class="{ disableformForInput: haveInput == 'true' }"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2 not-disabled"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { ref, computed } from "@vue/reactivity";
import { onMounted } from "@vue/runtime-core";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import { timestamp } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { useStore } from "vuex";
export default {
  props: [
    "patientInformation",
    "dateOfNextVisitProsthetic",
    "treatmentPlan",
    "haveInput",
    "edit",
    "newVisit",
    "id",
    "haveSubCases",
    "rx",
    "rxContainer",
    "images",
    "sendFromSubCase",
  ],
  emits: ["needSomeRequiredData"],
  setup(props, context) {
    const { updateDoc } = useDocument("patients");
    const { updateDoc: updateVisits } = useDocument("visits");
    const { addDoc } = useCollection("patients");
    const { addDoc: addNewVisit } = useCollection("visits");
    const { user } = getUser();
    const { filePath, url, uploadImage, deleteImage: delImage } = useStorage();
    const store = useStore();

    function getTeethBtnSelected(teethBtnsClass) {
      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);
      console.log(arrayOfTeeth);
      let arrayOfSelectedTeeth = [];
      for (let i = 0; i < arrayOfTeeth.length; i++) {
        if ($(arrayOfTeeth[i]).hasClass("active") == false) {
          arrayOfSelectedTeeth.push(i + 1);
        }
      }
      return arrayOfSelectedTeeth;
    }

    function showTeethBtnSelected(arrayOfSelectedTeeth, teethBtnsClass) {
      // remove class is redundant

      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);

      if (teethBtnsClass == "teeth-btn-endodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-primary"
          );
        }
      } else if (teethBtnsClass == "teeth-btn-composite") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-faroze");
        }
      } else if (teethBtnsClass == "teeth-btn-extraction") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-danger");
        }
      } else if (teethBtnsClass == "teeth-btn-orthodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-dark");
        }
      } else if (teethBtnsClass == "teeth-btn-Implant") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-info");
        }
      } else if (teethBtnsClass == "teeth-btn-periodontal-treatment") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-warning"
          );

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("btn-light");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("active");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).addClass("btn-m-warning");
        }
      } else if (teethBtnsClass == "teeth-btn-prosthetic-and-hollywood-smile") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          // $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
          //   "btn-m-secondary"
          // );

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).removeClass("btn-light");

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).removeClass("active");

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).addClass("btn-m-secondary");
        }
      }
    }

    const typeOfProsthetic = ref("");
    const typesOfProsthetic = ref("");
    const fixedProsthetic = ref("");
    const fixedProstheticTypeOfMeterials = ref("");
    const removableProsthetic = ref("");
    const colorShadeProsthetic = ref("");

    const hollywoodSmileType = ref([]);
    const hollywoodSmileTypeOtherInput = ref("");
    const hollywoodSmileTypeOtherCheck = ref(false);
    const colorShadehollywoodSmile = ref("");

    const notes = ref("");
    const cost = ref("");
    const inputFile = ref(null);
    const arrayOfFiles = ref([]);
    const activeFile = ref(null);
    let activeFile2 = ref(null);
    let imagesArray = ref([]);

    const getData = () => {
      let teeth = getTeethBtnSelected(
        "teeth-btn-prosthetic-and-hollywood-smile"
      );
      let prostheticObj = false;
      let hollywoodSmileObj = false;

      if (typeOfProsthetic.value == "Prosthetic") {
        prostheticObj = {
          typesOfProsthetic: typesOfProsthetic.value,
          fixedProsthetic: fixedProsthetic.value,
          fixedProstheticTypeOfMeterials: fixedProstheticTypeOfMeterials.value,
          removableProsthetic: removableProsthetic.value,
          colorShade: colorShadeProsthetic.value,
        };
      } else if (typeOfProsthetic.value == "Hollywood Smile") {
        hollywoodSmileObj = {
          hollywoodSmileType: hollywoodSmileType.value,
          hollywoodSmileTypeOtherInput: hollywoodSmileTypeOtherInput.value,
          colorShade: colorShadehollywoodSmile.value,
        };
      }
      if (cost.value == "") {
        cost.value = 0;
      }
      let treatmentPlan = {
        selectedTeeth: teeth,
        typeOfProsthetic: typeOfProsthetic.value,
        hollywoodSmileObj: hollywoodSmileObj,
        prostheticObj: prostheticObj,
        notes: notes.value,
        cost: cost.value,
        dateOfNextVisit: props.dateOfNextVisitProsthetic,
      };
      return treatmentPlan;
    };

    const saveData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let res = await addDoc({
        type: "Prosthetic And Hollywood Smile",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        createdAt: timestamp(),
        userId: user.value.uid,
        haveSubCases: 0,
      });
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
    };
    const counterForNewVisitsAdds = computed(
      () => store.state.counterForNewVisitsAdds
    );
    const incrementCounterForNewVisitsAddsByOne = () => {
      store.commit("incrementCounterForNewVisitsAddsByOne");
    };
    const saveDataToNewVisit = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let newHaveSubCases =
        parseInt(props.haveSubCases) + counterForNewVisitsAdds.value;
      let res = await addNewVisit({
        type: "Prosthetic And Hollywood Smile",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        createdAt: timestamp(),
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        userId: user.value.uid,
        mainCaseId: props.id,
        haveSubCases: 0,
      });
      await updateDoc(
        {
          haveSubCases: newHaveSubCases,
        },
        props.id
      );
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
      incrementCounterForNewVisitsAddsByOne();
    };
    const editData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          // if variable is not image it is file
          if (!arrayOfFiles.value[i].url) {
            await uploadImage(arrayOfFiles.value[i], props.treatmentPlan.id);
            images.push({
              url: url.value,
              filePath: filePath.value,
            });
          } else {
            images.push(arrayOfFiles.value[i]);
          }
        }
        if (props.sendFromSubCase == "true") {
          await updateVisits(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        } else {
          await updateDoc(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        }
      }
      if (props.sendFromSubCase == "true") {
        await updateVisits(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      } else {
        await updateDoc(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      }
    };

    onMounted(() => {
      imagesArray.value = props.images;
      // if the page is edit take arrayOfFiles to be array of images => show arrayOfFiles ui , adding image case in arrayOfFiles functions
      if (props.edit == "true") {
        arrayOfFiles.value = imagesArray.value;
      }
      if (
        props.treatmentPlan &&
        props.treatmentPlan.type == "Prosthetic And Hollywood Smile"
      ) {
        showTeethBtnSelected(
          props.treatmentPlan.selectedTeeth,
          "teeth-btn-prosthetic-and-hollywood-smile"
        );
        typeOfProsthetic.value = props.treatmentPlan.typeOfProsthetic;
        notes.value = props.treatmentPlan.notes;
        cost.value = props.treatmentPlan.cost;
        if (props.treatmentPlan.typeOfProsthetic == "Prosthetic") {
          typesOfProsthetic.value = props.treatmentPlan.typesOfProsthetic;
          fixedProsthetic.value = props.treatmentPlan.fixedProsthetic;
          fixedProstheticTypeOfMeterials.value =
            props.treatmentPlan.fixedProstheticTypeOfMeterials;
          removableProsthetic.value = props.treatmentPlan.removableProsthetic;
          colorShadeProsthetic.value = props.treatmentPlan.colorShade;
        } else if (props.treatmentPlan.typeOfProsthetic == "Hollywood Smile") {
          hollywoodSmileType.value = props.treatmentPlan.hollywoodSmileType;
          if (props.treatmentPlan.hollywoodSmileTypeOtherInput) {
            hollywoodSmileTypeOtherInput.value =
              props.treatmentPlan.hollywoodSmileTypeOtherInput;
            hollywoodSmileTypeOtherCheck.value = true;
          }
          colorShadehollywoodSmile.value = props.treatmentPlan.colorShade;
        }
      }
    });
    const triggerFunc = () => {
      $("#no-need").modal("show");
      return;
      inputFile.value.click();
    };
    const imageUpload = (e) => {
      if (e.target.files.length > 0) {
        if (arrayOfFiles.value.length >= 5) {
          return;
        }
        if (!arrayOfFiles.value.length) {
          $("#prosthetic-img").height("+=30");
        }
        let selected = e.target.files[0];
        arrayOfFiles.value.push(selected);
      }
    };

    const handleClickFile = (f) => {
      let src;
      activeFile.value = f;
      // if it is image , not file
      if (f.url) {
        src = f.url;
      } else {
        src = URL.createObjectURL(f);
      }
      let preview = document.getElementById("file-ip-4-preview");
      preview.src = src;
      preview.style.display = "block";
      $("#modal-of-file-prosthetic").modal("show");
    };
    const handleClickFile2 = (img) => {
      activeFile2.value = img.filePath;
      let preview = document.getElementById("file-ip-4-preview");
      preview.src = img.url;
      preview.style.display = "block";
      $("#modal-of-file-prosthetic").modal("show");
    };
    const deleteImage = async () => {
      if (activeFile2.value) {
        await delImage(activeFile2.value);
        imagesArray.value.splice(
          imagesArray.value.indexOf(activeFile2.value),
          1
        );
        await updateDoc(
          {
            images: imagesArray.value,
          },
          props.treatmentPlan.id
        );
      } else {
        arrayOfFiles.value.splice(
          arrayOfFiles.value.indexOf(activeFile.value),
          1
        );
        // the page is edit and the image is in the database
        if (props.edit == "true" && activeFile.value.filePath) {
          await delImage(activeFile.value.filePath);
          await updateDoc(
            {
              images: arrayOfFiles.value,
            },
            props.treatmentPlan.id
          );
        }
      }
      let preview = document.getElementById("file-ip-4-preview");
      preview.src = "";
      preview.style.display = "none";
      $("#modal-of-file-prosthetic").modal("hide");
    };

    return {
      typeOfProsthetic,
      typesOfProsthetic,
      fixedProsthetic,
      fixedProstheticTypeOfMeterials,
      removableProsthetic,
      colorShadeProsthetic,
      hollywoodSmileType,
      hollywoodSmileTypeOtherInput,
      hollywoodSmileTypeOtherCheck,
      colorShadehollywoodSmile,
      notes,
      cost,
      saveData,
      editData,
      inputFile,
      triggerFunc,
      imageUpload,
      arrayOfFiles,
      handleClickFile,
      deleteImage,
      handleClickFile2,
      imagesArray,
      saveDataToNewVisit,
    };
  },
};
</script>

<style></style>
