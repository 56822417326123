import { createStore } from "vuex";

const store = createStore({
  state: {
    counterForNewVisitsAdds: 1,
    newVisitsObj: null,
  },
  mutations: {
    incrementCounterForNewVisitsAddsByOne(state) {
      state.counterForNewVisitsAdds = state.counterForNewVisitsAdds + 1;
    },
    zeroForCounterForNewVisitsAdds(state) {
      state.counterForNewVisitsAdds = 1;
    },
    makeObj(state, obj) {
      state.newVisitsObj = obj;
    },
  },
});

export default store;
