<template>
  <div class="modal" tabindex="-1" role="dialog" id="rx-modal" v-if="mode == 1">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">Warning</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <p>please go to settings for create your rx</p>
          <button class="btn btn-secondary" @click="redirectTOSettings">
            RX Settings
          </button>
        </div>
        <!-- <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
        </div> -->
      </div>
    </div>
  </div>
  <div class="modal fade" id="rx-modal" v-else>
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">RX</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>
        <!-- Modal body -->
        <div
          class="modal-body"
          :class="{
            disableform: rxContainer && edit == 'false' && haveInput == 'true',
          }"
          id="modalBodyOfRx"
        >
          <div class="card">
            <div class="row">
              <div class="col-12 col-md-6">
                <img
                  id="rx-image"
                  src="@/assets/images/rx.webp"
                  style="width:100%;"
                  :class="{
                    'image-height-for-settings': mode == 2,
                    'image-height-normal': mode == 3 || mode == 4,
                  }"
                />
                <div
                  class="row image-section"
                  :class="{
                    'image-section-height-for-settings': mode == 2,
                    'image-section-height-normal': mode == 3 || mode == 4,
                  }"
                >
                  <div class="col-12 ">
                    <div class="row mt-5 ">
                      <div class="col-12">
                        <div class="form-group">
                          <textarea
                            class="form-control placeholder-with-font-rx"
                            rows="2"
                            v-model="nameOfClinic"
                            placeholder="please enter the name of clinic"
                            :class="{ disableform: mode != 2 }"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                    <div class="row mt-5">
                      <div class="col-12">
                        <div class="form-group">
                          <textarea
                            class="form-control placeholder-with-font-rx"
                            rows="2"
                            v-model="locationOfClinic"
                            placeholder="please enter the location of clinic"
                            :class="{ disableform: mode != 2 }"
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    class="col-12 d-flex justify-content-center mb-5"
                    v-if="mode == 2"
                  >
                    <img
                      src="@/assets/images/settings.webp"
                      class="first-time-scale rotating"
                    />
                  </div>
                </div>
              </div>
              <div class="col-12 col-md-6 pr-4">
                <div class="row upper-section mb-2">
                  <div class="col-6 mt-2">
                    <div class="row">
                      <label
                        class="ml-4 label-font font-weight-bold font-size-large"
                        >Name :
                      </label>
                      <span id="rx-name" class="ml-1">{{ name }}</span>
                    </div>
                    <div class="row">
                      <label
                        class="ml-4 label-font font-weight-bold font-size-large "
                        >Age :
                      </label>
                      <span id="rx-age" class="ml-1">{{ age }}</span>
                    </div>
                  </div>
                  <div class="col-6 mt-2">
                    <div class="row">
                      <label class="label-font font-weight-bold font-size-large"
                        >Marital Status :
                      </label>
                      <span id="rx-marital-status" class="ml-1"
                        >{{ maritalStatus }}
                        {{ patientPregnant ? ", pregrent" : "" }}</span
                      >
                    </div>
                    <div class="row">
                      <label class="label-font font-weight-bold font-size-large"
                        >Gender :
                      </label>
                      <span id="rx-gender" class="ml-1">{{ gender }}</span>
                    </div>
                  </div>
                </div>
                <div
                  class="row rx-content p-3 d-flex  flex-column"
                  :class="{
                    'rx-content-height-for-settings': mode == 2,
                    'rx-content-height-normal': mode == 3 || mode == 4,
                  }"
                >
                  <div>
                    <h1 v-if="mode != 4">RX ...</h1>
                  </div>
                  <div v-if="mode == 2">
                    <p class="text-arab m-0">
                      قم بأضافة اسماء العلاجات التي تستخدمها بكثرة وعدد المرات و
                      التركيز , قم بالضغط على enter لكي تتم الأضافة
                    </p>
                    <div>
                      <!-- Antibiotic -->
                      <label
                        class="label-font font-weight-bold font-size-large m-1"
                      >
                        Antibiotic
                      </label>
                      <input
                        type="text"
                        class="form-control bg-light"
                        v-model="antibioticItem"
                        @keyup.enter="onEnterClickAntibiotic()"
                      />
                      <ul class="list-group list-group-flush m-1">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                          v-for="a in antibiotic"
                          :key="a"
                        >
                          {{ a }}
                          <span
                            class="badge badge-danger badge-pill pointer"
                            @click="deleteItemAntibiotic(a)"
                            >X</span
                          >
                        </li>
                      </ul>
                      <!-- Analgesics -->
                      <label
                        class="label-font font-weight-bold font-size-large m-1"
                      >
                        Analgesics
                      </label>
                      <input
                        type="text"
                        class="form-control bg-light"
                        v-model="analgesicsItem"
                        @keyup.enter="onEnterClickAnalgesics()"
                      />
                      <ul class="list-group list-group-flush m-1">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                          v-for="a in analgesics"
                          :key="a"
                        >
                          {{ a }}
                          <span
                            class="badge badge-danger badge-pill pointer"
                            @click="deleteItemAnalgesics(a)"
                            >X</span
                          >
                        </li>
                      </ul>
                      <!-- AntifungalDrugs -->
                      <label
                        class="label-font font-weight-bold font-size-large m-1"
                      >
                        Antifungal Drugs
                      </label>
                      <input
                        type="text"
                        class="form-control bg-light"
                        v-model="antifungalDrugsItem"
                        @keyup.enter="onEnterClickAntifungalDrugs()"
                      />
                      <ul class="list-group list-group-flush m-1">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                          v-for="a in antifungalDrugs"
                          :key="a"
                        >
                          {{ a }}
                          <span
                            class="badge badge-danger badge-pill pointer"
                            @click="deleteItemAntifungalDrugs(a)"
                            >X</span
                          >
                        </li>
                      </ul>
                      <!-- SedationDrugs -->
                      <label
                        class="label-font font-weight-bold font-size-large m-1"
                      >
                        Sedation Drugs
                      </label>
                      <input
                        type="text"
                        class="form-control bg-light"
                        v-model="sedationDrugsItem"
                        @keyup.enter="onEnterClickSedationDrugs()"
                      />
                      <ul class="list-group list-group-flush m-1">
                        <li
                          class="list-group-item d-flex justify-content-between align-items-center"
                          v-for="a in sedationDrugs"
                          :key="a"
                        >
                          {{ a }}
                          <span
                            class="badge badge-danger badge-pill pointer"
                            @click="deleteItemSedationDrugs(a)"
                            >X</span
                          >
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div class="row" v-show="mode == 3" id="noramlRxMode3">
                    <div class="col-12" id="antibiotic-selector-div">
                      <p class="h2">Antibiotic</p>
                      <div class="form-group">
                        <select
                          class="form-control selectpicker"
                          id="antibiotic-selector"
                          v-model="selectedAntibiotic"
                          data-width="75%"
                        >
                          <option v-for="a in antibiotic" :key="a">{{
                            a
                          }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12" id="analgesics-selector-div">
                      <p class="h2">Analgesics</p>
                      <div class="form-group">
                        <select
                          class="form-control selectpicker"
                          id="analgesics-selector"
                          v-model="selectedAnalgesics"
                          data-width="75%"
                        >
                          <option v-for="a in analgesics" :key="a">{{
                            a
                          }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12" id="antifungalDrugs-selector-div">
                      <p class="h2">Antifungal Drugs</p>
                      <div class="form-group">
                        <select
                          class="form-control selectpicker"
                          id="antifungalDrugs-selector"
                          v-model="selectedAntifungalDrugs"
                          data-width="75%"
                        >
                          <option v-for="a in antifungalDrugs" :key="a">{{
                            a
                          }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12" id="sedationDrugs-selector-div">
                      <p class="h2">Sedation Drugs</p>
                      <div class="form-group">
                        <select
                          class="form-control selectpicker"
                          id="sedationDrugs-selector"
                          v-model="selectedSedationDrugs"
                          data-width="75%"
                        >
                          <option v-for="s in sedationDrugs" :key="s">{{
                            s
                          }}</option>
                        </select>
                      </div>
                    </div>
                    <div class="col-12" id="rx-other-input-div">
                      <label for="name" class="h2">Other</label>
                      <input
                        type="text"
                        class="form-control bg-light w-75"
                        id="rx-other-input"
                        v-model="rxOther"
                      />
                    </div>
                  </div>

                  <div class="row" v-show="mode == 4" id="instractionMode4">
                    <div class="col-12">
                      <p class="h2 text-arab-with-ul">
                        تعليمات ما بعد القلع
                      </p>
                      <ul
                        class="list-group list-group-flush text-arab-with-ul m-0"
                      >
                        <li class="list-group-item">
                          وضع قطنة مكان خلع الضرس أو السن لمدة نصف ساعة مع الضغط
                          عليها
                        </li>
                        <li class="list-group-item">
                          الأبتعاد عن المشروبات الساخنة أو المأكولات القاسية
                        </li>
                        <li class="list-group-item">
                          الأبتعاد عن التدخين لمدة 24 ساعة
                        </li>
                        <li class="list-group-item">
                          المواظبة على شرب المشروبات الباردة و المأكولات الطرية
                          لمدة 24 ساعة
                        </li>
                        <li class="list-group-item">
                          الأبتعاد عن المضمضة سواء بأستخدام الغسول او المياه و
                          الملح لمدة 24 ساعة و استعمالها بعد مرور اول يوم
                        </li>
                        <li class="list-group-item">
                          يمنع وضع اللسان او الأصبع على مكان الخلع لتجنب النزيف
                        </li>
                        <li class="list-group-item">
                          تناول الأدوية التي يصفها الطبيب بالوقت المخصص لها
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- Modal footer -->
          <div class="modal-footer not-print">
            <button
              type="button"
              class="btn rx-settings-btn px-5"
              data-dismiss="modal"
              v-if="mode == 2"
              @click="saveRxSettings"
            >
              Save
            </button>
          </div>
          <div class="modal-footer not-print">
            <button
              type="button"
              class="btn rx-settings-btn px-sm-5"
              data-dismiss="modal"
              v-if="mode == 3"
              @click="sendRxToTreatmentComponent"
            >
              Ok
            </button>
            <button
              type="button"
              class="btn rx-settings-btn px-sm-5 not-disabled"
              data-dismiss="modal"
              v-if="mode == 3 || mode == 4"
              @click="printRxModal"
            >
              Print
            </button>
            <button
              type="button"
              class="btn rx-settings-btn px-sm-5"
              data-dismiss="modal"
              v-if="mode == 3"
              @click="cancelRx"
            >
              cancel
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "@vue/reactivity";
import {
  onMounted,
  watch,
  onBeforeUnmount,
  onUpdated,
} from "@vue/runtime-core";
import useDocument from "@/composables/useDocument";
import getUser from "@/composables/getUser";
import usePrint from "@/composables/usePrint";
import { projectFirestore } from "../firebase/config";
import { useRouter } from "vue-router";
import $ from "jquery";
export default {
  props: [
    "mode",
    "settings",
    "rxGeneralInfo",
    "rxContainer",
    "edit",
    "haveInput",
  ],
  setup(props, context) {
    // there is no data in mode 1 just there is no settings,  mode 2 => seetings , mode 3 => show rx for user to select rx and to shor thir select options in show patients
    const { updateDoc } = useDocument("users");
    const { user } = getUser();
    const { printModal } = usePrint();
    const router = useRouter();

    // rxGeneralInfo
    const name = ref("");
    const age = ref("");
    const maritalStatus = ref("");
    const gender = ref("");
    const patientPregnant = ref("");

    const nameOfClinic = ref("");
    const locationOfClinic = ref("");
    const antibiotic = ref([]);
    const antibioticItem = ref("");
    const analgesics = ref([]);
    const analgesicsItem = ref("");
    const antifungalDrugs = ref([]);
    const antifungalDrugsItem = ref("");
    const sedationDrugs = ref([]);
    const sedationDrugsItem = ref("");

    const selectedAntibiotic = ref("");
    const selectedAnalgesics = ref("");
    const selectedAntifungalDrugs = ref("");
    const selectedSedationDrugs = ref("");
    const rxOther = ref("");

    const firstWatch = watch(
      () => props.mode,
      () => {
        if (props.settings) {
          nameOfClinic.value = props.settings.nameOfClinic;
          locationOfClinic.value = props.settings.locationOfClinic;
          antibiotic.value = props.settings.antibiotic;
          analgesics.value = props.settings.analgesics;
          antifungalDrugs.value = props.settings.antifungalDrugs;
          sedationDrugs.value = props.settings.sedationDrugs;
        }

        // add options to antibiotic-selector
        if (props.rxContainer) {
          if (
            antibiotic.value.indexOf(props.rxContainer.selectedAntibiotic) == -1
          ) {
            let html = `<option> ${props.rxContainer.selectedAntibiotic}</option>`;
            $("#antibiotic-selector").append(html);
          }
          if (
            analgesics.value.indexOf(props.rxContainer.selectedAnalgesics) == -1
          ) {
            let html = `<option> ${props.rxContainer.selectedAnalgesics}</option>`;
            $("#analgesics-selector").append(html);
          }
          if (
            antifungalDrugs.value.indexOf(
              props.rxContainer.selectedAntifungalDrugs
            ) == -1
          ) {
            let html = `<option> ${props.rxContainer.selectedAntifungalDrugs}</option>`;
            $("#antifungalDrugs-selector").append(html);
          }
          if (
            sedationDrugs.value.indexOf(
              props.rxContainer.selectedSedationDrugs
            ) == -1
          ) {
            let html = `<option> ${props.rxContainer.selectedSedationDrugs}</option>`;
            $("#sedationDrugs-selector").append(html);
          }
        }

        for (let i = 0; i < antibiotic.value.length; i++) {
          let html = `<option> ${antibiotic.value[i]}</option>`;
          $("#antibiotic-selector").append(html);
        }

        for (let i = 0; i < analgesics.value.length; i++) {
          let html = `<option> ${analgesics.value[i]}</option>`;
          $("#analgesics-selector").append(html);
        }

        for (let i = 0; i < antifungalDrugs.value.length; i++) {
          let html = `<option> ${antifungalDrugs.value[i]}</option>`;
          $("#antifungalDrugs-selector").append(html);
        }

        for (let i = 0; i < sedationDrugs.value.length; i++) {
          let html = `<option> ${sedationDrugs.value[i]}</option>`;
          $("#sedationDrugs-selector").append(html);
        }
        $(".selectpicker").selectpicker("refresh");
        if (props.rxContainer) {
          if (props.edit == "false" && props.haveInput == "false") {
            return;
          }
          selectedAntibiotic.value = props.rxContainer.selectedAntibiotic;
          selectedAnalgesics.value = props.rxContainer.selectedAnalgesics;
          selectedAntifungalDrugs.value =
            props.rxContainer.selectedAntifungalDrugs;
          selectedSedationDrugs.value = props.rxContainer.selectedSedationDrugs;
          rxOther.value = props.rxContainer.rxOther;
          $("#antibiotic-selector").val(selectedAntibiotic.value.trim());
          // $("#antibiotic-selector").selectpicker("refresh");
          $("#analgesics-selector").val(selectedAnalgesics.value.trim());
          // $("#analgesics-selector").selectpicker("refresh");
          $("#antifungalDrugs-selector").val(
            selectedAntifungalDrugs.value.trim()
          );
          $("#antifungalDrugs-selector").selectpicker("refresh");
          $("#sedationDrugs-selector").val(selectedSedationDrugs.value.trim());
          // $("#sedationDrugs-selector").selectpicker("refresh");
        }

        $(".selectpicker").selectpicker("refresh");

        // selectedAntibiotic.value = antibiotic.value[0];
        // selectedAnalgesics.value = analgesics.value[0];
        // selectedAntifungalDrugs.value = antifungalDrugs.value[0];
        // selectedSedationDrugs.value = sedationDrugs.value[0];
      }
    );

    const secondWatch = watch(
      () => props.rxGeneralInfo,
      () => {
        name.value = props.rxGeneralInfo.name;
        age.value = props.rxGeneralInfo.age;
        gender.value = props.rxGeneralInfo.gender;
        maritalStatus.value = props.rxGeneralInfo.maritalStatus;
        patientPregnant.value = props.rxGeneralInfo.patientPregnant;
      }
    );

    const onEnterClickAntibiotic = () => {
      if (antibiotic.value.length > 3) {
        return;
      }
      antibiotic.value.push(antibioticItem.value);
      antibioticItem.value = "";
    };
    const onEnterClickAnalgesics = () => {
      if (analgesics.value.length > 3) {
        return;
      }
      analgesics.value.push(analgesicsItem.value);
      analgesicsItem.value = "";
    };
    const onEnterClickAntifungalDrugs = () => {
      if (antifungalDrugs.value.length > 3) {
        return;
      }
      antifungalDrugs.value.push(antifungalDrugsItem.value);
      antifungalDrugsItem.value = "";
    };
    const onEnterClickSedationDrugs = () => {
      if (sedationDrugs.value.length > 3) {
        return;
      }
      sedationDrugs.value.push(sedationDrugsItem.value);
      sedationDrugsItem.value = "";
    };
    const deleteItemAntibiotic = (item) => {
      let index = antibiotic.value.indexOf(item);
      if (index > -1) {
        antibiotic.value.splice(index, 1);
      }
    };
    const deleteItemAnalgesics = (item) => {
      let index = analgesics.value.indexOf(item);
      if (index > -1) {
        analgesics.value.splice(index, 1);
      }
    };
    const deleteItemAntifungalDrugs = (item) => {
      let index = antifungalDrugs.value.indexOf(item);
      if (index > -1) {
        antifungalDrugs.value.splice(index, 1);
      }
    };
    const deleteItemSedationDrugs = (item) => {
      let index = sedationDrugs.value.indexOf(item);
      if (index > -1) {
        sedationDrugs.value.splice(index, 1);
      }
    };

    const saveRxSettings = async () => {
      let id = null;
      let ref = projectFirestore
        .collection("users")
        .where("userId", "==", user.value.uid);
      const data = await ref.get();
      data.forEach((doc) => {
        id = doc.id;
      });
      if (antibiotic.value[0] != "no thing") {
        antibiotic.value.unshift("no thing");
        analgesics.value.unshift("no thing");
        antifungalDrugs.value.unshift("no thing");
        sedationDrugs.value.unshift("no thing");
      }

      await updateDoc(
        {
          rxSettings: {
            nameOfClinic: nameOfClinic.value,
            locationOfClinic: locationOfClinic.value,
            antibiotic: antibiotic.value,
            analgesics: analgesics.value,
            antifungalDrugs: antifungalDrugs.value,
            sedationDrugs: sedationDrugs.value,
          },
          haveRxSettings: true,
        },
        id
      );
    };
    let rxObj;
    const sendRxToTreatmentComponent = () => {
      if (selectedAntibiotic.value == "") {
        selectedAntibiotic.value = antibiotic.value[0];
      }
      if (selectedAnalgesics.value == "") {
        selectedAnalgesics.value = analgesics.value[0];
      }
      if (selectedAntifungalDrugs.value == "") {
        selectedAntifungalDrugs.value = antifungalDrugs.value[0];
      }
      if (selectedSedationDrugs.value == "") {
        selectedSedationDrugs.value = sedationDrugs.value[0];
      }
      rxObj = {
        selectedAntibiotic: selectedAntibiotic.value,
        selectedAnalgesics: selectedAnalgesics.value,
        selectedAntifungalDrugs: selectedAntifungalDrugs.value,
        selectedSedationDrugs: selectedSedationDrugs.value,
        rxOther: rxOther.value,
      };
      context.emit("getRx", rxObj);
    };
    const getIdForUserDoc = async () => {
      let id = null;
      let ref = projectFirestore
        .collection("users")
        .where("userId", "==", user.value.uid);
      const data = await ref.get();
      data.forEach((doc) => {
        id = doc.id;
      });

      return id;
    };
    const cancelRx = () => {
      let rxObj = null;
      context.emit("getRx", rxObj);
    };
    const redirectTOSettings = () => {
      $(".modal").modal("hide");
      router.push({ name: "Settings" });
    };
    onMounted(async () => {
      // to solve => Why Input having blank Values? While Printing HTML
      $("input").on("input", function() {
        $(this).attr("value", $(this).val());
      });

      if (props.mode == 2) {
        // Rx section

        let id = await getIdForUserDoc();
        let ref = projectFirestore.collection("users").doc(id);
        const doc = await ref.get();
        let userSettingsAndPresets = doc.data();
        if (userSettingsAndPresets.haveRxSettings) {
          let fullAntibiotic = userSettingsAndPresets.rxSettings.antibiotic;
          let pureAntibiotic = fullAntibiotic.splice(1, fullAntibiotic.length);
          let fullAnalgesics = userSettingsAndPresets.rxSettings.analgesics;
          let pureAnalgesics = fullAnalgesics.splice(1, fullAnalgesics.length);
          let fullAntifungalDrugs =
            userSettingsAndPresets.rxSettings.antifungalDrugs;
          let pureAntifungalDrugs = fullAntifungalDrugs.splice(
            1,
            fullAntifungalDrugs.length
          );
          let fullSedationDrugs =
            userSettingsAndPresets.rxSettings.sedationDrugs;
          let pureSedationDrugs = fullSedationDrugs.splice(
            1,
            fullSedationDrugs.length
          );
          // rxSettings.value = userSettingsAndPresets.rxSettings;
          nameOfClinic.value = userSettingsAndPresets.rxSettings.nameOfClinic;
          locationOfClinic.value =
            userSettingsAndPresets.rxSettings.locationOfClinic;
          antibiotic.value = pureAntibiotic;
          analgesics.value = pureAnalgesics;
          antifungalDrugs.value = pureAntifungalDrugs;
          sedationDrugs.value = pureSedationDrugs;
        }
      }
    });
    const printRxModal = () => {
      // $(".modal").modal("hide");
      printModal("modalBodyOfRx");
      // router.push({ name: "Home" });
    };
    let updateFinish = false;
    onUpdated(() => {
      if (updateFinish == false) {
        context.emit("allRxDataFinish");
        updateFinish = true;
      }
    });
    onBeforeUnmount(() => {
      firstWatch();
      secondWatch();
    });
    return {
      nameOfClinic,
      locationOfClinic,
      antibiotic,
      onEnterClickAntibiotic,
      antibioticItem,
      deleteItemAntibiotic,
      analgesics,
      analgesicsItem,
      antifungalDrugs,
      antifungalDrugsItem,
      sedationDrugs,
      sedationDrugsItem,
      onEnterClickAnalgesics,
      deleteItemAnalgesics,
      deleteItemAntifungalDrugs,
      onEnterClickAntifungalDrugs,
      deleteItemSedationDrugs,
      onEnterClickSedationDrugs,
      saveRxSettings,
      redirectTOSettings,
      name,
      age,
      gender,
      maritalStatus,
      selectedAntibiotic,
      selectedAnalgesics,
      selectedAntifungalDrugs,
      selectedSedationDrugs,
      rxOther,
      sendRxToTreatmentComponent,
      cancelRx,
      patientPregnant,
      printRxModal,
    };
  },
};
</script>

<style>
.upper-section {
  background: rgb(248, 233, 226);

  color: white;
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
}

.rx-content {
  border: rgb(248, 233, 226) solid 8px;
  border-radius: 2px;
}
.rx-content h1 {
  color: rgb(248, 233, 226);
  text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000,
    1px 1px 0 #000;
  font-family: "Dancing Script", "cursive" !important;
}

.rx-content .h2 {
  color: rgba(204, 127, 127, 0.8);
}
.image-section {
  background: rgb(248, 233, 226);
  border: white solid 12px;
  border-radius: 2px;
}
textarea.rx-textarea {
  width: 100% !important;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  border-radius: 8px;
}
/* #text {
  direction: rtl;
}
#text:after {
  content: "\200e";
} */
.text-arab {
  direction: rtl !important;
  text-align: right;
}
.text-arab-with-ul {
  /* direction: rtl !important; */
  text-align: right;
}
.text-arab:after {
  content: "a";
  color: transparent;
}
.pointer {
  cursor: pointer !important;
}
.list-group-item {
  padding: 0.35rem 0.85rem !important;
}
.image-height-for-settings {
  height: 400px;
}
.image-height-normal {
  height: 300px;
}
.image-section-height-normal {
  height: 398px;
}
.image-section-height-for-settings {
  height: 800px;
}
.rx-content-height-normal {
  height: 600px;
}
.rx-content-height-for-settings {
  height: 1104px;
}
.rx-settings-btn {
  color: rgb(204, 127, 127);
  background-color: #f8e9e2;
  border-color: #f8e9e2;
}

.image-section textarea {
  text-align: center;
  padding: 20px 0;
  font-size: 20px;
  font-family: "Merriweather", serif;
  color: rgb(204, 127, 127);
}
.form-control:focus {
  color: #495057 !important;
  background-color: #fff !important;
  border-color: #000000 !important;
  outline: 0 !important;
  box-shadow: 0 0 0 0.2rem rgba(8, 8, 8, 0.25) !important;
}
img {
  width: 80%;
  height: auto;
}
@-webkit-keyframes rotating /* Safari and Chrome */ {
  from {
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes rotating {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotating {
  -webkit-animation: rotating 2s linear infinite;
  -moz-animation: rotating 2s linear infinite;
  -ms-animation: rotating 2s linear infinite;
  -o-animation: rotating 2s linear infinite;
  animation: rotating 2s linear infinite;
}
</style>
