<template>
  <!-- orthodotic treatment modal -->
  <div class="modal fade" id="orthodotic-treatment">
    <div class="modal-dialog modal-xl">
      <div class="modal-content">
        <!-- Modal Header -->
        <div class="modal-header">
          <h4 class="modal-title">Orthodontic Treatment</h4>
          <button type="button" class="close" data-dismiss="modal">
            &times;
          </button>
        </div>

        <!-- Modal body -->
        <div
          class="modal-body"
          :class="{ disableform: haveInput == 'true' }"
          id="OrthodonticTreatmentPrintModal"
        >
          <div class="card">
            <div class="row">
              <div class="d-none col-xl-6 d-xl-block">
                <img
                  src="@/assets/images/orthodontic.webp"
                  class="rounded"
                  style="width:100%; height:99%;"
                  id="orthodontic-img"
                />
              </div>
              <div class="col-12 col-xl-6">
                <div class="card-body ml-0 pl-0">
                  <div class="card-title ">
                    <h2>Tooth Number</h2>
                  </div>
                  <div class="card-text">
                    <div
                      class="btn-group btn-group-lg-teeth btn-group-toggle special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div
                      class="btn-group btn-group-toggle btn-group-lg-teeth special"
                      role="group"
                      data-toggle="buttons"
                    >
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />1
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />2
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />3
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />4
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />5
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />6
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />7
                      </label>
                      <label class="btn btn-light active teeth-btn-orthodontic">
                        <input
                          type="checkbox"
                          name="options"
                          autocomplete="off"
                          checked
                        />8
                      </label>
                    </div>
                    <div class="row mt-3 ">
                      <div
                        class="col-12"
                        v-if="!images.length || edit == 'true'"
                      >
                        <input
                          type="file"
                          id="imgupload1"
                          style="display:none"
                          ref="inputFile"
                          @change="imageUpload"
                        />
                        <button
                          type="button"
                          class="btn btn-rx btn-lg btn-block py-0 h4 upload-image"
                          id="OpenImgUpload1"
                          @click="triggerFunc"
                        >
                          Image Upload
                        </button>
                        <div
                          class="div-of-files d-flex justify-content-center"
                          v-if="arrayOfFiles.length"
                        >
                          <div
                            class="paragraph-file"
                            v-for="file in arrayOfFiles"
                            :key="file"
                            @click="handleClickFile(file)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                      <div class="col-12" style="pointer-events: auto;" v-else>
                        <div class="div-of-files d-flex justify-content-center">
                          <div
                            class="paragraph-file"
                            v-for="image in imagesArray"
                            :key="image.url"
                            @click="handleClickFile2(image)"
                          >
                            <i class="fas fa-file-image fa-2x mx-3"></i>
                            <!-- <p>{{ file.name.replace(/\.[^.]*$/, "") }}</p> -->
                          </div>
                        </div>
                      </div>
                    </div>
                    <div>
                      <div class="row">
                        <div class="col mt-2">
                          <h2 class=" mb-3 mt-2">Clinical Examinatation</h2>
                          <div class="row mt-3">
                            <div class="col">
                              <p class="h4">Anterior Posterior Relation</p>
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="antrior-posterior-relation"
                                  value="Class 1"
                                  v-model="anteriorPosteriorRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Class 1</label
                                  >
                                </div>
                              </div>

                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="antrior-posterior-relation"
                                  value="Class 2"
                                  v-model="anteriorPosteriorRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Class 2</label
                                  >
                                </div>
                              </div>

                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="antrior-posterior-relation"
                                  value="Class 3"
                                  v-model="anteriorPosteriorRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Class 3</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <p class="h4">Vertical Relation</p>
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="vertical-relation"
                                  value="Normal"
                                  v-model="verticalRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Normal</label
                                  >
                                </div>
                              </div>

                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="vertical-relation"
                                  value="Increase"
                                  v-model="verticalRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Increase</label
                                  >
                                </div>
                              </div>

                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="vertical-relation"
                                  value="Decreased"
                                  v-model="verticalRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Decreased</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <p class="h4">Horizontal Relation</p>
                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="horizontal-relation"
                                  value="Symmetrical"
                                  v-model="horizontalRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Symmetrical</label
                                  >
                                </div>
                              </div>

                              <div class="pretty p-default p-round p-thick h5">
                                <input
                                  type="radio"
                                  name="horizontal-relation"
                                  value="Asymmetrical"
                                  v-model="horizontalRelation"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h5"
                                    >Asymmetrical</label
                                  >
                                </div>
                              </div>
                            </div>
                          </div>
                          <div class="row mt-2">
                            <div class="col-12">
                              <div class="form-inline">
                                <label class="h4 mr-2">Facial Profile </label>
                                <input
                                  type="text"
                                  class="form-control bg-light w-75 mt-1"
                                  id="facial-profile"
                                  v-model="facialProfile"
                                />
                              </div>
                            </div>
                          </div>
                          <div class="row mt-3">
                            <div class="col">
                              <h2>Dental And Local Factors</h2>
                              <div class="row d-none d-sm-flex">
                                <div class="col-8 col-md-6">
                                  <label for="name" class="h4 mb-4"
                                    >Angles Classification</label
                                  >
                                  <label for="name" class="h4 mb-4"
                                    >Canines Classification</label
                                  >
                                  <label for="name" class="h4 mb-4"
                                    >Incisors Classification</label
                                  >
                                </div>
                                <div class="col-4 col-md-6">
                                  <div class="row">
                                    <div class="col-8 col-md-6">
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="anglesClassification"
                                      />
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="caninesClassification"
                                      />
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="incisorsClassification"
                                      />
                                    </div>
                                  </div>
                                </div>
                                <div class="col-4">
                                  <label for="name" class="h4 mb-4"
                                    >Over Jet</label
                                  >
                                  <label for="name" class="h4 mb-4"
                                    >Over Bit</label
                                  >
                                </div>
                                <div class="col-8">
                                  <div class="row">
                                    <div class="col-4">
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="overJet"
                                      />
                                      <input
                                        type="text"
                                        class="form-control bg-light mb-2"
                                        v-model="overBit"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row d-flex d-sm-none">
                                <div class="col-12">
                                  <label for="name" class="h4 mb-4"
                                    >Angles Classification</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    id="angles-classification"
                                    v-model="anglesClassification"
                                  />
                                  <label for="name" class="h4 mb-4"
                                    >Canines Classification</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    id="canines-classification"
                                    v-model="caninesClassification"
                                  />
                                  <label for="name" class="h4 mb-4"
                                    >Incisors Classification</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    id="incisors-classification"
                                    v-model="incisorsClassification"
                                  />
                                  <label for="name" class="h4 mb-4"
                                    >Over Jet</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    v-model="overJet"
                                  />
                                  <label for="name" class="h4 mb-4"
                                    >Over Bit</label
                                  >
                                  <input
                                    type="text"
                                    class="form-control bg-light mb-2"
                                    id="over-bit"
                                    v-model="overBit"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>

                          <div class="row mt-3">
                            <div class="col-12">
                              <p class="d-inline h4 mr-2">Cross Bit</p>
                              <div class="pretty p-default p-round p-thick h4">
                                <input
                                  type="radio"
                                  name="cross-bit"
                                  value="Yes"
                                  v-model="crossBit"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >Yes</label
                                  >
                                </div>
                              </div>
                              <div class="pretty p-default p-round p-thick h4">
                                <input
                                  type="radio"
                                  name="cross-bit"
                                  value="No"
                                  v-model="crossBit"
                                />
                                <div class="state p-dark-o">
                                  <label
                                    class="pretty p-default p-curve p-fill h4"
                                    >No</label
                                  >
                                </div>
                              </div>
                            </div>
                            <div class="col">
                              <div class="row">
                                <div class="col">
                                  <div class="row mt-3">
                                    <div class="col">
                                      <div class="form-group">
                                        <textarea
                                          class="form-control eng-placeholder-lg"
                                          rows="3"
                                          id="orthodontic-diagnosis"
                                          placeholder="Diagnosis"
                                          v-model="Diagnosis"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col">
                                      <div class="form-group">
                                        <textarea
                                          class="form-control eng-placeholder-lg"
                                          rows="3"
                                          id="orthodontic-treatment-plan"
                                          placeholder="Treatment Plan"
                                          v-model="Plan"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="row mt-3">
                                    <div class="col">
                                      <div class="form-group">
                                        <textarea
                                          class="form-control eng-placeholder-lg"
                                          rows="3"
                                          id="orthodontic-appliance-design"
                                          placeholder="Appliance Design"
                                          v-model="applianceDesign"
                                        ></textarea>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div class="row mt-3">
                                <div class="col">
                                  <div class="form-group">
                                    <textarea
                                      class="form-control eng-placeholder-lg"
                                      rows="3"
                                      id="orthodontic-notes"
                                      placeholder="Your Notes"
                                      v-model="notes"
                                    ></textarea>
                                  </div>
                                </div>
                              </div>
                              <div class="row">
                                <div class="col-12 col-sm-6">
                                  <label for="name" class="h4"
                                    >Date Of Next Visit</label
                                  >
                                  <input
                                    type="text"
                                    id="datetimepicker5"
                                    class="form-control  bg-light datetimepicker"
                                  />
                                </div>
                                <div class="col-6 col-sm-3">
                                  <label for="name" class="h4">Cost</label>
                                  <input
                                    type="text"
                                    class="form-control bg-light"
                                    v-model="cost"
                                  />
                                </div>
                                <div class="col-6 col-sm-3">
                                  <label class="h4" style="color: #ffffff;"
                                    >Cost</label
                                  >
                                  <br />
                                  <button
                                    type="button"
                                    class="btn btn-rx px-4"
                                    data-toggle="modal"
                                    data-target="#rx-modal"
                                    :class="{
                                      pointerEventAuto: rxContainer != null,
                                    }"
                                  >
                                    RX
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Modal footer -->
        <div class="modal-footer" :class="{ disableform: haveInput == 'true' }">
          <button
            type="button"
            class="btn btn-dark px-5"
            data-dismiss="modal"
            id="orthodontic-btn"
            v-if="
              (edit == 'false' || !edit) && (newVisit == 'false' || !newVisit)
            "
            :class="{ disableformForInput: haveInput == 'true' }"
            @click="saveData"
          >
            Save
          </button>
          <button
            type="button"
            class="btn btn-dark px-5"
            data-dismiss="modal"
            id="orthodontic-btn"
            v-if="edit == 'true'"
            @click="editData"
          >
            Save Changes
          </button>
          <button
            type="button"
            class="btn btn-dark px-5"
            data-dismiss="modal"
            id="orthodontic-btn"
            @click="saveDataToNewVisit"
            v-if="newVisit == 'true'"
          >
            New Visit
          </button>
        </div>
      </div>
    </div>
  </div>
  <!-- modal of file  -->
  <div class="modal" id="modal-of-file-orthodontic" tabindex="-1" role="dialog">
    <div class="modal-dialog modal-xl" role="document">
      <div class="modal-content">
        <div class="modal-body" :class="{ disableform: haveInput == 'true' }">
          <img
            id="file-ip-5-preview"
            style="display:none;"
            class="uploaded-img"
          />
          <div class="d-flex justify-content-center">
            <button
              class="btn btn-danger m-2"
              @click="deleteImage"
              :class="{ disableformForInput: haveInput == 'true' }"
            >
              Delete
            </button>
            <button
              type="button"
              class="btn btn-secondary m-2 not-disabled"
              data-dismiss="modal"
            >
              Close
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { onMounted, ref, computed } from "@vue/runtime-core";
import useCollection from "@/composables/useCollection";
import useDocument from "@/composables/useDocument";
import useStorage from "@/composables/useStorage";
import { timestamp } from "@/firebase/config";
import getUser from "@/composables/getUser";
import { useStore } from "vuex";
export default {
  props: [
    "patientInformation",
    "dateOfNextVisitOrthodontic",
    "treatmentPlan",
    "haveInput",
    "edit",
    "newVisit",
    "id",
    "haveSubCases",
    "rx",
    "rxContainer",
    "images",
    "sendFromSubCase",
  ],
  emits: ["needSomeRequiredData"],
  setup(props, context) {
    const { updateDoc } = useDocument("patients");
    const { updateDoc: updateVisits } = useDocument("visits");
    const { addDoc } = useCollection("patients");
    const { addDoc: addNewVisit } = useCollection("visits");
    const { user } = getUser();
    const { filePath, url, uploadImage, deleteImage: delImage } = useStorage();
    const store = useStore();

    function getTeethBtnSelected(teethBtnsClass) {
      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);

      let arrayOfSelectedTeeth = [];
      for (let i = 0; i < arrayOfTeeth.length; i++) {
        if ($(arrayOfTeeth[i]).hasClass("active") == false) {
          arrayOfSelectedTeeth.push(i + 1);
        }
      }
      return arrayOfSelectedTeeth;
    }

    function showTeethBtnSelected(arrayOfSelectedTeeth, teethBtnsClass) {
      // remove class is redundant

      let arrayOfTeeth = document.querySelectorAll("." + teethBtnsClass);

      if (teethBtnsClass == "teeth-btn-endodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-primary"
          );
        }
      } else if (teethBtnsClass == "teeth-btn-composite") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-faroze");
        }
      } else if (teethBtnsClass == "teeth-btn-extraction") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-danger");
        }
      } else if (teethBtnsClass == "teeth-btn-orthodontic") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-dark");
        }
      } else if (teethBtnsClass == "teeth-btn-Implant") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass("btn-m-info");
        }
      } else if (teethBtnsClass == "teeth-btn-periodontal-treatment") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-warning"
          );

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("btn-light");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).removeClass("active");

          $(
            arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          ).addClass("btn-m-warning");
        }
      } else if (teethBtnsClass == "teeth-btn-prosthetic-and-hollywood-smile") {
        for (let i = 0; i < arrayOfSelectedTeeth.length; i++) {
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("btn-light");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).removeClass("active");
          $(arrayOfTeeth[arrayOfSelectedTeeth[i] - 1]).addClass(
            "btn-m-secondary"
          );

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).removeClass("btn-light");

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).removeClass("active");

          // $(
          //   arrayOfTeeth[arrayOfSelectedTeeth[i] - 1 + arrayOfTeeth.length / 2]
          // ).addClass("btn-m-secondary");
        }
      }
    }

    const anteriorPosteriorRelation = ref("");
    const verticalRelation = ref("");
    const horizontalRelation = ref("");
    const facialProfile = ref("");
    const anglesClassification = ref("");
    const caninesClassification = ref("");
    const incisorsClassification = ref("");
    const overJet = ref("");
    const overBit = ref("");
    const crossBit = ref("");
    const Diagnosis = ref("");
    const Plan = ref("");
    const applianceDesign = ref("");
    const notes = ref("");
    const cost = ref("");
    const inputFile = ref(null);
    const arrayOfFiles = ref([]);
    const activeFile = ref(null);
    let activeFile2 = ref(null);
    let imagesArray = ref([]);

    const getData = () => {
      let teeth = getTeethBtnSelected("teeth-btn-orthodontic");
      if (cost.value == "") {
        cost.value = 0;
      }
      let treatmentPlan = {
        selectedTeeth: teeth,
        anteriorPosteriorRelation: anteriorPosteriorRelation.value,
        verticalRelation: verticalRelation.value,
        horizontalRelation: horizontalRelation.value,
        facialProfile: facialProfile.value,
        anglesClassification: anglesClassification.value,
        caninesClassification: caninesClassification.value,
        incisorsClassification: incisorsClassification.value,
        overJet: overJet.value,
        overBit: overBit.value,
        crossBit: crossBit.value,
        Diagnosis: Diagnosis.value,
        Plan: Plan.value,
        applianceDesign: applianceDesign.value,
        notes: notes.value,
        cost: cost.value,
        dateOfNextVisit: props.dateOfNextVisitOrthodontic,
      };

      return treatmentPlan;
    };

    const saveData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let res = await addDoc({
        type: "Orthodontic Treatment",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        createdAt: timestamp(),
        userId: user.value.uid,
        haveSubCases: 0,
      });
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
    };
    const counterForNewVisitsAdds = computed(
      () => store.state.counterForNewVisitsAdds
    );
    const incrementCounterForNewVisitsAddsByOne = () => {
      store.commit("incrementCounterForNewVisitsAddsByOne");
    };
    const saveDataToNewVisit = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (!props.patientInformation.dateOfVisit) {
        requiredfields.push("date of visit");
      }
      if (!props.patientInformation.doctorName) {
        requiredfields.push(0);
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      let newHaveSubCases =
        parseInt(props.haveSubCases) + counterForNewVisitsAdds.value;
      let res = await addNewVisit({
        type: "Orthodontic Treatment",
        patientInformation: props.patientInformation,
        treatmentPlan: treatmentPlan,
        rx: props.rx,
        createdAt: timestamp(),
        timestampOfNewVisit: timestampOfNewVisit,
        timestampOfVisit: timestampOfVisit,
        userId: user.value.uid,
        mainCaseId: props.id,
        haveSubCases: 0,
      });
      await updateDoc(
        {
          haveSubCases: newHaveSubCases,
        },
        props.id
      );
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          await uploadImage(arrayOfFiles.value[i], res.id);
          images.push({
            url: url.value,
            filePath: filePath.value,
          });
        }
        await updateDoc(
          {
            images: images,
          },
          res.id
        );
      }
      incrementCounterForNewVisitsAddsByOne();
    };
    const editData = async () => {
      let treatmentPlan = getData();
      let timestampOfNewVisit = new Date(treatmentPlan.dateOfNextVisit);
      timestampOfNewVisit = timestampOfNewVisit.getTime();
      let timestampOfVisit = new Date(props.patientInformation.dateOfVisit);
      timestampOfVisit = timestampOfVisit.getTime();
      // validation section
      let requiredfields = [];
      if (!props.patientInformation.name) {
        requiredfields.push("name");
      }
      if (requiredfields.length) {
        context.emit("needSomeRequiredData", requiredfields);
        return;
      }
      if (arrayOfFiles.value.length) {
        let images = [];
        for (let i = 0; i < arrayOfFiles.value.length; i++) {
          // if variable is not image it is file
          if (!arrayOfFiles.value[i].url) {
            await uploadImage(arrayOfFiles.value[i], props.treatmentPlan.id);
            images.push({
              url: url.value,
              filePath: filePath.value,
            });
          } else {
            images.push(arrayOfFiles.value[i]);
          }
        }
        if (props.sendFromSubCase == "true") {
          await updateVisits(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        } else {
          await updateDoc(
            {
              images: images,
            },
            props.treatmentPlan.id
          );
        }
      }
      if (props.sendFromSubCase == "true") {
        await updateVisits(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      } else {
        await updateDoc(
          {
            patientInformation: props.patientInformation,
            treatmentPlan: treatmentPlan,
            timestampOfNewVisit: timestampOfNewVisit,
            timestampOfVisit: timestampOfVisit,
            rx: props.rx,
          },
          props.treatmentPlan.id
        );
      }
    };

    onMounted(() => {
      imagesArray.value = props.images;
      // if the page is edit take arrayOfFiles to be array of images => show arrayOfFiles ui , adding image case in arrayOfFiles functions
      if (props.edit == "true") {
        arrayOfFiles.value = imagesArray.value;
      }
      $(".teeth-btn-orthodontic").on("click", function() {
        if ($(this).hasClass("active") == true) {
          $(this).removeClass("btn-light");
          $(this).addClass("btn-dark");
        } else {
          $(this).removeClass("btn-dark");
          $(this).addClass("btn-light");
        }
      });

      if (
        props.treatmentPlan &&
        props.treatmentPlan.type == "Orthodontic Treatment"
      ) {
        showTeethBtnSelected(
          props.treatmentPlan.selectedTeeth,
          "teeth-btn-orthodontic"
        );
        anteriorPosteriorRelation.value =
          props.treatmentPlan.anteriorPosteriorRelation;
        verticalRelation.value = props.treatmentPlan.verticalRelation;
        horizontalRelation.value = props.treatmentPlan.horizontalRelation;
        facialProfile.value = props.treatmentPlan.facialProfile;
        anglesClassification.value = props.treatmentPlan.anglesClassification;
        caninesClassification.value = props.treatmentPlan.caninesClassification;
        incisorsClassification.value =
          props.treatmentPlan.incisorsClassification;
        overJet.value = props.treatmentPlan.overJet;
        overBit.value = props.treatmentPlan.overBit;
        crossBit.value = props.treatmentPlan.crossBit;
        Diagnosis.value = props.treatmentPlan.Diagnosis;
        Plan.value = props.treatmentPlan.Plan;
        applianceDesign.value = props.treatmentPlan.applianceDesign;
        notes.value = props.treatmentPlan.notes;
        cost.value = props.treatmentPlan.cost;
      }
    });
    const triggerFunc = () => {
      $("#no-need").modal("show");
      return;
      inputFile.value.click();
    };
    const imageUpload = (e) => {
      if (e.target.files.length > 0) {
        if (arrayOfFiles.value.length >= 5) {
          return;
        }
        if (!arrayOfFiles.value.length) {
          $("#orthodontic-img").height("+=30");
        }
        let selected = e.target.files[0];
        arrayOfFiles.value.push(selected);
      }
    };

    const handleClickFile = (f) => {
      let src;
      activeFile.value = f;
      // if it is image , not file
      if (f.url) {
        src = f.url;
      } else {
        src = URL.createObjectURL(f);
      }
      let preview = document.getElementById("file-ip-5-preview");
      preview.src = src;
      preview.style.display = "block";
      $("#modal-of-file-orthodontic").modal("show");
    };
    const handleClickFile2 = (img) => {
      activeFile2.value = img.filePath;
      let preview = document.getElementById("file-ip-5-preview");
      preview.src = img.url;
      preview.style.display = "block";
      $("#modal-of-file-orthodontic").modal("show");
    };
    const deleteImage = async () => {
      if (activeFile2.value) {
        await delImage(activeFile2.value);
        imagesArray.value.splice(
          imagesArray.value.indexOf(activeFile2.value),
          1
        );
        await updateDoc(
          {
            images: imagesArray.value,
          },
          props.treatmentPlan.id
        );
      } else {
        arrayOfFiles.value.splice(
          arrayOfFiles.value.indexOf(activeFile.value),
          1
        );
        // the page is edit and the image is in the database
        if (props.edit == "true" && activeFile.value.filePath) {
          await delImage(activeFile.value.filePath);
          await updateDoc(
            {
              images: arrayOfFiles.value,
            },
            props.treatmentPlan.id
          );
        }
      }
      let preview = document.getElementById("file-ip-5-preview");
      preview.src = "";
      preview.style.display = "none";
      $("#modal-of-file-orthodontic").modal("hide");
    };
    return {
      anteriorPosteriorRelation,
      verticalRelation,
      horizontalRelation,
      facialProfile,
      anglesClassification,
      caninesClassification,
      incisorsClassification,
      overJet,
      overBit,
      crossBit,
      Diagnosis,
      Plan,
      applianceDesign,
      notes,
      cost,
      saveData,
      editData,
      inputFile,
      triggerFunc,
      imageUpload,
      arrayOfFiles,
      handleClickFile,
      deleteImage,
      handleClickFile2,
      imagesArray,
      saveDataToNewVisit,
    };
  },
};
</script>

<style scoped>
@media only screen and (max-width: 768px) {
  h2,
  .h2 {
    font-size: 1.8rem;
  }
}
@media only screen and (max-width: 400px) {
  h2,
  .h2 {
    font-size: 1rem;
  }
}
</style>
