<template>
  <!-- Modal -->
  <div class="modal fade" id="changePasswordModal">
    <div class="modal-dialog modal-dialog-centered" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="exampleModalLongTitle">Reset Password</h5>
          <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div class="modal-body">
          <div class="form-group">
            <label class="text-uppercase">Email</label>
            <input type="text" class="form-control" required v-model="email" />
          </div>
          <span class="text-danger">{{ resetPasswordError }}</span>
        </div>
        <div class="modal-footer">
          <button type="button" class="btn btn-secondary" data-dismiss="modal">
            Close
          </button>
          <button type="button" class="btn btn-warning" @click="changePassword">
            Send Reset Password
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import { ref } from "@vue/reactivity";
import useLogin from "@/composables/useLogin";
import { useRouter } from "vue-router";
export default {
  setup() {
    const { resetPasswordError, resetPassword } = useLogin();
    const router = useRouter();
    const email = ref(null);
    const changePassword = async () => {
      const res = await resetPassword(email.value);
      if (!resetPasswordError.value) {
        $("#changePasswordModal").modal("hide");
        router.push({ name: "Home" });
      }
    };
    return { email, changePassword, resetPasswordError };
  },
};
</script>

<style></style>
