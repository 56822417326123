import $ from "jquery";

const printModal = (modalToPrint) => {
  if (
    $("#antibiotic-selector").val() == "no thing" ||
    $("#antibiotic-selector").val() == null
  ) {
    $("#antibiotic-selector-div").addClass("not-print");
  } else {
    $("#antibiotic-selector-div").removeClass("not-print");
  }
  if (
    $("#analgesics-selector").val() == "no thing" ||
    $("#analgesics-selector").val() == null
  ) {
    $("#analgesics-selector-div").addClass("not-print");
  } else {
    $("#analgesics-selector-div").removeClass("not-print");
  }
  if (
    $("#antifungalDrugs-selector").val() == "no thing" ||
    $("#antifungalDrugs-selector").val() == null
  ) {
    $("#antifungalDrugs-selector-div").addClass("not-print");
  } else {
    $("#antifungalDrugs-selector-div").removeClass("not-print");
  }
  if (
    $("#sedationDrugs-selector").val() == "no thing" ||
    $("#sedationDrugs-selector").val() == null
  ) {
    $("#sedationDrugs-selector-div").addClass("not-print");
  } else {
    $("#sedationDrugs-selector-div").removeClass("not-print");
  }
  let lengthOfRxOtherInput = $("#rx-other-input").val().length;
  if (lengthOfRxOtherInput == 0) {
    $("#rx-other-input-div").addClass("not-print");
  } else {
    $("#rx-other-input-div").removeClass("not-print");
  }
  var printContents = $("#" + modalToPrint)
    .clone()
    .find("script")
    .remove()
    .end()
    .html();

  let allLinks = $("head")
    .clone()
    .find("script")
    .remove()
    .end()
    .html();
  let mywindow = window.open("", "_blank");
  mywindow.document.open();
  let keepColors =
    "<style>body {-webkit-print-color-adjust: exact !important; }</style>";
  let landScape =
    '<style type="text/css" media="print">@page {size: A4 landscape;}</style>';
  mywindow.document.write(
    "<html><head>" +
      keepColors +
      allLinks +
      landScape +
      "</head><body>" +
      printContents +
      "</body></html>"
  );
  mywindow.document.close(); // necessary for IE >= 10
  mywindow.focus(); // necessary for IE >= 10*/
  mywindow.addEventListener(
    "load",
    () => {
      setTimeout(function() {
        mywindow.print();
        mywindow.close();
      }, 900);
    },
    false
  );

  // mywindow.print();
  // mywindow.close();

  // Reload <img> element from same source
  let source = document.getElementById("rx-image").src,
    timestamp = new Date().getTime(),
    newUrl = source + "?_=" + timestamp;
  document.getElementById("rx-image").src = newUrl;
  return true;
};

const usePrint = () => {
  return { printModal };
};
export default usePrint;
